import { useState, useEffect } from "react";
import AdminNavbar from "./../../Layout/AdminNavbar";
import MenuItems from "./../MenuItems";
import { Spinner } from "reactstrap";
import axios from "axios";
import { Endpoint } from "./../../Utils/Endpoint";
import AdminUserTable from "./AdminUserTable";

function AdminUsers() {
  const [IsLoading, setIsLoading] = useState(false);
  const [BusinessUserCount, setBusinessUserCount] = useState(0);
  const [CustomerUserCount, setCustomerUserCount] = useState(0);
  const [TotalCount, setTotalCount] = useState(0);
  const [Status, setStatus] = useState(0);
  var token = localStorage.getItem("token");

  useEffect(() => {
    getUsersCount();
  }, []);
  let getUsersCount = async () => {
    try {
      const response = await axios.get(`${Endpoint}/admin/users/count`, {
        headers: { Authorization: token },
      });
      setBusinessUserCount(response.data.business);
      setCustomerUserCount(response.data.customer);
      setTotalCount(response.data.total);
    } catch (e) {
      console.log(e, "Error while getting users");
    }
  };

  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        {IsLoading ? (
          <div className="mt-5 text-center">
            <Spinner color="dark" /> Getting Data...
          </div>
        ) : (
          <>
            <div className="row mt-2">
              <div className="col-6">
                <h4 className="text-dark"> Users</h4>
              </div>
            </div>
          </>
        )}
        <div className="mt-3">
          <div className="row">
            <div className="col-6 text-center">
              <button
                className={`btn ${
                  Status === 0 ? "btn-dark" : "btn-outline-dark"
                }`}
                onClick={() => setStatus(0)}
              >
                Business Users{" "}
                <span class="badge badge-light bg-secondary text-light">
                  {BusinessUserCount}
                </span>
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className={`btn ${
                  Status === 1 ? "btn-dark" : "btn-outline-dark"
                }`}
                onClick={() => setStatus(1)}
              >
                Customer Users{" "}
                <span class="badge badge-light bg-secondary text-light">
                  {CustomerUserCount}
                </span>
              </button>
            </div>
          </div>
          <div className="mt-4">
            <AdminUserTable status={Status} />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default AdminUsers;
