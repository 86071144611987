import React, { Component } from "react";
import { Input, Label, Button, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import axios from "axios";

import firetoast from './../../Utils/Firetoast';
import { Endpoint } from './../../Utils/Endpoint';


class DecimalFields extends Component {
  state = {
    data: {
      is_active: 1,
      is_mandatory: 0,
      is_quick_create: 0,
      is_key_field: 0,
      is_table_view: 0,
    },
    activeField: true,
    is_mandatory: false,
    is_quick_create: false,
    is_key_field: false,
    is_table_view: false,
  };

  render() {
    return (
      <>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Label Name <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="field_name"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            Length<span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="length"
              onChange={this.handleExtraChange}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            Decimals<span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="decimals"
              onChange={this.handleExtraChange}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Default Value</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="default_value"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <>
          <hr />

          <div class="mb-3 row">
            <div class="col-sm-6">
              <Label className="d-block" for="chk-ani">
                <Input
                  className="checkbox_animated"
                  id="chk-ani"
                  name="is_mandatory"
                  type="checkbox"
                  checked={this.state.is_mandatory}
                  onChange={this.setIsMandatory}
                />
                Is it Mandatory?
              </Label>
            </div>
          </div>
        </>
        <ModalFooter>
          <Button
            color="primary"
            className="btn-sm"
            onClick={this.SaveDecimalField}
          >
            Save
          </Button>{" "}
          {/* <Button
                color="secondary"
                onClick={() => this.toggleCustomField(!customField)}
              >
                Cancel
              </Button> */}
        </ModalFooter>
      </>
    );
  }
  handleExtraChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (input.name === "length") {
      var extra_info = { ...data["extra_info"] };
      extra_info.length = input.value;
      data["extra_info"] = extra_info;
      this.setState({ data });
    } else {
      var extra_info = { ...data["extra_info"] };
      extra_info.decimals = input.value;
      data["extra_info"] = extra_info;
      this.setState({ data });
    }
  };
  SaveDecimalField = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    var data = { ...this.state.data };
    data["section_id"] = this.props.section_id;
    data["extra_info"] = JSON.stringify(data["extra_info"]);
    data["type_id"] = this.props.type_id;
    var form = new URLSearchParams();
    for (var key in data) {
      form.append(key, data[key]);
    }
    try {
      const response = await axios.post(
        Endpoint + "/business/forms/sections/fields",
        form,
        { headers: { Authorization: token } }
      );

      if (response.data.status === 200) {
        toast.info("Decimal Field Added");
        this.props.closeModal(!this.props.customField);
        this.props.refreshSection(this.props.section_id);
      }
    } catch (e) {
      var error = e.response.data.message;
      if (Array.isArray(error)) {
        // toast.error(e.response.data.message[0]);
        firetoast(error[0], "error", 5000, "top-right");
      } else {
        firetoast(error, "error", 5000, "top-right");
        // toast.error(e.response.data.message);
      }
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete [input.name];
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  setActiveField = () => {
    var data = { ...this.state.data };
    if (this.state.activeField) {
      data["is_active"] = 0;
      this.setState({ data, activeField: false });
    } else {
      data["is_active"] = 1;
      this.setState({ data, activeField: true });
    }
  };
  setIsMandatory = () => {
    var data = { ...this.state.data };
    if (this.state.is_mandatory) {
      data["is_mandatory"] = 0;
      this.setState({ data, is_mandatory: false });
    } else {
      data["is_mandatory"] = 1;
      this.setState({ data, is_mandatory: true });
    }
  };
  setIsQuickCreate = () => {
    var data = { ...this.state.data };
    if (this.state.is_quick_create) {
      data["is_quick_create"] = 0;
      this.setState({ data, is_quick_create: false });
    } else {
      data["is_quick_create"] = 1;
      this.setState({ data, is_quick_create: true });
    }
  };
  setIsKeyField = () => {
    var data = { ...this.state.data };
    if (this.state.is_key_field) {
      data["is_key_field"] = 0;
      this.setState({ data, is_key_field: false });
    } else {
      data["is_key_field"] = 1;
      this.setState({ data, is_key_field: true });
    }
  };
  setIsTableView = () => {
    var data = { ...this.state.data };
    if (this.state.is_table_view) {
      data["is_table_view"] = 0;
      this.setState({ data, is_table_view: false });
    } else {
      data["is_table_view"] = 1;
      this.setState({ data, is_table_view: true });
    }
  };
}

export default DecimalFields;
