import { useState } from "react";
import { useEffect } from "react";
import firetoast from "./../../Utils/Firetoast";
import { Endpoint } from "./../../Utils/Endpoint";
import axios from "axios";
import { Modal } from "reactstrap";
import { ModalHeader } from "reactstrap";
import { ModalBody } from "reactstrap";
import AdminEditTextField from "./Edit Fields/EditTextField";
import AdminEditUploadField from "./Edit Fields/EditUploadField";
import AdminEditPercentageField from "./Edit Fields/EditPercentageField";
import AdminEditTextAreaField from "./Edit Fields/EditTextAreaField";
import AdminEditCurrencyField from "./Edit Fields/EditCurrencyField";
import AdminEditDateField from "./Edit Fields/EditDateField";
import AdminEditTimeField from "./Edit Fields/EditTimeField";
import AdminEditDateTimeField from "./Edit Fields/EditDateTime";
import AdminEditEmailField from "./Edit Fields/EditEmailField";
import AdminEditDecimalField from "./Edit Fields/EditDecimalField";
import AdminEditIntegerField from "./Edit Fields/EditIntegerField";
import AdminEditRadioFields from "./Edit Fields/EditRadioField";
import AdminEditCheckboxField from "./Edit Fields/EditCheckboxField";
import AdminEditPickListField from "./Edit Fields/EditPicklistField";
import AdminEditUrlFields from "./Edit Fields/EditUrlField";
import AdminEditPhoneFields from "./Edit Fields/EditPhoneField";
function FieldList({ UniversalFields, getFields }) {
  const token = localStorage.getItem("token");
  const [ShowModal, setShowModal] = useState(false);
  const [Field, setField] = useState(null);

  var MarkInActive = async (id) => {
    try {
      const response = await axios.get(
        `${Endpoint}/admin/universal-fields/deactivate/${id}`,
        { headers: { Authorization: token } }
      );
      if (response.data.success === 200 || response.status === 200) {
        firetoast("Field marked inactive", "dark", 3000, "top-center");
        getFields();
      } else {
        firetoast(response.data.message, "error", 3000, "top-center");
      }
    } catch (e) {
      firetoast("Something went wrong", "error", 3000, "top-center");
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered ">
        <thead>
          <th> Name</th>
          <th> Type</th>
          <th className="text-center"> Image</th>
          <th className="text-center">Active</th>
          <th className="text-center">Action</th>
        </thead>
        <tbody>
          {UniversalFields &&
            UniversalFields.map((field, index) => (
              <tr key={index}>
                <td>{field.field_name}</td>
                <td>{field.type.name}</td>
                <td className="text-center">
                  <img src={field.image} style={{ height: "25px" }} />
                </td>
                <td className="text-center">
                  {field.is_active === 1 ? (
                    <span>
                      <i class="fas fa-check text-success"></i>
                    </span>
                  ) : (
                    <span>
                      <i class="fas fa-times text-danger"></i>
                    </span>
                  )}
                </td>
                <td className="text-center">
                  <div className="btn-group">
                    {field.is_active ? (
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => MarkInActive(field.id)}
                      >
                        {" "}
                        Mark Inactive
                      </button>
                    ) : (
                      <button className="btn btn-warning btn-sm">
                        Mark Active
                      </button>
                    )}
                    <button
                      className="btn btn-dark btn-sm"
                      onClick={() => {
                        setField(field);
                        setShowModal(true);
                      }}
                    >
                      View
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        isOpen={ShowModal}
        toggle={() => setShowModal(!ShowModal)}
        size="lg"
      >
        <ModalHeader toggle={() => setShowModal(!ShowModal)}>
          Edit Field
        </ModalHeader>
        <ModalBody>
          <div class="row">
            <div class="col-9 m-auto">
              {Field && Field.type.type === "text" && (
                <AdminEditTextField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "file" && (
                <AdminEditUploadField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "percent" && (
                <AdminEditPercentageField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "textarea" && (
                <AdminEditTextAreaField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "currency" && (
                <AdminEditCurrencyField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "date" && (
                <AdminEditDateField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}

              {Field && Field.type.type === "time" && (
                <AdminEditTimeField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "datetime" && (
                <AdminEditDateTimeField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}

              {Field && Field.type.type === "email" && (
                <AdminEditEmailField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "decimal" && (
                <AdminEditDecimalField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "integer" && (
                <AdminEditIntegerField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "radio" && (
                <AdminEditRadioFields
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "checkbox" && (
                <AdminEditCheckboxField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "select" && (
                <AdminEditPickListField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "select2" && (
                <AdminEditPickListField
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "url" && (
                <AdminEditUrlFields
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {Field && Field.type.type === "phone" && (
                <AdminEditPhoneFields
                  field={Field}
                  refreshSection={() => getFields()}
                  toggleEdit={() => setShowModal(!ShowModal)}
                />
              )}
              {/**{field.type === "relationship" && (
          <EditRelationshipField
            field={field}
            section_id={this.props.section_id}
            refreshSection={this.props.refreshSection}
            toggleEdit={this.props.toggleEdit}
            Edit={this.props.toggleEdit}
          />
        )}
      

 */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
export default FieldList;
