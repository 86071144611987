import React from "react";
import { Redirect, Route } from "react-router-dom";

function LoginRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("token");

  if (!isAuthenticated) {
    return (
      <Route {...restOfProps} render={(props) => <Component {...props} />} />
    );
  } else {
    var user = JSON.parse(localStorage.getItem("nopen-user"));
    if (user.role_id === "2") {
      return (
        <Route
          {...restOfProps}
          render={(props) => <Redirect to="/user-landing" />}
        />
      );
    } else if (user.role_id === "3") {
      return (
        <Route {...restOfProps} render={(props) => <Redirect to="/forms" />} />
      );
    } else {
      return (
        <Route
          {...restOfProps}
          render={(props) => <Redirect to="/admin/dashboard" />}
        />
      );
    }
  }
}

export default LoginRoute;
