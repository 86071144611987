import QRCode from "react-qr-code";
import { useRef } from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import firetoast from "./../Utils/Firetoast";

function QrCode(props) {
  const code = useRef(null);
  var toTextBoard = () => {
    var copyText = document.getElementById("clipboard");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    firetoast("Copied!", "info", 1000, "top-center");
  };
  return (
    <>
      <input
        value={`https://nopen.us/form/${props.name}/${props.id}`}
        className="d-none"
        id="clipboard"
      />
      <div ref={code} className="p-3">
        <div className="card">
          <div className="card-body">
            <QRCode value={props.id} />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <button
          onClick={() => exportComponentAsPNG(code)}
          className="btn btn-dark text-white"
        >
          Download
        </button>
        <button
          onClick={() => toTextBoard()}
          className="btn btn-secondary text-white"
        >
          Copy Link
        </button>
      </div>
    </>
  );
}
export default QrCode;
