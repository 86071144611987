import React, { useEffect, useState } from "react";
import axios from "axios";
// import FormInput from "./../components/Fields/Fields";
import { Spinner } from "reactstrap";
import UserNavbar from "./../Layout/UserNavbar";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import { useParams } from "react-router-dom";
import ErrorHandler from "./../Utils/ErrroHandler";
import FormInput from "./../Helpers/FormInput";
function CreatedForm(props) {
  const { id } = useParams();
  const [formTitle, setFormTitle] = useState("");
  const [sectionFields, setSectionFields] = useState({});
  const [show, setShow] = useState(false);
  const [field, setField] = useState({});

  useEffect(async () => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("token");
      var response = await axios.get(
        Endpoint + `/forms/get/detail-create/fields/${id}`,
        { headers: { Authorization: token } }
      );
      var title = response.data.data["form-details"].name;
      var temp = [];
      // if (response.data.data.sections) {
      //   temp = Object.keys(response.data.data.sections);
      // }
      setFormTitle(title);
      // setSetions(temp);
      setSectionFields(response.data.data.sections || []);
      setShow(true);
    }
  }, [typeof window]);
  function handleChange({ currentTarget: input }) {
    var temp = { ...field };
    temp[input.name] = input.value;
    setField(temp);
  }
  function uploadImage(e) {
    var temp = field;
    temp[e.target.name] = e.target.files[0];
    setField(temp);
  }
  async function submit(e) {
    e.preventDefault();
    setShow(false);
    var form = new FormData();
    for (var key in field) {
      if (
        field[key] !== null ||
        field[key] !== undefined ||
        field[key] !== ""
      ) {
        form.append(key, field[key]);
      }
    }
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        Endpoint + `/forms/get/detail-create/fields/${id}`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast("Form Submit Successfully", "success", 3000, "top-center");

        setTimeout(() => {
          window.location.reload();
        }, 3500);
      }
    } catch (e) {
      console.log(e);
      setShow(true);
      // firetoast(e.response.data.message, "error", 5000, "top-right");
      // ErrorHandler(e.response.data.error);
      if (
        e.response.data.error == null ||
        e.response.data.error === undefined
      ) {
        firetoast(e.response.data.message, "error", 3000, "top-right");
      } else {
        ErrorHandler(e.response.data.error);
      }
    }
  }
  const handleRadioAndCheck = (value, name) => {
    console.log(value, name);
    var temp = { ...field };
    temp[name] = value;
    setField(temp);
  };
  const handleRating = (rate, name) => {
    var temp = { ...field };
    temp[name] = rate;
    setField(temp);
  };
  const handleSelect2 = (e, name) => {
    var values = [];
    for (let i = 0; i < e.length; i++) {
      values.push(e[i].value);
    }
    if (e.length > 0) {
      var temp = { ...field };
      temp[name] = values.toString();
      setField(temp);
    } else {
      var temp = { ...field };
      temp[name] = "";
      setField(temp);
    }
  };
  return (
    <>
      {/* <UserNavbar /> */}

      <div style={{ marginTop: "4rem" }}>
        <div className="container">
          <div className="text-center">
            <h4 className="text-dark">{formTitle}</h4>
          </div>
          {show ? (
            <div className="mt-4">
              {sectionFields.map((section, index) => (
                <div className="card mb-2" key={index}>
                  <div className="card-header bg-white">
                    <strong>{section["section-name"]}</strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {section.data.map((field, index) => (
                        <div className="col-6 mb-2" key={index}>
                          <div className="row">
                            <div className="col-4 my-auto">
                              {field.field_name}{" "}
                              {field.is_mandatory === 1 && (
                                <span className="text-danger">*</span>
                              )}
                            </div>
                            <div className="col-8 my-auto">
                              {" "}
                              <FormInput
                                field={field}
                                handleChange={handleChange}
                                uploadImage={uploadImage}
                                handleRadioAndCheck={handleRadioAndCheck}
                                handleSelect2={handleSelect2}
                                handleRating={handleRating}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center" style={{ height: "100px" }}>
              <Spinner color="dark" /> Generating Form...
            </div>
          )}

          <div className="text-right mb-5">
            <button
              className="btn btn-md btn-dark"
              onClick={submit}
              type="button"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreatedForm;
