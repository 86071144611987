import { useHistory } from "react-router-dom";
function MenuItems() {
  const history = useHistory();
  return (
    <div
      className="menu-items mt-2"
      style={{ backgroundColor: "black", color: "white" }}
    >
      <div
        style={{
          overflowX: "auto",
          overflowY: "hidden",
          padding: "10px",
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div
          style={{
            display: "inline-block",
            padding: "0px 10px",
          }}
          className="menu-item-links"
        >
          <i class="fas fa-tachometer-alt"></i> Dashboard
        </div>
        <div
          style={{
            display: "inline-block",

            padding: "0px 10px",
          }}
          className="menu-item-links"
          onClick={() => history.push("/admin/users")}
        >
          <i class="fas fa-users"></i> Users
        </div>
        <div
          style={{
            display: "inline-block",
            padding: "0px 10px",
          }}
          className="menu-item-links"
          onClick={() => history.push("/admin/fields")}
        >
          <i class="fab fa-wpforms"></i> Fields
        </div>
        <div
          style={{
            display: "inline-block",
            padding: "0px 10px",
          }}
          className="menu-item-links"
          onClick={() => history.push("/admin/subscriptions")}
        >
          <i class="fas fa-credit-card"></i> Subscription
        </div>
        <div
          style={{
            display: "inline-block",
            padding: "0px 10px",
          }}
          className="menu-item-links"
          onClick={() => history.push("/admin/app-content")}
        >
          <i class="fas fa-mobile-alt"></i> App Content
        </div>
        <div
          style={{
            display: "inline-block",
            padding: "0px 10px",
          }}
          className="menu-item-links"
          onClick={() => history.push("/admin/notifications")}
        >
          <i class="fas fa-bell"></i> Notifications
        </div>
      </div>
    </div>
  );
}
export default MenuItems;
