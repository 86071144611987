import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { Spinner } from "reactstrap";
import NavbarThree from "./../Layout/NavbarThree";
import { Endpoint } from "./../Utils/Endpoint";
import firetoast from "./../Utils/Firetoast";
import ErrorHandler from "./../Utils/ErrroHandler";
function UserProfile() {
  const [user, setUser] = useState({});
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      getUserProfile();
    }
  }, [typeof window]);
  var getUserProfile = async () => {
    var token = localStorage.getItem("token");

    const response = await axios.get(Endpoint + `/business/get/user-details`, {
      headers: { Authorization: token },
    });
    setUser(response.data.data.user);
  };
  var updatePassword = async () => {
    setIsLoading(true);
    if (current_password.length < 1) {
      setIsLoading(false);
      return firetoast(
        "Please provide current password",
        "error",
        3000,
        "top-right"
      );
    } else if (password.length < 1) {
      setIsLoading(false);
      return firetoast(
        "Please provide new password",
        "error",
        3000,
        "top-right"
      );
    } else if (password_confirmation.length < 1) {
      setIsLoading(false);
      return firetoast(
        "Please provide password confirmation",
        "error",
        3000,
        "top-right"
      );
    } else if (password !== password_confirmation) {
      setIsLoading(false);
      return firetoast("Password Mismatched", "error", 3000, "top-right");
    } else {
      var form = new FormData();
      form.append("current_password", current_password);
      form.append("password", password);
      form.append("password_confirmation", password_confirmation);

      try {
        var token = localStorage.getItem("token");
        var response = await axios.post(
          Endpoint + "/business/user/change-password",
          form,
          { headers: { Authorization: token } }
        );
        if (response.data.status === 200) {
          firetoast(
            "Password updated successfully",
            "success",
            3000,
            "top-center"
          );
          setCurrentPassword("");
          setPassword("");
          setPasswordConfirmation("");
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          if (
            e.response.data.error == null ||
            e.response.data.error === undefined
          ) {
            firetoast(e.response.data.message, "error", 3000, "top-right");
          } else {
            ErrorHandler(e.response.data.error);
          }
        } else {
          console.log(e);
        }
      }
    }
  };
  return (
    <>
      <NavbarThree />

      <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
        <div className="text-center">
          <h4 className="color-default">Profile</h4>
        </div>
        <div className="row m-0">
          <div className="col-md-10 m-auto">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        style={{ height: "125px" }}
                        src={
                          user.image
                            ? user.image
                            : "https://media.istockphoto.com/vectors/no-image-available-sign-vector-id1138179183?k=20&m=1138179183&s=612x612&w=0&h=iJ9y-snV_RmXArY4bA-S4QSab0gxfAMXmXwn5Edko1M="
                        }
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-6">
                        <label>
                          <b>First Name</b>
                        </label>
                        <div>{user.first_name}</div>
                      </div>
                      <div className="col-6">
                        <label>
                          <b>Last Name</b>
                        </label>
                        <div>{user.last_name}</div>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-6">
                        <label>
                          <b>Email</b>
                        </label>
                        <div>{user.email}</div>
                      </div>
                      <div className="col-6">
                        <label>
                          <b>Account Created On</b>
                        </label>
                        <div>
                          {moment(user.created_at).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <div className="text-center">
                      <h6>Update Your Password!</h6>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="row">
                      <div className="col-8 m-auto">
                        <div className="mt-2">
                          <label>Old Password</label>
                          <input
                            className="form-control"
                            placdeholder="Current Password"
                            type="password"
                            value={current_password}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </div>

                        <div className="mt-2">
                          <label>New Password</label>
                          <input
                            className="form-control"
                            placdeholder="New Password"
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mt-2">
                          <label>Confirm Password</label>
                          <input
                            className="form-control"
                            type="password"
                            value={password_confirmation}
                            onChange={(e) =>
                              setPasswordConfirmation(e.target.value)
                            }
                            placdeholder="Password Confirmation"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-right mt-3">
                      <button
                        className="btn btn-dark text-white"
                        onClick={() => updatePassword()}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner color="light" size="md" />
                        ) : (
                          "Update Password"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UserProfile;
