import React, { Component } from "react";
import { Link } from "react-router-dom";
// import FacebookLogin from "react-facebook-login";
// import GoogleLogin from "react-google-login";
import axios from "axios";
import { Endpoint } from "./../Utils/Endpoint";
// import { urlencoded } from "body-parser";
// import business from "../images/business.svg";
// import user from "../images/user.svg";
import Footer from "./../Layout/Footer";
import Navbar from "./../Layout/Navbar";
import firetoast from "./../Utils/Firetoast";
import ErrorHandler from "./../Utils/ErrroHandler";
import SocialLoginButtons from "./../Social Logins/SocialLoginButtons";
import { Spinner } from "reactstrap";
class Signup extends Component {
  state = { data: {}, role: "user", isLoading: false };

  render() {
    return (
      <>
        <Navbar />

        {/* <PageHeader
          pageTitle="Signup"
          breadcrumbTextOne="Home"
          breadcrumbUrl="/"
          breadcrumbTextTwo="Signup"
        /> */}

        <div className="container" style={{ marginTop: "8rem" }}>
          {/* {this.state.role === null ? (
            <div className="row m-5 ">
              <div className="col-8 m-auto">
                <div className="mt-5">
                  <div className="text-center">
                    <h4 style={{ color: "#362b9d" }}>Continue As...</h4>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="col-6">
                      <div
                        className="text-center"
                        style={{
                          border: "1px solid rgb(253 156 11 / 63%)",
                          padding: "12px",
                        }}
                      >
                        <div class="container-1">
                          <img
                            src={business}
                            className="image-1"
                            style={{ height: "200px" }}
                          />

                          <div class="middle-1">
                            <div class="text">
                              <button
                                className="btn"
                                style={{
                                  color: "white",
                                  backgroundColor: "#fd9c0b",
                                }}
                                onClick={() => {
                                  setTimeout(() => {
                                    this.setState({ role: 1 });
                                  }, 2000);
                                }}
                              >
                                Business
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className="text-center"
                        style={{
                          border: "1px solid rgb(253 156 11 / 63%)",
                          padding: "12px",
                        }}
                      >
                        <div class="container-1 text-center">
                          <img
                            src={user}
                            className="image-1"
                            style={{ height: "200px" }}
                          />

                          <div class="middle-1">
                            <button
                              className="btn"
                              style={{
                                color: "white",
                                backgroundColor: "#fd9c0b",
                              }}
                              onClick={() => {
                                setTimeout(() => {
                                  this.setState({ role: 2 });
                                }, 2000);
                              }}
                            >
                              User
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : ( */}
          <div className="form-content">
            <div className="form-header">
              <h3>Create an Account</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
            <ul
              class="nav nav-tabs"
              id="myTab"
              role="tablist"
              style={{ justifyContent: "center" }}
            >
              <li class="nav-item" role="presentation">
                <button
                  class={
                    this.state.role === "user" ? "nav-link active" : "nav-link"
                  }
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => this.setState({ role: "user" })}
                >
                  As User
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={
                    this.state.role === "business"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() => this.setState({ role: "business" })}
                >
                  As Business
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class={
                  this.state.role === "user" ? "tab-pane active" : "tab-pane"
                }
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <form onSubmit={this.handleSubmit} className="mt-5">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={this.handleChange}
                      placeholder="First name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last-name"
                      name="last_name"
                      onChange={this.handleChange}
                      required
                      placeholder="First name"
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={this.handleChange}
                      required
                      placeholder="Your email"
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.handleChange}
                      required
                      placeholder="Your password"
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      onChange={this.handleChange}
                      required
                      placeholder="Confirm password"
                    />
                  </div>
                  <div className="text-center">OR</div>
                  <SocialLoginButtons role={this.state.role} />
                  <button
                    type="submit"
                    className="default-btn mt-2"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <div className="text-center">
                        <Spinner color="white" size={"md"} />
                      </div>
                    ) : (
                      "Signup"
                    )}
                  </button>
                </form>
              </div>
              <div
                class={
                  this.state.role === "business"
                    ? "tab-pane active"
                    : "tab-pane"
                }
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <form onSubmit={this.handleSubmit} className="mt-5">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="first_name"
                      onChange={this.handleChange}
                      placeholder="First name"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label>Last name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="last-name"
                      name="last_name"
                      onChange={this.handleChange}
                      required
                      placeholder="First name"
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={this.handleChange}
                      required
                      placeholder="Your email"
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.handleChange}
                      required
                      placeholder="Your password"
                    />
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password_confirmation"
                      onChange={this.handleChange}
                      required
                      placeholder="Confirm password"
                    />
                  </div>
                  <div className="text-center">OR</div>
                  <SocialLoginButtons />
                  <button
                    type="submit"
                    className="default-btn mt-2"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <div className="text-center">
                        <Spinner color="white" size={"md"} />
                      </div>
                    ) : (
                      "Signup"
                    )}
                  </button>
                </form>
              </div>
            </div>

            <div className="form-footer">
              <p>
                Do you have an account?
                <Link to="/login">
                  <a className="form-link">Login</a>
                </Link>
              </p>
            </div>
          </div>
          {/* )} */}
        </div>

        <Footer />
      </>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var data = { ...this.state.data };
    var form = new FormData();
    if (data["password"] !== data["password_confirmation"]) {
      return firetoast("Password Mismatch", "error", 3000, "top-right");
    }
    for (var key in data) {
      if (this.checkField(key)) {
        form.append(key, data[key]);
      } else {
        return firetoast(
          "Please provide all fields",
          "error",
          3000,
          "top-right"
        );
      }
    }

    try {
      let response = null;

      if (this.state.role === "business") {
        response = await axios.post(Endpoint + "/business/reg", data);
      } else {
        response = await axios.post(Endpoint + "/reg", data);
      }
      if (response.data.status === 200) {
        firetoast("Registration successfull", "success", 3000, "top-right");

        setTimeout(() => {
          this.setState({ isLoading: false });
          this.props.history.push(`/otp-verify?email=${data.email}`);
        }, 2000);
      }
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
      if (e.response) {
        if (
          e.response.data.error == null ||
          e.response.data.error === undefined
        ) {
          firetoast(e.response.data.message, "error", 3000, "top-right");
        } else {
          ErrorHandler(e.response.data.error);
        }
      }
    }
  };

  checkField = (field) => {
    if (!field === "" || field == null || field === undefined) {
      return false;
    }
    return true;
  };
}

export default Signup;
