import React, { Component } from "react";
import { InputGroup } from "reactstrap";
import ReactStars from "react-rating-stars-component";
// import mapToOptions from './mapToOptions';
import Select from "react-select";
class FormInput extends Component {
  state = { field: "", selectedValue: [] };
  componentDidMount() {
    this.setState({ field: this.props.field });
  }
  mapToOptions(options) {
    console.log(options);
    var array = [];
    for (let i = 0; i < options.length; i++) {
      var obj = {
        value: options[i],
        label: options[i],
      };

      array.push(obj);
    }
    // console.log(array)
    return array;
  }

  getStyle = (color) => {
    const colourStyles = {
      multiValueLabel: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: color,
        };
      },
    };
    return colourStyles;
  };
  handlePicklistColored = (e) => {
    var selectedValue = [];
    selectedValue.push(e[e.length - 1]);
    this.setState({ selectedValue: selectedValue });
    // console.log(selectedValue);
  };
  render() {
    const { field } = this.state;

    return (
      <>
        {/*Select*/}
        {field.type === "percent" && (
          <InputGroup>
            <input
              name={field.db_field_name}
              required={field["is_mandatory"] === 0 ? false : true}
              className="form-control"
              type={field.type}
              defaultValue={this.props.defaultValue && this.props.defaultValue}
              onChange={this.props.handleChange}
            />
            {/* <InputGroupAddon addonType="append">
              <InputGroupText>
                <span style={{ fontSize: "13px", padding: "8px 12px" }}>%</span>
              </InputGroupText>
            </InputGroupAddon> */}
          </InputGroup>
        )}
        {field.type === "currency" && (
          <InputGroup>
            <input
              name={field.db_field_name}
              required={field["is_mandatory"] === 0 ? false : true}
              className="form-control"
              type={field.type}
              defaultValue={this.props.defaultValue && this.props.defaultValue}
              onChange={this.props.handleChange}
            />
            {/* <InputGroupAddon addonType="append">
              <InputGroupText>
                <span style={{ fontSize: "13px", padding: "8px 12px" }}>
                  PKR-Rs
                </span>
              </InputGroupText>
            </InputGroupAddon> */}
          </InputGroup>
        )}

        {field.type === "select" && (
          <select
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            onChange={this.props.handleChange}
          >
            <option value={null}>Select...</option>
            {field.extra_info["options"] &&
              field.extra_info["options"].map((option) => (
                <option
                  value={option}
                  selected={
                    this.props.defaultValue &&
                    this.props.defaultValue === option
                      ? true
                      : false
                  }
                >
                  {option}
                </option>
              ))}
          </select>
        )}
        {field.type === "picklist" && (
          <select
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            onChange={this.props.handleChange}
          >
            <option value={null}>Select...</option>
            {field.extra_info["options"] &&
              field.extra_info["options"].map((option, index) => (
                <option
                  key={index}
                  value={option}
                  selected={
                    this.props.defaultValue &&
                    this.props.defaultValue === option
                      ? true
                      : false
                  }
                >
                  {option}
                </option>
              ))}
          </select>
        )}

        {/* {field.type === "select" && field.extra_info["picklist_color"] && (
          <select
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            onChange={this.props.handleChange}
          >
            <option value={null}>Select...</option>
            {field.extra_info.options.map((option) => (
              <option
                value={option}
                selected={
                  this.props.defaultValue && this.props.defaultValue === option
                    ? true
                    : false
                }
              >
                {option}
              </option>
            ))}
          </select>
       
        )} */}
        {/*Text*/}
        {field.type === "text" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "url" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "time" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "checkbox" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            // className="form-control"
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            type={field.type}
            defaultChecked={
              this.props.defaultValue && parseInt(this.props.defaultValue) === 1
            }
            onChange={(e) => {
              if (e.target.checked) {
                this.props.handleRadioAndCheck(true, field.db_field_name);
              } else {
                this.props.handleRadioAndCheck(false, field.db_field_name);
              }
            }}
          />
        )}
        {field.type === "radio" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            // className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            defaultChecked={
              this.props.defaultValue && parseInt(this.props.defaultValue) === 1
            }
            onChange={(e) => {
              if (e.target.checked) {
                this.props.handleRadioAndCheck(true, field.db_field_name);
              } else {
                this.props.handleRadioAndCheck(false, field.db_field_name);
              }
            }}
          />
        )}
        {/*Email*/}
        {field.type === "email" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {/*Number*/}
        {field.type === "number" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {(field.type === "decimal" ||
          (field.type === "integer" && field.db_field_name !== "rating")) && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "rating" && (
          <ReactStars
            count={5}
            // onChange={ratingChanged}
            onChange={(e) => this.props.handleRating(e, field.db_field_name)}
            size={24}
            activeColor="#ffd700"
          />
        )}
        {/*date*/}
        {field.type === "date" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {/*textarea*/}
        {field.type === "textarea" && (
          <textarea
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "text area" && (
          <textarea
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type={field.type}
            defaultValue={this.props.defaultValue && this.props.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {/*textarea*/}
        {(field.type === "upload" ||
          field.type === "upload field" ||
          field.type === "file") && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type="file"
            onChange={this.props.uploadImage}
          />
        )}
        {field.type === "phone" && (
          <input
            name={field.db_field_name}
            required={field["is_mandatory"] === 0 ? false : true}
            className="form-control"
            type="number"
            defaultValue={field.defaultValue}
            onChange={this.props.handleChange}
          />
        )}
        {field.type === "select2" && this.returnSelect2(field)}
      </>
    );
  }
  returnSelect2 = (field) => {
    var options = [];
    let availOptions = field["extra_info"]["options"];
    for (let i = 0; i < availOptions.length; i++) {
      var obj = {
        label: availOptions[i],
        value: availOptions[i],
      };
      options.push(obj);
    }
    return (
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={options}
        onChange={(e) => this.props.handleSelect2(e, field.db_field_name)}
      />
    );
  };
}

export default FormInput;
