import { Link } from "react-router-dom";
import shape7 from "../../images/shape/shape7.png";
import shape2 from "../../images/shape/shape2.svg";
import shape3 from "../../images/shape/shape3.svg";
import shape4 from "../../images/shape/shape4.png";
import checked from "../../images/checked.png";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
function Packages() {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  return (
    <>
      <div style={{ margin: "6rem 0.5rem 0rem 0.5rem" }}>
        <div className="container">
          <div className="text-right">
            <Link to="/forms">
              {" "}
              Skip Now <i class="fas fa-chevron-right"></i>
            </Link>
          </div>
        </div>
        <div className="text-center">
          <h4 className="color-default">Pricing Plan</h4>
        </div>
        <div className="row m-0">
          <div className="col-md-10 m-auto">
            <section className="pricing-area ptb-100 pt-0">
              <div className="container">
                <div className="section-title">
                  <h2>Choose Your Best Plan</h2>
                  <p>
                    Real innovations and a positive customer experience are the
                    heart of successful communication. No fake products and
                    services.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box">
                      <div className="pricing-header">
                        <h3>Basic</h3>
                      </div>

                      <div className="price">
                        <sub>$</sub>
                        39.99
                        <sub>/mo</sub>
                      </div>

                      <ul className="price-features-list">
                        <li>
                          <i className="flaticon-tick"></i> 1 Projects
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Email Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Phone Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Article Promotion
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Editorial Services
                        </li>
                        <li>
                          <i className="flaticon-close"></i> Profile Management
                        </li>
                        <li>
                          <i className="flaticon-close"></i> Selection Support
                        </li>
                      </ul>

                      <Link
                        to="#"
                        className="get-started-btn"
                        onClick={() => setModal(!modal)}
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box ">
                      <div className="pricing-header">
                        <h3>Starter</h3>
                      </div>

                      <div className="price">
                        <sub>$</sub>
                        49.99
                        <sub>/mo</sub>
                      </div>

                      <ul className="price-features-list">
                        <li>
                          <i className="flaticon-tick"></i> 2 Projects
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Email Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Phone Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Article Promotion
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Editorial Services
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Profile Management
                        </li>
                        <li>
                          <i className="flaticon-close"></i> Selection Support
                        </li>
                      </ul>

                      <Link
                        to="#"
                        className="get-started-btn"
                        onClick={() => setModal(!modal)}
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div className="single-pricing-box ">
                      <div className="pricing-header">
                        <h3>Extended</h3>
                      </div>

                      <div className="price">
                        <sub>$</sub>
                        59.99
                        <sub>/mo</sub>
                      </div>

                      <ul className="price-features-list">
                        <li>
                          <i className="flaticon-tick"></i> 3 Projects
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Email Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Phone Support
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Article Promotion
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Editorial Services
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Profile Management
                        </li>
                        <li>
                          <i className="flaticon-tick"></i> Selection Support
                        </li>
                      </ul>

                      <Link
                        to="#"
                        className="get-started-btn"
                        onClick={() => setModal(!modal)}
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Animation Shape Images */}
              <div className="shape-img7">
                <img src={shape7} alt="image" />
              </div>
              <div className="shape-img2">
                <img src={shape2} alt="image" />
              </div>
              <div className="shape-img3">
                <img src={shape3} alt="image" />
              </div>
              <div className="shape-img4">
                <img src={shape4} alt="image" />
              </div>
            </section>
          </div>
        </div>
        <Modal isOpen={modal} toggle={() => setModal(!modal)} backdrop="static">
          <ModalHeader toggle={() => setModal(!modal)}>
            Package Selection
          </ModalHeader>
          <ModalBody>
            <div className="text-center">
              <h4>Congratulations!</h4>
            </div>
            <div className="text-center">
              <img
                src={checked}
                className="img-fluid avatar"
                style={{ height: "100px" }}
              />
            </div>
            <div className="text-center mt-2">
              <p>Your package details have been saved!</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => history.push("/forms")}>
              OK! Continue
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}
export default Packages;
