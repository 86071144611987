import { useState, useEffect } from "react";
import AdminNavbar from "./../../Layout/AdminNavbar";
import MenuItems from "./../MenuItems";
import { Spinner } from "reactstrap";
import axios from "axios";
import { Endpoint } from "./../../Utils/Endpoint";
import firetoast from "./../../Utils/Firetoast";

function AppContent() {
  const [IsLoading, setIsLoading] = useState(false);
  const [EmailChange, setEmailChange] = useState(false);
  const [NumberChange, setNumberChange] = useState(false);
  const [PolicyChange, setPolicyChange] = useState(false);
  const [AppChange, setAppChange] = useState(false);
  const [TermsChange, setTermsChange] = useState(false);
  const [Data, setData] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    getData();
  }, []);
  let getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${Endpoint}/admin/settings`, {
        headers: {
          Authorization: token,
        },
      });
      setData(response.data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("Error while fetching data", e);
    }
  };
  let updateSupportEmail = async () => {
    let value = document.getElementById("supportEmail").value;
    if (!value || value === "") {
      return firetoast(
        "Support Email shouldn't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/settings`,
        {
          slug: "support-email",
          value: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        response.status === 200 ||
        response.data.status === 200 ||
        response.data.success === true
      ) {
        setEmailChange(false);
        return firetoast("Updated!", "dark", 3000, "top-right");
      }
    } catch (e) {
      return firetoast(
        "Error while updating email",
        "error",
        3000,
        "top-right"
      );
    }
  };
  let updateSupportNumber = async () => {
    let value = document.getElementById("supportNumber").value;
    if (!value || value === "") {
      return firetoast(
        "Support Number shouldn't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/settings`,
        {
          slug: "support-cell",
          value: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        response.status === 200 ||
        response.data.status === 200 ||
        response.data.success === true
      ) {
        setNumberChange(false);
        return firetoast("Updated!", "dark", 3000, "top-right");
      }
    } catch (e) {
      return firetoast(
        "Error while updating number",
        "error",
        3000,
        "top-right"
      );
    }
  };
  let updatePolicy = async () => {
    let value = document.getElementById("policy").value;
    if (!value || value === "") {
      return firetoast("Policy shouldn't be empty", "error", 3000, "top-right");
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/settings`,
        {
          slug: "privacy-policy",
          value: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        response.status === 200 ||
        response.data.status === 200 ||
        response.data.success === true
      ) {
        setPolicyChange(false);
        return firetoast("Updated!", "dark", 3000, "top-right");
      }
    } catch (e) {
      return firetoast(
        "Error while updating policy",
        "error",
        3000,
        "top-right"
      );
    }
  };
  let updateApp = async () => {
    let value = document.getElementById("App").value;
    if (!value || value === "") {
      return firetoast(
        "App content shouldn't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/settings`,
        {
          slug: "about-app",
          value: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        response.status === 200 ||
        response.data.status === 200 ||
        response.data.success === true
      ) {
        setAppChange(false);
        return firetoast("Updated!", "dark", 3000, "top-right");
      }
    } catch (e) {
      return firetoast(
        "Error while updating app content",
        "error",
        3000,
        "top-right"
      );
    }
  };
  let updateTerms = async () => {
    let value = document.getElementById("Terms").value;
    if (!value || value === "") {
      return firetoast(
        "Terms & Conditions shouldn't be empty",
        "error",
        3000,
        "top-right"
      );
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/settings`,
        {
          slug: "terms-conditions",
          value: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (
        response.status === 200 ||
        response.data.status === 200 ||
        response.data.success === true
      ) {
        setTermsChange(false);
        return firetoast("Updated!", "dark", 3000, "top-right");
      }
    } catch (e) {
      return firetoast(
        "Error while updating terms and conditions",
        "error",
        3000,
        "top-right"
      );
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        {IsLoading ? (
          <div className="mt-5 text-center">
            <Spinner color="dark" /> Getting Data...
          </div>
        ) : (
          <>
            <div className="row mt-2">
              <div className="col-6">
                <h4 className="text-dark"> App Content </h4>
              </div>
              <div className="mt-2">
                <form>
                  <div className="row">
                    <div className="col-6">
                      <label>Support Email</label>
                      <input
                        className="form-control"
                        type="email"
                        id="supportEmail"
                        defaultValue={Data && Data[0]["value"]}
                        onChange={() => {
                          setEmailChange(true);
                        }}
                      />
                      {EmailChange && (
                        <button
                          className="btn btn-dark btn-sm mt-2"
                          type="button"
                          onClick={() => updateSupportEmail()}
                        >
                          Save
                        </button>
                      )}
                    </div>
                    <div className="col-6">
                      <label>Support Cell</label>
                      <input
                        className="form-control"
                        type="number"
                        id="supportNumber"
                        defaultValue={Data && parseInt(Data[1]["value"])}
                        onChange={() => {
                          setNumberChange(true);
                        }}
                      />
                      {NumberChange && (
                        <button
                          className="btn btn-dark btn-sm mt-2"
                          type="button"
                          onClick={() => {
                            updateSupportNumber();
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>Privacy Policy</label>
                      <textarea
                        className="w-100"
                        rows={"10"}
                        id="policy"
                        defaultValue={Data && Data[2]["value"]}
                        onChange={() => {
                          setPolicyChange(true);
                        }}
                      />
                      {PolicyChange && (
                        <button
                          className="btn btn-dark btn-sm mt-2"
                          type="button"
                          onClick={() => updatePolicy()}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>About App</label>
                      <textarea
                        className="w-100"
                        rows={"10"}
                        id="App"
                        defaultValue={Data && Data[3]["value"]}
                        onChange={() => {
                          setAppChange(true);
                        }}
                      />
                      {AppChange && (
                        <button
                          className="btn btn-dark btn-sm mt-2"
                          type="button"
                          onClick={() => updateApp()}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <label>Terms & Conditions</label>
                      <textarea
                        className="w-100"
                        rows={"10"}
                        id="Terms"
                        defaultValue={Data && Data[4]["value"]}
                        onChange={() => {
                          setTermsChange(true);
                        }}
                      />
                      {TermsChange && (
                        <button
                          className="btn btn-dark btn-sm mt-2"
                          type="button"
                          onClick={() => updateTerms()}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>

      {/* <Footer /> */}
    </>
  );
}
export default AppContent;
