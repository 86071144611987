import { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import UserNavbar from "./../Layout/UserNavbar";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import AnimatedImages from "./../Helpers/AnimatedImages";
function UserHistory() {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (window !== undefined || window !== "undefined") {
      getFormHistory();
    }
  }, [typeof window]);
  var getFormHistory = async () => {
    try {
      var token = localStorage.getItem("token");
      var response = await axios.get(`${Endpoint}/forms/get/form-history`, {
        headers: { Authorization: token },
      });
      setRecords(response.data.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      firetoast("Something went wrong!", "error", 3000, "top-right");
      console.log(e);
    }
  };
  return (
    <>
      <UserNavbar />

      <div className="container">
        {isLoading ? (
          <div
            className="text-center"
            style={{ height: "100px", marginTop: "25px" }}
          >
            <Spinner color="dark" /> Getting Records....
          </div>
        ) : (
          <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
            <div className="text-center">
              <h4 className="color-default">Record History</h4>
            </div>
            <div className="mt-3 table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Submission Date </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records.length > 0 &&
                    records.map((record, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{record.title}</td>
                        <td>
                          {moment(record.submission_date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <Link
                            className="btn btn-sm btn-dark text-white"
                            to={`/form-history-detail/${record.recor_id}/${record.form_id}`}
                          >
                            Show Detail
                          </Link>{" "}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <AnimatedImages />
      </div>
    </>
  );
}
export default UserHistory;
