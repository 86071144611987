import { Link } from "react-router-dom";

function FormTable(props) {
  return (
    <table className="table table-bordered mt-1">
      <thead>
        <tr>
          <th></th>
          <th>Form Name</th>
          {/* <th>Status</th> */}
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.forms.map((form, index) => (
          <tr key={index} style={{ cursor: "pointer" }}>
            <td>
              <i class="fab fa-wpforms"></i>
            </td>
            <td>{form.name}</td>
            {/* <td>
              {form.status === 0 ? (
                <span
                  class="badge badge-pill badge-danger"
                  style={{ backgroundColor: "#dc3545" }}
                >
                  Incomplete
                </span>
              ) : (
                <span
                  class="badge badge-pill badge-warning"
                  style={{ backgroundColor: "#28a745" }}
                >
                  Completed
                </span>
              )}{" "}
            </td> */}
            <td>
              <>
                <i class="fas fa-cog" style={{ paddingRight: "5px" }}></i>
                <Link to={`/formDetails/${form.id}`}>Set Fields</Link>
              </>
            </td>

            <td>
              <>
                <i class="far fa-clipboard" style={{ paddingRight: "5px" }}></i>
                <Link to={`/submitted-records/${form.id}`}>
                  View Form Records
                </Link>
              </>
            </td>
            <td>
              <>
                <i class="fas fa-qrcode" style={{ paddingRight: "5px" }}></i>

                <Link to={`/generate-qr/${form.id}/${form.name}`}>
                  Generate Qr
                </Link>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
export default FormTable;
