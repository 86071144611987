import AdminNavbar from "./../Layout/AdminNavbar";
import Footer from "./../Layout/Footer";
import moment from "moment";
import { useState, useEffect } from "react";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import axios from "axios";
import { Spinner } from "reactstrap";
import MenuItems from "./MenuItems";
function AdminDashboard() {
  const [DateFrom, setDateFrom] = useState(moment().format("YYYY-MM-DD"));
  const [DateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));

  const [FormsCreated, setFormsCreated] = useState(0);
  const [FormsSubmitted, setFormsSubmitted] = useState(0);
  const [Customers, setCustomers] = useState(0);
  const [Business, setBusiness] = useState(0);
  const [Total, setTotal] = useState(0);
  const [IsLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDate();
  }, []);
  let getDate = async () => {
    try {
      var token = localStorage.getItem("token");
      var response = await axios.get(`${Endpoint}/admin/dashboard`, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        setFormsCreated(response.data.data.forms_created);
        setFormsSubmitted(response.data.data.forms_submitted);
        setCustomers(response.data.data.active_users.customer);
        setBusiness(response.data.data.active_users.business);
        setTotal(response.data.data.active_users.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        firetoast("Something went wrong!", "error", 2000, "top-right");
      }
    } catch (e) {
      setIsLoading(false);
      firetoast("Something went wrong!", "error", 2000, "top-right");
    }
  };
  let getDateByDate = async () => {
    setIsLoading(true);
    try {
      var token = localStorage.getItem("token");
      let data = new FormData();
      data.append("date_start", DateFrom);
      data.append("date_end", DateTo);

      var response = await axios.post(`${Endpoint}/admin/dashboard`, data, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        setFormsCreated(response.data.data.forms_created);
        setFormsSubmitted(response.data.data.forms_submitted);
        setCustomers(response.data.data.active_users.customer);
        setBusiness(response.data.data.active_users.business);
        setTotal(response.data.data.active_users.total);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        firetoast("Something went wrong!", "error", 2000, "top-right");
      }
    } catch (e) {
      setIsLoading(false);
      firetoast("Something went wrong!", "error", 2000, "top-right");
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />
        <div className="row mt-2">
          <div className="col-6">
            <h4 className="text-dark"> Dashboard</h4>
          </div>
          <div className="col-6">
            <div className="row">
              <div class="col-6">
                <div className="row">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <div>From</div>
                    <div>
                      {" "}
                      <input
                        type="date"
                        class="form-control"
                        id="staticEmail"
                        value={DateFrom}
                        onChange={(e) => {
                          setDateFrom(e.target.value);
                          getDateByDate();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div className="row">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <div>To</div>
                    <div>
                      <input
                        type="date"
                        class="form-control"
                        id="staticEmail"
                        value={DateTo}
                        onChange={(e) => {
                          setDateTo(e.target.value);
                          getDateByDate();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {IsLoading ? (
          <div className="mt-5 text-center">
            <Spinner color="dark" /> Getting Data...
          </div>
        ) : (
          <>
            <div className="row mt-4">
              <div className="col-4">
                <div class="card-counter dark" style={{ position: "relative" }}>
                  <i class="fas fa-globe-americas"></i>
                  <span class="count-numbers">{Total}</span>
                  <span class="count-name">Total Active</span>
                </div>
              </div>
              <div className="col-4">
                <div class="card-counter dark" style={{ position: "relative" }}>
                  <i class="fas fa-briefcase"></i>
                  <span class="count-numbers">{Business}</span>
                  <span class="count-name">Active Business</span>
                </div>
              </div>
              <div className="col-4">
                <div class="card-counter dark" style={{ position: "relative" }}>
                  <i class="fas fa-users"></i>
                  <span class="count-numbers">{Customers}</span>
                  <span class="count-name">Active Customers</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div class="card-counter dark" style={{ position: "relative" }}>
                  <i class="fas fa-globe-americas"></i>
                  <span class="count-numbers">{FormsCreated}</span>
                  <span class="count-name">Forms Created</span>
                </div>
              </div>
              <div className="col-4">
                <div class="card-counter dark" style={{ position: "relative" }}>
                  <i class="fas fa-briefcase"></i>
                  <span class="count-numbers">{FormsSubmitted}</span>
                  <span class="count-name">Forms Submitted</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* <Footer /> */}
    </>
  );
}
export default AdminDashboard;
