import React, { Component } from "react";
// import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import SidebarModal from "../SidebarModal/SidebarModal";
import Logo from "../../images/logo.png";
import firetoast from "./../Utils/Firetoast";
class AdminNavbar extends Component {
  // Sidebar Modal
  state = {
    sidebarModal: false,
    user: "",
    token: "",
  };
  toggleModal = () => {
    this.setState({
      sidebarModal: !this.state.sidebarModal,
    });
  };

  handleSearchForm = () => {
    this.setState((prevState) => {
      return {
        searchForm: !prevState.searchForm,
      };
    });
  };

  // Navbar
  _isMounted = false;
  state = {
    display: false,
    collapsed: true,
  };
  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  componentDidMount() {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
    this.setState({
      user:
        localStorage.getItem("nopen-user") &&
        localStorage.getItem("nopen-user"),
      token: localStorage.getItem("token") && localStorage.getItem("token"),
      role:
        localStorage.getItem("token") &&
        localStorage.getItem("nopen-user") &&
        JSON.parse(localStorage.getItem("nopen-user")).role_id,
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    let { products } = this.props;
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";

    return (
      <>
        <div id="navbar" className="navbar-area">
          <div className="adani-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src={Logo} alt="logo" style={{ height: "80px" }} />
                </Link>

                <button
                  onClick={this.toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul className="navbar-nav">
                    {localStorage.getItem("nopen-user") &&
                      localStorage.getItem("token") && (
                        <li>
                          <Link
                            className="nav-link"
                            to={
                              this.state.role === "2"
                                ? "/user-landing"
                                : this.state.role === "3"
                                ? "/forms"
                                : "/admin/dashboard"
                            }
                            style={{ padding: "7px 20px" }}
                          >
                            Home
                          </Link>
                        </li>
                      )}
                    {localStorage.getItem("nopen-user") &&
                      localStorage.getItem("token") && (
                        <li>
                          <Link
                            className="nav-link"
                            to={"#"}
                            style={{ padding: "7px 20px" }}
                            onClick={() => {
                              localStorage.clear();
                              firetoast(
                                "Logging Out...",
                                "info",
                                3000,
                                "top-right"
                              );
                              setTimeout(() => {
                                window.location.href = "/";
                              }, 3000);
                            }}
                          >
                            Logout
                          </Link>
                        </li>
                      )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdminNavbar;
