import React, { useEffect, useState } from "react";
import axios from "axios";
import NavbarThree from "./../Layout/NavbarThree";
import { useParams } from "react-router-dom";
import { Endpoint } from "./../Utils/Endpoint";
import firetoast from "./../Utils/Firetoast";
import TextFields from "./Conditional fields/textFields";
import MultiComboFields from "./Conditional fields/multiComboFileds";
import DecimalFields from "./Conditional fields/decimalFields";
import IntegerFields from "./Conditional fields/integerFields";
import PercentFields from "./Conditional fields/percentFields";
import DateFields from "./Conditional fields/dateFields";
import EmailFields from "./Conditional fields/emailFields";
import UrlFields from "./Conditional fields/urlFields";
import CheckboxFields from "./Conditional fields/checkboxFields";
import TextAreaFields from "./Conditional fields/textareaFields";
import TimeFields from "./Conditional fields/timeFields";
import MultiCurrencyFields from "./Conditional fields/multiCurrencyFields";
import PhoneFields from "./Conditional fields/phoneFields";
import UploadFields from "./Conditional fields/uploadFields";
import PicklistFields from "./Conditional fields/pickListFields";
import RadioFields from "./Conditional fields/radioFields";
import ErrorHandler from "./../Utils/ErrroHandler";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
function FormDetails() {
  const { id, FormName } = useParams();
  const [formname, setFormName] = useState("");
  const [sections, setSectionName] = useState([]);
  const [addmodal, setAddModal] = useState(false);
  const [sectionFields, setSectionFields] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [customField, toggleCustomField] = useState(false);
  const [allFields, setAllFields] = useState([]);
  const [fieldId, setFieldId] = useState(null);
  const [sectionType, setSectionType] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [isSecLoading, setIsSecLoading] = useState(true);
  const [isSecFieldLoading, SetIsSecFieldLoading] = useState(true);
  const [ordermodal, setOrderModal] = useState(false);
  const [orderSectionId, setOrderSectionId] = useState(null);
  const [order, setOrder] = useState(null);
  const [universalFields, setuniversalFields] = useState([]);
  const [FieldModal, setFieldModal] = useState(false);
  const [universalValue, setUniversalValue] = useState(null);
  const [waiting, setWaiting] = useState(false);
  useEffect(async () => {
    initData();
    setisLoading(false);
    setShowMessage(true);
    setIsSecLoading(false);
    SetIsSecFieldLoading(false);
  }, [typeof window, addmodal, ordermodal, sections.length]);
  var initData = async () => {
    setWaiting(true);
    const token = localStorage.getItem("token");

    try {
      var response = await axios.get(Endpoint + `/business/forms/by-id/${id}`, {
        headers: { Authorization: token },
      });
    } catch (e) {
      console.log(e);
    }
    const response_ = await axios.get(
      Endpoint + `/business/forms/sections/by-form/${id}`,
      { headers: { Authorization: token } }
    );
    let temSec = response_.data.data;
    let tempSections = [];
    for (let i = 0; i < temSec.length; i++) {
      tempSections.push({ id: temSec[i].id, name: temSec[i].name });
    }
    var fields = [];
    if (tempSections.length > 0) {
      const response__ = await axios.get(
        Endpoint +
          `/business/forms/sections/fields/by-section/${tempSections[0].id}`,
        { headers: { Authorization: token } }
      );
      fields = response__.data.data;
      setSectionFields(fields);
      setSelectedSection(tempSections[0].name);
      setSectionId(tempSections[0].id);
    }
    const response___ = await axios.get(
      Endpoint + "/business/forms/sections/fields/types",
      { headers: { Authorization: token } }
    );
    const response____ = await axios.get(
      Endpoint + "/business/forms/sections/universal-fields",
      { headers: { Authorization: token } }
    );
    setuniversalFields(response____.data.data);
    setFormName(FormName);
    // setFormStatus(response.data.data.status);
    setSectionName(tempSections);
    setAllFields(response___.data.data);
    setSectionType(response___.data.data[0].id);
    setFieldId(response___.data.data[0].id);
    setWaiting(false);
  };
  // var getSectionFields = async (id) => {
  //   const response__ = await axios.get(
  //     Endpoint + `/business/forms/sections/fields/by-section/${id}`,
  //     { headers: { Authorization: token } }
  //   );
  //   fields = response__.data.data;
  //   setSectionFields(fields);
  // };
  var getSectionFields = async (id) => {
    setIsSecLoading(true);
    SetIsSecFieldLoading(true);
    const token = localStorage.getItem("token");
    const response__ = await axios.get(
      Endpoint + `/business/forms/sections/fields/by-section/${id}`,
      { headers: { Authorization: token } }
    );
    setSectionFields(response__.data.data);
    setIsSecLoading(false);
    SetIsSecFieldLoading(false);
  };
  var AddNewSection = async () => {
    setIsSecLoading(true);
    const token = localStorage.getItem("token");
    var value = document.getElementById("newSection").value;
    var form = new FormData();
    form.append("name", value);
    form.append("form_id", id);

    try {
      var response = await axios.post(
        Endpoint + `/business/forms/sections`,
        form,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        firetoast("Section Added", "success", 3000, "top-right");
        initData();
        setAddModal(!addmodal);
        setIsSecLoading(false);
      }
    } catch (e) {
      setIsSecLoading(false);
      if (e.response) {
        if (
          e.response.data.error == null ||
          e.response.data.error === undefined
        ) {
          firetoast(e.response.data.message, "error", 3000, "top-right");
        } else {
          ErrorHandler(e.response.data.error);
        }
      }
    }
  };
  var handleFieldType = ({ currentTarget: input }) => {
    var field = JSON.parse(input.value);
    setSectionType(field.id);
    setFieldId(field.id);
  };
  var setForm = async (status) => {
    var token = localStorage.getItem("token");
    try {
      var response = await axios.post(
        Endpoint + "/business/forms/update-form",
        {
          form_id: id,
          status: status,
        },
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast("Status updated!", "success", 3000, "top-right");
        if (status === "complete") {
          setFormStatus(1);
        } else {
          setFormStatus(0);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  var UpdateOrder = async () => {
    if (orderSectionId == null || order == null) {
      firetoast("Select Order Section or order", "error", 3000, "top-right");
    } else {
      var token = localStorage.getItem("token");
      try {
        var response = await axios.post(
          `${Endpoint}/business/forms/sections/set-order`,
          {
            section_id: Number(orderSectionId),
            order: Number(order),
          },
          {
            headers: { Authorization: token },
          }
        );
        if (response.data.status === 200) {
          firetoast("Section Order updated!", "success", 3000, "top-center");
          setOrderModal(!ordermodal);
        }
      } catch (e) {
        if (e.response) {
          if (
            e.response.data.error == null ||
            e.response.data.error === undefined
          ) {
            firetoast(e.response.data.message, "error", 3000, "top-right");
          } else {
            ErrorHandler(e.response.data.error);
          }
        }
      }
    }
  };

  return (
    <>
      <NavbarThree />

      <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
        {waiting || isLoading ? (
          <div style={{ textAlign: "center", height: "100px" }}>
            <Spinner color="dark" /> Getting Data
          </div>
        ) : (
          <>
            <div className="text-center">
              <h4 className="mb-5">{formname}</h4>
            </div>
            <div className="row m-0">
              {showMessage && sections.length == 0 && (
                <span className="alert alert-warning">
                  It seems you haven't added any section in form, Add a new
                  section to continue{" "}
                  <button
                    className="btn btn-dark"
                    onClick={() => setAddModal(!addmodal)}
                  >
                    {" "}
                    Add Section
                  </button>
                </span>
              )}
              <div className="col-3">
                {sections.length > 0 && (
                  <div style={{ alignItems: "baseline" }}>
                    <h5>Fields</h5>
                    <div
                      className="row"
                      style={{
                        overflowX: "hidden",
                        overflowY: "auto",
                        height: "50vh",
                      }}
                    >
                      {universalFields.map((field, index) => (
                        <div
                          className="col-6 mt-1 "
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          <div
                            class="card text-center card-hover-box"
                            onClick={() => {
                              setSectionType(field.type_id);
                              setFieldId(field.type_id);
                              setUniversalValue(field);
                              toggleCustomField(!customField);
                            }}
                          >
                            <img
                              class="card-img-top m-auto"
                              src={field.image}
                              style={{ height: "75px", width: "75px" }}
                              alt={field.db_field_name}
                            />
                            <div
                              class="card-body p-1"
                              style={{ fontSize: "13px" }}
                            >
                              {field.field_name}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="col-6 mt-1 "
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          class="card text-center card-hover-box"
                          onClick={() => {
                            // setFieldModal(!FieldModal);
                            setUniversalValue(null);
                            toggleCustomField(!customField);
                          }}
                        >
                          <img
                            class=" m-auto"
                            style={{ height: "70px", width: "70px" }}
                            src={
                              "https://img.icons8.com/ios-filled/30/fa314a/menu-2.png"
                            }
                            alt={"Others"}
                          />
                          <div
                            class="card-body p-1"
                            style={{ fontSize: "13px" }}
                          >
                            Others
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-7">
                {sections.length > 0 && (
                  <div className="text-right mb-1">
                    {formStatus === 0 && (
                      <button
                        className="btn btn-dark text-white"
                        onClick={() => setForm("complete")}
                      >
                        Set Completed
                      </button>
                    )}
                    {formStatus === 1 && (
                      <button
                        className="btn btn-warning text-white"
                        onClick={() => setForm("un-complete")}
                      >
                        Set Uncomplete
                      </button>
                    )}
                  </div>
                )}
                {isSecLoading ? (
                  <div style={{ textAlign: "center", height: "100px" }}>
                    <Spinner color="dark" /> Getting Section Fields...
                  </div>
                ) : (
                  sections.length > 0 && (
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <h5>Displaying Fields For {selectedSection}</h5>
                          {/* <button
                            className="btn btn-dark btn-small text-white"
                            onClick={() => toggleCustomField(!customField)}
                            // onClick={() => setFieldModal(!FieldModal)}
                          >
                            Create Field
                          </button> */}
                        </div>
                        {isSecFieldLoading ? (
                          <div
                            className="text-center"
                            style={{ height: "100px" }}
                          >
                            <Spinner color="dark" /> Getting Section Fields
                          </div>
                        ) : (
                          <div className="row mt-5">
                            {sectionFields.map((field, index) => (
                              <div class="col-6" key={index}>
                                <table class="table table-bordered">
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "50%" }}>
                                        <strong>{field.field_name}</strong>
                                        <br />
                                        {field.type}
                                      </td>
                                      <td style={{ width: "50%" }}>
                                        <div style={{ textAlign: "right" }}>
                                          {/* <a
                                            type="button"
                                            class="btn-sm"
                                            data-bs-toggle="modal"
                                            data-bs-target=".bd-example-modal-add"
                                            data-bs-original-title=""
                                            title=""
                                          >
                                            <i class="fa fa-edit"></i>
                                          </a> */}
                                        </div>
                                        <div>
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="right"
                                            title=""
                                            data-bs-original-title="Mandatory Field"
                                          >
                                            <i class="fa fa-lock"></i>{" "}
                                            {field.is_mandatory === 1 ? (
                                              "Manadatory"
                                            ) : (
                                              <strike>Mandatory</strike>
                                            )}
                                          </span>
                                        </div>
                                        {/* <div>
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              title=""
                                              data-bs-original-title="Show this field in quick create view"
                                            >
                                              <i class="fa fa-plus"></i>{" "}
                                              {field.is_quick_create === 1 ? (
                                                "Quick Create"
                                              ) : (
                                                <strike>Quick Create</strike>
                                              )}
                                            </span>
                                          </div> */}
                                        {/* <div>
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              title=""
                                              data-bs-original-title="Show this field in key field view"
                                            >
                                              <i class="fa fa-key"></i>{" "}
                                              {field.is_key_field === 1 ? (
                                                "Key Field"
                                              ) : (
                                                <strike> Key Field</strike>
                                              )}
                                            </span>
                                          </div> */}
                                        {/* <div>
                                            <span
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="right"
                                              title=""
                                              data-bs-original-title="Show this field in table list view"
                                            >
                                              <i class="fa fa-table"></i>{" "}
                                              {field.is_table_view === 1 ? (
                                                "Table List View"
                                              ) : (
                                                <strike>
                                                  {" "}
                                                  Table List View
                                                </strike>
                                              )}
                                            </span>
                                          </div> */}
                                        {/* {field.default_value == null ? (
                                            <div style={{ color: "lightgray" }}>
                                              <i class="fa fa-ban"></i> Default
                                              Value not set
                                            </div>
                                          ) : (
                                            <div>
                                              <i class="fas fa-check"></i>{" "}
                                              Default Value is set
                                            </div>
                                          )} */}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="col-2">
                {sections.length > 0 && (
                  <div
                    className="d-flex justify-content-between"
                    style={{ alignItems: "baseline" }}
                  >
                    <h5>Sections</h5>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setOrderModal(!ordermodal)}
                    >
                      <i
                        class="fas fa-align-justify"
                        data-toggle="tooltip"
                        title="Set Section Orders"
                      ></i>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setAddModal(!addmodal)}
                    >
                      <i
                        class="fas fa-plus-circle"
                        data-toggle="tooltip"
                        title="Add New Section"
                      ></i>
                    </div>
                  </div>
                )}
                {sections.map((section, index) => (
                  <button
                    className="btn btn-light btn-block mb-2"
                    key={index}
                    style={{ width: "100%" }}
                    onClick={() => {
                      getSectionFields(section.id);
                      setSelectedSection(section.name);
                      setSectionId(section.id);
                    }}
                  >
                    {section.name}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        isOpen={FieldModal}
        toggle={() => setFieldModal(!FieldModal)}
        size="lg"
      >
        <ModalHeader toggle={() => setFieldModal(!FieldModal)}>
          Create Form Fields
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {universalFields.map((field, index) => (
                <div
                  className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mt-1 "
                  style={{ cursor: "pointer" }}
                  key={index}
                >
                  <div class="card text-center card-hover-box">
                    <img
                      class="card-img-top"
                      src={field.image}
                      alt={field.db_field_name}
                    />
                    <div class="card-body">{field.field_name}</div>
                  </div>
                </div>
              ))}
              <div
                className="col-lg-3 col-xl-3 col-md-4 col-sm-6 mt-1 "
                style={{ cursor: "pointer" }}
              >
                <div
                  class="card text-center card-hover-box"
                  onClick={() => {
                    setFieldModal(!FieldModal);
                    toggleCustomField(!customField);
                  }}
                >
                  <img
                    src={
                      "https://img.icons8.com/ios-filled/20/fa314a/menu-2.png"
                    }
                    alt={"Others"}
                  />
                  <div class="card-body">Others</div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={addmodal} toggle={() => setAddModal(!addmodal)}>
        <ModalHeader toggle={() => setAddModal(!addmodal)}>
          Create New Section
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-4 my-auto">
              <label>Section Name</label>
            </div>
            <div className="col-8 ">
              <Input className="form-control" id="newSection" />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={() => AddNewSection()}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => setAddModal(!addmodal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={ordermodal} toggle={() => setOrderModal(!ordermodal)}>
        <ModalHeader toggle={() => setOrderModal(!ordermodal)}>
          Change Section Order
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Section</Label>
              <Input
                type="select"
                onChange={(e) => {
                  setOrderSectionId(e.target.value);
                }}
              >
                <option vlaue={null}>Select Section</option>
                {sections.map((section, index) => (
                  <option key={index} value={section.id}>
                    {section.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Order</Label>
              <Input type="select" onChange={(e) => setOrder(e.target.value)}>
                <option vlaue={null}>Select Order</option>
                {sections.map((section, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="dark"
            onClick={() => {
              UpdateOrder();
            }}
          >
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => setOrderModal(!ordermodal)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/*Custom Field*/}
      <Modal
        isOpen={customField}
        toggle={() => toggleCustomField(!customField)}
        size="lg"
        backdrop="static"
      >
        <ModalHeader toggle={() => toggleCustomField(!customField)}>
          Custom Field
        </ModalHeader>
        <ModalBody>
          <div class="row">
            <div class="col-12">
              {universalValue == null && (
                <>
                  <div class="mb-3 row">
                    <label class="col-sm-3 col-form-label my-auto">
                      Select Field Type
                    </label>
                    <div class="col-sm-9">
                      <select
                        class="form-control"
                        id="validationCustom04"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleFieldType}
                      >
                        {allFields.map((field) => (
                          <option value={JSON.stringify(field)}>
                            {field.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
              {sectionType === 1 && (
                <TextFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 13 && (
                <MultiComboFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}

              {sectionType === 3 && (
                <DecimalFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {(sectionType === 4 || sectionType === 20) && (
                <IntegerFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 5 && (
                <PercentFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 7 && (
                <DateFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 8 && (
                <EmailFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 10 && (
                <UrlFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 11 && (
                <CheckboxFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 12 && (
                <TextAreaFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 14 && (
                <TimeFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  universalValue={universalValue}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 6 && (
                <MultiCurrencyFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 9 && (
                <PhoneFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {(sectionType === 15 ||
                sectionType === 18 ||
                sectionType === 19) && (
                <UploadFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 2 && (
                <PicklistFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
              {sectionType === 16 && (
                <RadioFields
                  type_id={fieldId}
                  section_id={sectionId}
                  closeModal={toggleCustomField}
                  customField={customField}
                  // moduleId={this.props.match.params.id}
                  // history={this.props.history}
                  universalValue={universalValue}
                  refreshSection={getSectionFields}
                />
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
export default FormDetails;
