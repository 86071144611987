import logo from "../../images/black-logo.png";
// const DynamicComponentWithNoSSR = dynamic(
//   () => import("../components/Qrcode/qrCode"),
//   { ssr: false }
// );
import { useParams } from "react-router-dom";
import QrCode from "./QRCode";
function GenerateQr() {
  const { id, name } = useParams();
  // useEffect(() => {
  //   const fileReader = new FileReader();
  //   const fileInput = document.getElementById("file");

  //   // Sadly png.js doesn't return an array of pixel data, so we have to construct one by iterating over and calling getPixel
  //   function convertPNGtoByteArray(pngData) {
  //     const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
  //     for (let y = 0; y < pngData.height; y++) {
  //       for (let x = 0; x < pngData.width; x++) {
  //         const pixelData = pngData.getPixel(x, y);

  //         data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
  //         data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
  //         data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
  //         data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
  //       }
  //     }
  //     return data;
  //   }

  //   fileReader.onload = function (event) {
  //     const pngReader = new png(event.target.result);
  //     pngReader.parse(function (err, pngData) {
  //       if (err) throw err;
  //       const pixelArray = convertPNGtoByteArray(pngData);
  //       console.log(jsQR(pixelArray, pngData.width, pngData.height));
  //     });
  //   };

  //   fileInput.onchange = function () {
  //     fileReader.readAsArrayBuffer(fileInput.files[0]);
  //   };
  // }, []);
  return (
    <>
      {/* <NavbarThree /> */}

      <div style={{ marginTop: "4rem" }}>
        <div className="container">
          <div className="text-center">
            <h3 className="color-default">
              <img src={logo} style={{ height: "120px" }} />{" "}
            </h3>

            <div className="row">
              <div className="col-lg-6 col-md-10 col-sm-12 m-auto text-center">
                <h4>{name}</h4>
                <QrCode
                  // id={`https://nopen.us/form/${name}/${id}`}
                  id={id}
                  name={name}
                />
              </div>
              {/* <input onChange={(e) => scanCode(e)} type="file" id="file" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default GenerateQr;
