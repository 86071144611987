import moment from "moment";
import { Link } from "react-router-dom";
function SubscriptionTable({ total, records }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered">
        <thead>
          <th>Name</th>
          <th>Price</th>
          <th>Active</th>
          <th>Created At</th>
          <th>Last Updated</th>
          <th></th>
        </thead>
        <tbody>
          {records &&
            records.map((record, index) => (
              <tr key={index}>
                <td>{record.name}</td>
                <td>{record.price}</td>
                <td>
                  {record.is_active === 1 ? (
                    <span>
                      <i class="fas fa-check text-success"></i>
                    </span>
                  ) : (
                    <span>
                      <i class="fas fa-times text-danger"></i>
                    </span>
                  )}
                </td>
                <td>{moment(record.created_at).format("DD-MM-YYYY")}</td>
                <td>
                  {record.updated_at &&
                    moment(record.updated_at).format("DD-MM-YYYY")}
                </td>
                <td>
                  <Link to={`/admin/subscription/${record.id}`}>View</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default SubscriptionTable;
