import Navbar from "./../Layout/Navbar";
import Footer from "./../Layout/Footer";
import Banner from "./Banner";
import FeaturedServices from "./FeaturedServices";
import OurFeaturesTab2 from "./OtherFeaturesTab2";
import OurFeaturesTab from "./OtherFeatures";
import Testimonials from "./Testimonials";
import Pricing from "./Pricing";
function Landing() {
  return (
    <>
      <Navbar />
      <Banner />
      <FeaturedServices />

      <OurFeaturesTab2 />
      <OurFeaturesTab />

      <Testimonials />
      <Pricing />

      <Footer />
    </>
  );
}
export default Landing;
