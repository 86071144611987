import React, { Component } from "react";

import axios from "axios";
import UserNavbar from "./../Layout/UserNavbar";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import { Spinner } from "reactstrap";
class UserInformation extends Component {
  state = {
    show: 1,
    countries: [],
    employmentDetails: [{}],
    personal_details: {},
    health_details: {},
    insuranceDetails: [{}],
    educationDetails: [{}],
    first_name: "",
    last_name: "",
    loading: true,
  };

  componentDidMount = async () => {
    this.checkProfileUpdate();
    var token = localStorage.getItem("token");
    var resp = await axios.get(Endpoint + "/get/countries-list", {
      headers: { Authorization: token },
    });
    this.setState({ countries: resp.data.data.records });

    this.getData();
  };

  checkProfileUpdate = async () => {
    try {
      var token = localStorage.getItem("token");
      var response = await axios.post(
        `${Endpoint}/set/profile-check`,
        {},
        { headers: { Authorization: token } }
      );
      var { data } = response.data;
      if (parseInt(data.is_profile_set) === 1) {
        this.props.history.push("/user-landing");
      }
    } catch (e) {
      firetoast(
        "Something went wrong while getting profile status",
        "error",
        3000,
        "top-right"
      );
    }
  };
  render() {
    const {
      personal_details,
      health_details,
      insuranceDetails,
      educationDetails,
      employmentDetails,
    } = this.state;
    return (
      <>
        <UserNavbar />
        {this.state.loading ? (
          <div
            className="text-center"
            style={{ height: "100px", marginTop: "8rem" }}
          >
            <Spinner color="dark" /> Getting Ready...{" "}
          </div>
        ) : (
          <div style={{ marginTop: "8rem" }}>
            <div className="container">
              <div
                class="accordion"
                id="accordionExample"
                style={{ border: "none" }}
              >
                <div className="mb-2">
                  {this.state.show === 1 ? (
                    <h4>Personal Details</h4>
                  ) : (
                    <div
                      class="card-header"
                      id="headingOne"
                      style={{
                        background: "#EEEEFF",
                        borderBottom: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h6 class="mb-0" onClick={() => this.toggle(1)}>
                        Personal Details
                      </h6>
                    </div>
                  )}

                  <div
                    id="collapseOne"
                    class={this.state.show === 1 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div>
                      <form onSubmit={this.submitPersonalDetails}>
                        <div className="row">
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="your-email"
                                value={personal_details.first_name}
                                required
                                name="first_name"
                                onChange={this.handleChange}
                                placeholder="Enter your first name here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="your-email"
                                required
                                value={personal_details.last_name}
                                name="last_name"
                                onChange={this.handleChange}
                                placeholder="Enter your last name here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>DOB</label>
                              <input
                                type="date"
                                className="form-control"
                                id="your-email"
                                required
                                name="dob"
                                value={
                                  personal_details["dob"]
                                    ? personal_details["dob"]
                                    : null
                                }
                                onChange={this.handleChange}
                                placeholder="Enter Your Date Of Birth"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Sex/Gender</label>
                              <select
                                className="form-control"
                                required
                                name="gender"
                                onChange={this.handleChange}
                                placeholder="Select your gender"
                              >
                                <option value={null}>Select your gender</option>
                                <option
                                  value="male"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "male"
                                  }
                                >
                                  Male
                                </option>
                                <option
                                  value="female"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "female"
                                  }
                                >
                                  Female
                                </option>
                                <option
                                  value="other"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "other"
                                  }
                                >
                                  Other
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>Country</label>
                              <select
                                type="text"
                                className="form-control"
                                required
                                name="country"
                                onChange={this.handleChange}
                                placeholder="Enter country here"
                              >
                                <option>Select Country</option>
                                {this.state.countries.map((country, index) => (
                                  <option
                                    value={country.name}
                                    selected={
                                      personal_details.country &&
                                      personal_details.country === country.name
                                    }
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>Marital Status</label>
                              <select
                                type="text"
                                className="form-control"
                                required
                                name="marital_status"
                                onChange={this.handleChange}
                              >
                                <option>Select Marital Status</option>
                                <option
                                  value={"single"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status === "single"
                                  }
                                >
                                  Single
                                </option>
                                <option
                                  value={"married"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "married"
                                  }
                                >
                                  Married
                                </option>
                                <option
                                  value={"widowed"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "widowed"
                                  }
                                >
                                  Widowed
                                </option>
                                <option value={"divorced"}>
                                  {" "}
                                  selected=
                                  {personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "divorced"}
                                  Divorced
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Social ID/ID Card</label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                name="social_id"
                                value={
                                  personal_details["social_id"]
                                    ? personal_details["social_id"]
                                    : null
                                }
                                onChange={this.handleChange}
                                placeholder="Enter your ID here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Mobile/Phone</label>
                              <input
                                type="tel"
                                className="form-control"
                                name="phone"
                                onChange={this.handleChange}
                                value={
                                  personal_details["phone"]
                                    ? personal_details["phone"]
                                    : null
                                }
                                pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                required
                                placeholder="Enter your phone number here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={
                                  personal_details["city"]
                                    ? personal_details["city"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter place of your residence"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                type="text"
                                className="form-control"
                                // id="your-email"
                                name="state"
                                onChange={this.handleChange}
                                value={
                                  personal_details["state"]
                                    ? personal_details["state"]
                                    : null
                                }
                                required
                                placeholder="Enter place of your residence"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Postal/Zip Code</label>
                              <input
                                type="number"
                                className="form-control"
                                name="postal_code"
                                value={
                                  personal_details["postal_code"]
                                    ? personal_details["postal_code"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter postal/zip code here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={
                                  personal_details["address"]
                                    ? personal_details["address"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter your address here"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => this.setState({ show: 2 })}
                          >
                            Skip
                          </button>
                          {this.state.actualData.personal_details &&
                          Object.keys(this.state.actualData.personal_details)
                            .length > 0 ? (
                            <button
                              className="btn btn-dark"
                              type="button"
                              onClick={() => this.updatePersonalDetails()}
                            >
                              Update
                            </button>
                          ) : (
                            <button className="btn btn-dark" type="submit">
                              Save
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  {this.state.show === 2 ? (
                    <h4>Health Details</h4>
                  ) : (
                    <div
                      class="card-header"
                      id="headingOne"
                      style={{
                        background: "#EEEEFF",
                        borderBottom: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h6 class="mb-0" onClick={() => this.toggle(2)}>
                        Health & Insurance Details
                      </h6>
                    </div>
                  )}

                  <div
                    id="collapseOne"
                    class={this.state.show === 2 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <form>
                      <div className="row">
                        <div className="col-6 mb-1">
                          <div className="form-group">
                            <label>Blood Group</label>
                            <select
                              className="form-control"
                              required
                              name="blood_group"
                              onChange={this.handleHealthDetails}
                            >
                              <option value={null}>
                                Select Your Blood Group
                              </option>
                              <option
                                value="A+"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "A+"
                                }
                              >
                                A+
                              </option>
                              <option
                                value="A-"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "A-"
                                }
                              >
                                A-
                              </option>
                              <option
                                value="B+"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "B+"
                                }
                              >
                                B+
                              </option>
                              <option
                                value="B-"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "B-"
                                }
                              >
                                B-
                              </option>
                              <option
                                value="AB+"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "AB+"
                                }
                              >
                                AB+
                              </option>
                              <option
                                value="AB-"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "AB-"
                                }
                              >
                                AB-
                              </option>
                              <option
                                value="O+"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "O+"
                                }
                              >
                                O+
                              </option>
                              <option
                                value="O-"
                                selected={
                                  health_details["blood_group"] &&
                                  health_details["blood_group"] === "O-"
                                }
                              >
                                O-
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6  mb-1">
                          <div className="form-group">
                            <label>Family Doctor Name(If Any)</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={
                                health_details["family_doc"] &&
                                health_details["family_doc"]
                              }
                              name="family_doc"
                              onChange={this.handleHealthDetails}
                              placeholder="Enter Doctor Name"
                            />
                          </div>
                        </div>
                        <div className="col-6  mb-1">
                          <div className="form-group">
                            <label>Emergency Contact</label>
                            <input
                              type="tel"
                              className="form-control"
                              name="emergency_contact"
                              value={
                                health_details["emergency_contact"] &&
                                health_details["emergency_contact"]
                              }
                              onChange={this.handleHealthDetails}
                              pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                              required
                              placeholder="Enter your emergency contact here"
                            />
                          </div>
                        </div>

                        <div className="col-6  mb-1">
                          <div className="form-group">
                            <label>Emergency Contact Relation</label>
                            <input
                              type="text"
                              className="form-control"
                              name="relation_to_ec"
                              value={
                                health_details["relation_to_ec"] &&
                                health_details["relation_to_ec"]
                              }
                              onChange={this.handleHealthDetails}
                              required
                              placeholder="Enter your relation with emergency contact"
                            />
                          </div>
                        </div>
                        <div className="col-6  mb-1">
                          <div className="form-group">
                            <label>Presently Taking Medicine(If Any)</label>
                            <textarea
                              className="form-control"
                              name="p_t_medicine"
                              value={
                                health_details["p_t_medicine"] &&
                                health_details["p_t_medicine"]
                              }
                              onChange={this.handleHealthDetails}
                              required
                              placeholder="Enter your medications here"
                            />
                          </div>
                        </div>
                        <div className="col-6  mb-1">
                          <div className="form-group">
                            <label>Any Allergies(If Any)</label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="allergies"
                              value={
                                health_details["allergies"] &&
                                health_details["allergies"]
                              }
                              onChange={this.handleHealthDetails}
                              required
                              placeholder="Enter your allergies here"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mb-2">
                  {this.state.show === 2 ? <h4>Insurance Details</h4> : null}

                  <div
                    id="collapseOne"
                    class={this.state.show === 2 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    {this.state.insuranceDetails.map((insurance, index) => (
                      <div key={index}>
                        <div className="d-flex justify-content-between">
                          <h2 className="mb-2">{index + 1}</h2>
                          <div className="text-right">
                            {this.state.actualData.insurance_details.length <
                              1 && (
                              <>
                                {index !== 0 ? (
                                  <img
                                    src="https://img.icons8.com/office/16/000000/delete-sign.png"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      var insuranceDetails = [
                                        ...this.state.insuranceDetails,
                                      ];
                                      insuranceDetails.splice(1, index + 1);
                                      this.setState({ insuranceDetails });
                                    }}
                                  />
                                ) : (
                                  <button
                                    className="btn btn-sm btn-dark"
                                    onClick={() => {
                                      var insuranceDetails = [
                                        ...this.state.insuranceDetails,
                                      ];
                                      insuranceDetails[index + 1] = {};
                                      this.setState({ insuranceDetails });
                                    }}
                                  >
                                    Add More
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-6 mb-1">
                              <div className="form-group">
                                <label>Insurance Holder Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="insurance_holder_name"
                                  value={
                                    insuranceDetails[index]
                                      .insurance_holder_name &&
                                    insuranceDetails[index]
                                      .insurance_holder_name
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  placeholder="Enter name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>
                                  Insurance Holder Social Security Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  value={
                                    insuranceDetails[index].i_h_security_no &&
                                    insuranceDetails[index].i_h_security_no
                                  }
                                  name="i_h_security_no"
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  placeholder="Enter your social security# here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Insurance Holder's Contact</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="i_h_contact_no"
                                  value={
                                    insuranceDetails[index].i_h_contact_no &&
                                    insuranceDetails[index].i_h_contact_no
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                  required
                                  placeholder="Enter your emergency contact here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Insurance Company</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="company_name"
                                  value={
                                    insuranceDetails[index].company_name &&
                                    insuranceDetails[index].company_name
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  placeholder="Enter company name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="phone_no"
                                  value={
                                    insuranceDetails[index].phone_no &&
                                    insuranceDetails[index].phone_no
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                  required
                                  placeholder="Enter your phone no. here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Insurance ID</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="insurance_id"
                                  value={parseInt(
                                    insuranceDetails[index].insurance_id &&
                                      insuranceDetails[index].insurance_id
                                  )}
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  required
                                  placeholder="Enter insurance id here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Plan Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="plan_name"
                                  value={
                                    insuranceDetails[index].plan_name &&
                                    insuranceDetails[index].plan_name
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  required
                                  placeholder="Enter plane name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Group No.</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="group_no"
                                  value={
                                    insuranceDetails[index].group_no &&
                                    insuranceDetails[index].group_no
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  required
                                  placeholder="Enter group# here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Effective Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="effective_date"
                                  value={
                                    insuranceDetails[index].effective_date &&
                                    insuranceDetails[index].effective_date
                                  }
                                  onChange={(e) =>
                                    this.handleInsuranceDetails(e, index)
                                  }
                                  required
                                  placeholder="Select effective date of insurance"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    ))}
                    <div className="d-flex justify-content-between mb-2">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() =>
                          this.setState({ show: this.state.show + 1 })
                        }
                      >
                        Skip
                      </button>
                      {this.state.actualData.health_details &&
                      Object.keys(this.state.actualData.health_details).length <
                        1 &&
                      this.state.actualData.insurance_details.length < 1 ? (
                        <button
                          className="btn btn-dark"
                          onClick={this.submitHealthAndInsuranceDetails}
                        >
                          Save
                        </button>
                      ) : (
                        <>
                          <button
                            className="btn btn-dark"
                            type="button"
                            onClick={() =>
                              this.updateHealthAndInsuranceDetails()
                            }
                          >
                            Update
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  {this.state.show === 3 ? (
                    <h4>Education Details</h4>
                  ) : (
                    <div
                      class="card-header"
                      id="headingOne"
                      style={{
                        background: "#EEEEFF",
                        borderBottom: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h6 class="mb-0" onClick={() => this.toggle(3)}>
                        Education Details
                      </h6>
                    </div>
                  )}

                  <div
                    id="collapseOne"
                    class={this.state.show === 3 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    {this.state.educationDetails.map((education, index) => (
                      <>
                        <div className="d-flex justify-content-between">
                          <h2 className="mb-2">{index + 1}</h2>
                          <div className="text-right">
                            {this.state.actualData.education_details.length <
                              1 && (
                              <>
                                {index !== 0 ? (
                                  <img
                                    src="https://img.icons8.com/office/16/000000/delete-sign.png"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      var educationDetails = [
                                        ...this.state.educationDetails,
                                      ];
                                      educationDetails.splice(1, index + 1);
                                      this.setState({ educationDetails });
                                    }}
                                  />
                                ) : (
                                  <button
                                    className="btn btn-sm btn-dark"
                                    onClick={() => {
                                      var educationDetails = [
                                        ...this.state.educationDetails,
                                      ];
                                      educationDetails[index + 1] = {};
                                      this.setState({ educationDetails });
                                    }}
                                  >
                                    Add More
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <form>
                          <div className="row">
                            <div className="col-6 mb-1">
                              <div className="form-group">
                                <label>Education/Degree Level</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="degree_level"
                                  value={
                                    educationDetails[index]["degree_level"] &&
                                    educationDetails[index]["degree_level"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  placeholder="Enter your degree level"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Name of institute</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="institute_name"
                                  value={
                                    educationDetails[index]["institute_name"] &&
                                    educationDetails[index]["educationDetails"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  placeholder="Enter institute name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Institute Location</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="your-email"
                                  required
                                  name="city"
                                  value={
                                    educationDetails[index]["city"] &&
                                    educationDetails[index]["city"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  placeholder="Enter institute location here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="your-email"
                                  required
                                  name="state"
                                  value={
                                    educationDetails[index]["state"] &&
                                    educationDetails[index]["state"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  placeholder="Enter institute location here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Session/Completeion Year</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="session"
                                  value={
                                    educationDetails[index]["session"] &&
                                    educationDetails[index]["session"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  placeholder="Enter session/completion year here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Enrollment ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="enrollment_id"
                                  value={
                                    educationDetails[index]["enrollment_id"] &&
                                    educationDetails[index]["enrollment_id"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  required
                                  placeholder="Enter enrollment id here"
                                />
                              </div>
                            </div>
                            <div className="col-6 mb-1">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  type="text"
                                  className="form-control"
                                  required
                                  name="country"
                                  onChange={(e) => {
                                    this.handleEducationDetails(e, index);
                                  }}
                                  placeholder="Enter country here"
                                >
                                  <option>Select Country</option>
                                  {this.state.countries.map((country, ind) => (
                                    <option
                                      value={country.name}
                                      selected={
                                        educationDetails[index]["country"] &&
                                        educationDetails[index]["country"] ===
                                          country.name
                                      }
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Grades/CGPA</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="grades"
                                  value={
                                    educationDetails[index]["grades"] &&
                                    educationDetails[index]["grades"]
                                  }
                                  onChange={(e) =>
                                    this.handleEducationDetails(e, index)
                                  }
                                  required
                                  placeholder="Enter your grades/cgpa here"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                    ))}
                    <div className="d-flex justify-content-between mb-2">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() =>
                          this.setState({ show: this.state.show + 1 })
                        }
                      >
                        Skip
                      </button>
                      {this.state.actualData.education_details.length == 0 ? (
                        <button
                          className="btn btn-dark"
                          onClick={this.submitEducationDetails}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark"
                          type="button"
                          onClick={() => this.updateEducationalDetails()}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  {this.state.show === 4 ? (
                    <h4>Employment Details</h4>
                  ) : (
                    <div
                      class="card-header"
                      id="headingOne"
                      style={{
                        background: "#EEEEFF",
                        borderBottom: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h6 class="mb-0" onClick={() => this.toggle(4)}>
                        Employment Details
                      </h6>
                    </div>
                  )}

                  <div
                    id="collapseOne"
                    class={this.state.show === 4 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    {this.state.employmentDetails.map((education, index) => (
                      <>
                        <div
                          className="d-flex justify-content-between"
                          style={{ alignItems: "baseline" }}
                        >
                          <h2 className="mb-2">{index + 1}</h2>
                          {this.state.actualData.work_details.length === 0 && (
                            <>
                              {index !== 0 ? (
                                <img
                                  src="https://img.icons8.com/office/16/000000/delete-sign.png"
                                  onClick={() => {
                                    var employmentDetails = [
                                      ...this.state.employmentDetails,
                                    ];
                                    employmentDetails.splice(1, index + 1);
                                    this.setState({ employmentDetails });
                                  }}
                                />
                              ) : (
                                <div className="text-right">
                                  <button
                                    className="btn btn-sm btn-dark"
                                    onClick={() => {
                                      var employmentDetails = [
                                        ...this.state.employmentDetails,
                                      ];
                                      employmentDetails[index + 1] = {};
                                      this.setState({ employmentDetails });
                                    }}
                                  >
                                    Add More
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <form>
                          <div className="row">
                            <div className="col-6 mb-1">
                              <div className="form-group">
                                <label>Position/Post</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="position"
                                  value={
                                    employmentDetails[index]["position"] &&
                                    employmentDetails[index]["position"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  placeholder="Enter your job position here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Name of Employer </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  required
                                  name="employer_name"
                                  value={
                                    employmentDetails[index]["employer_name"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  placeholder="Enter employer's name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Country</label>
                                <select
                                  type="text"
                                  className="form-control"
                                  required
                                  name="country"
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  placeholder="Enter country here"
                                >
                                  <option>Select Country</option>
                                  {this.state.countries.map((country, ind) => (
                                    <option
                                      value={country.name}
                                      selected={
                                        employmentDetails[index]["country"] &&
                                        employmentDetails[index]["country"] ===
                                          country.name
                                      }
                                    >
                                      {country.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Job's Location(City)</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="city"
                                  value={
                                    employmentDetails[index]["city"] &&
                                    employmentDetails[index]["city"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  placeholder="Enter job's location here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>State</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  required
                                  name="state"
                                  value={
                                    employmentDetails[index]["state"] &&
                                    employmentDetails[index]["state"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  placeholder="Enter state here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Supervisor's Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="your-email"
                                  name="supervisor"
                                  value={
                                    employmentDetails[index]["supervisor"] &&
                                    employmentDetails[index]["supervisor"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Enter supervisior's name here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Supervisor's Email</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={
                                    employmentDetails[index]["email"] &&
                                    employmentDetails[index]["email"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Enter supervisor's email here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Supervisor's Contact</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  name="contact_No"
                                  value={
                                    employmentDetails[index]["contact_No"] &&
                                    employmentDetails[index]["contact_No"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                  required
                                  placeholder="Enter supervisor's contact here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Salary(Dollars)</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="salary"
                                  value={
                                    employmentDetails[index]["salary"] &&
                                    employmentDetails[index]["salary"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Enter your salary here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Start Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={
                                    employmentDetails[index]["start_date"] &&
                                    employmentDetails[index]["start_date"]
                                  }
                                  name="start_date"
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Select Start Date"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>End Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="end_date"
                                  value={
                                    employmentDetails[index]["end_date"] &&
                                    employmentDetails[index]["end_date"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Select End Date"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Responsibilities</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="your-email"
                                  name="responsibilities"
                                  value={
                                    employmentDetails[index][
                                      "responsibilities"
                                    ] &&
                                    employmentDetails[index]["responsibilities"]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Enter responsibilities here"
                                />
                              </div>
                            </div>
                            <div className="col-6  mb-1">
                              <div className="form-group">
                                <label>Reason for Leaving</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="your-email"
                                  name="reason_of_leaving"
                                  value={
                                    employmentDetails[index][
                                      "reason_of_leaving"
                                    ] &&
                                    employmentDetails[index][
                                      "reason_of_leaving"
                                    ]
                                  }
                                  onChange={(e) => {
                                    this.handleEmploymentDetails(e, index);
                                  }}
                                  required
                                  placeholder="Enter reason for leaving here"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                    ))}
                    <div className="d-flex justify-content-between mb-2">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={() =>
                          this.setState({ show: this.state.show + 1 })
                        }
                      >
                        Skip
                      </button>
                      {this.state.actualData.work_details.length !== 0 ? (
                        <button
                          className="btn btn-dark"
                          type="button"
                          onClick={() => this.updateEmploymentDetails()}
                        >
                          {" "}
                          Update
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark"
                          onClick={this.submitEmployeementDetails}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  toggle = (val) => {
    this.setState({ show: val });
  };
  handleChange = ({ currentTarget: input }) => {
    var personal_details = { ...this.state.personal_details };
    personal_details[input.name] = input.value;
    this.setState({ personal_details });
  };
  handleHealthDetails = ({ currentTarget: input }) => {
    var health_details = { ...this.state.health_details };
    health_details[input.name] = input.value;
    this.setState({ health_details });
  };
  handleInsuranceDetails = (e, index) => {
    var insuranceDetails = [...this.state.insuranceDetails];
    insuranceDetails[index][e.target.name] = e.target.value;
    this.setState({ insuranceDetails });
  };
  handleEducationDetails = (e, index) => {
    var educationDetails = [...this.state.educationDetails];
    educationDetails[index][e.target.name] = e.target.value;
    this.setState({ educationDetails });
  };
  handleEmploymentDetails = (e, index) => {
    var employmentDetails = [...this.state.employmentDetails];
    employmentDetails[index][e.target.name] = e.target.value;
    this.setState({ employmentDetails });
  };
  submitPersonalDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = { ...this.state.personal_details };
    delete data["updated_at"];
    delete data["created_at"];
    for (var key in data) {
      form.append(key, data[key]);
    }
    try {
      var response = await axios.post(
        Endpoint + "/set/personal-details",
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Personal Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {}
  };
  submitHealthAndInsuranceDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = { ...this.state.health_details };
    delete data["updated_at"];
    delete data["created_at"];

    for (var key in data) {
      form.append(key, data[key]);
    }
    var data2 = this.state.insuranceDetails;
    var insuranceObj = {
      company_name: [],
      effective_date: [],
      group_no: [],
      i_h_contact_no: [],
      i_h_security_no: [],
      insurance_holder_name: [],
      insurance_id: [],
      phone_no: [],
      plan_name: [],
    };
    for (let i = 0; i < data2.length; i++) {
      let current = data2[i];
      console.log(current);
      for (var key in current) {
        insuranceObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(insuranceObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], insuranceObj[keys[i]]);
      if (insuranceObj[keys[i]].length !== 0) {
        console.log("appended");
        form.append(keys[i], JSON.stringify(insuranceObj[keys[i]]));
      }
    }

    try {
      var response = await axios.post(Endpoint + "/set/health-details", form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Health & Insurance Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {}
  };
  getData = async () => {
    this.setState({ loading: true });
    var token = localStorage.getItem("token");

    var resp_ = await axios.get(Endpoint + "/get/user-details", {
      headers: { Authorization: token },
    });
    var personal_details = resp_.data.data.personal_details || [];
    personal_details["first_name"] = resp_.data.data.user.first_name;
    personal_details["last_name"] = resp_.data.data.user.last_name;
    var health_details = resp_.data.data.health_details || [];
    var insuranceDetails =
      resp_.data.data.insurance_details.length > 0
        ? resp_.data.data.insurance_details
        : [{}];
    var educationDetails =
      resp_.data.data.education_details.length > 0
        ? resp_.data.data.education_details
        : [{}];
    var employmentDetails =
      resp_.data.data.work_details.length > 0
        ? resp_.data.data.work_details
        : [{}];
    if (personal_details["dob"]) {
      let date = new Date(personal_details["dob"]);
      let d =
        date.getFullYear() +
        "-" +
        `${
          parseInt(date.getMonth() + 1) > 10
            ? parseInt(date.getMonth() + 1)
            : "0" + parseInt(date.getMonth() + 1)
        }` +
        "-" +
        date.getDate();
      personal_details["dob"] = d;
    }

    this.setState({
      personal_details,
      health_details,
      insuranceDetails,
      educationDetails,
      employmentDetails,
      actualData: resp_.data.data,
      first_name: resp_.data.data.user.first_name,
      last_name: resp_.data.data.user.last_name,
      loading: false,
    });
  };
  submitEducationDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");
    var form = new FormData();
    var data = this.state.educationDetails;
    delete data["updated_at"];
    delete data["created_at"];

    var educationObj = {
      degree_level: [],
      institute_name: [],
      city: [],
      state: [],
      session: [],
      enrollment_id: [],
      country: [],
      grades: [],
    };

    for (let i = 0; i < data.length; i++) {
      let current = data[i];
      console.log(current);
      for (var key in current) {
        educationObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(educationObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], educationObj[keys[i]]);
      if (educationObj[keys[i]].length !== 0) {
        form.append(keys[i], JSON.stringify(educationObj[keys[i]]));
      }
    }
    // for (let i = 0; i < data.length; i++) {
    //   let current = data[i];
    //   console.log(current);
    //   for (var key in current) {
    //     form.append(`${key}[${i}]`, current[key]);
    //   }
    // }
    try {
      var response = await axios.post(
        Endpoint + "/set/education-details",
        form,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        firetoast(
          "Education Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {}
  };
  submitEmployeementDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = this.state.employmentDetails;
    delete data["updated_at"];
    delete data["created_at"];
    // for (let i = 0; i < data.length; i++) {
    //   let current = data[i];
    //   console.log(current);
    //   for (var key in current) {
    //     form.append(`${key}[${i}]`, current[key]);
    //   }
    // }
    var employmentObj = {
      employer_name: [],
      position: [],
      country: [],
      state: [],
      city: [],
      supervisor: [],
      contact_No: [],
      email: [],
      start_date: [],
      end_date: [],
      salary: [],
      reason_of_leaving: [],
      responsibilities: [],
    };
    for (let i = 0; i < data.length; i++) {
      let current = data[i];
      console.log(current);
      for (var key in current) {
        employmentObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(employmentObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], employmentObj[keys[i]]);
      if (employmentObj[keys[i]].length !== 0) {
        form.append(keys[i], JSON.stringify(employmentObj[keys[i]]));
      }
    }
    try {
      var response = await axios.post(Endpoint + "/set/work-details", form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Employement Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        // this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {
      firetoast(
        "Something went wrong while adding employment details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updatePersonalDetails = async () => {
    try {
      var { personal_details } = this.state;
      delete personal_details["updated_at"];
      delete personal_details["id"];
      delete personal_details["created_at"];
      var token = localStorage.getItem("token");
      let form = new URLSearchParams();
      for (let key in personal_details) {
        form.append(key, personal_details[key]);
      }
      var response = await axios.put(
        `${Endpoint}/update/personal-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast("Personal details updated!", "success", 3000, "top-right");
      }
    } catch (e) {
      firetoast(
        "Something went wrong while updating personal details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateHealthAndInsuranceDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { insuranceDetails, health_details } = this.state;
      delete health_details["id"];
      delete health_details["created_at"];
      delete health_details["updated_at"];
      health_details["record_ids"] =
        this.state.actualData.personal_details["id"];
      for (let i = 0; i < insuranceDetails.length; i++) {
        // delete insuranceDetails[i].id;
        delete insuranceDetails[i].created_at;
        delete insuranceDetails[i].updated_at;
        delete insuranceDetails[i].user_id;
      }

      for (let key in health_details) {
        form.append(key, health_details[key]);
      }

      var insuranceObj = {
        record_ids: [],
        company_name: [],
        effective_date: [],
        group_no: [],
        i_h_contact_no: [],
        i_h_security_no: [],
        insurance_holder_name: [],
        insurance_id: [],
        phone_no: [],
        plan_name: [],
      };
      for (let i = 0; i < insuranceDetails.length; i++) {
        let current = insuranceDetails[i];
        for (var key in current) {
          if (key === "id") {
            insuranceObj.record_ids.push(current[key]);
          } else {
            insuranceObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(insuranceObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], insuranceObj[keys[i]]);
        if (insuranceObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(insuranceObj[keys[i]]));
        }
      }

      var response = await axios.put(
        `${Endpoint}/update/health-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Health and Insurance Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating insurance details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating insurance details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateEducationalDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { educationDetails } = this.state;

      for (let i = 0; i < educationDetails.length; i++) {
        // delete educationDetails[i].id;
        delete educationDetails[i].created_at;
        delete educationDetails[i].updated_at;
        delete educationDetails[i].user_id;
      }

      var educationObj = {
        degree_level: [],
        institute_name: [],
        city: [],
        state: [],
        session: [],
        enrollment_id: [],
        country: [],
        grades: [],
        record_ids: [],
      };
      for (let i = 0; i < educationDetails.length; i++) {
        let current = educationDetails[i];
        for (var key in current) {
          if (key === "id") {
            educationObj.record_ids.push(current[key]);
          } else {
            educationObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(educationObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], educationObj[keys[i]]);
        if (educationObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(educationObj[keys[i]]));
        }
      }

      var response = await axios.put(
        `${Endpoint}/update/education-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Education Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating education details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating education details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateEmploymentDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { employmentDetails } = this.state;

      for (let i = 0; i < employmentDetails.length; i++) {
        // delete employmentDetails[i].id;
        delete employmentDetails[i].created_at;
        delete employmentDetails[i].updated_at;
        delete employmentDetails[i].user_id;
      }

      var employmentObj = {
        employer_name: [],
        position: [],
        country: [],
        state: [],
        city: [],
        supervisor: [],
        contact_No: [],
        email: [],
        start_date: [],
        end_date: [],
        salary: [],
        reason_of_leaving: [],
        responsibilities: [],
        record_ids: [],
      };
      for (let i = 0; i < employmentDetails.length; i++) {
        let current = employmentDetails[i];
        for (var key in current) {
          if (key === "id") {
            employmentObj.record_ids.push(current[key]);
          } else {
            employmentObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(employmentObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], employmentObj[keys[i]]);
        if (employmentObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(employmentObj[keys[i]]));
        }
      }

      var response = await axios.put(`${Endpoint}/update/work-details`, form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Employment Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating employment details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating employment details",
        "error",
        3000,
        "top-right"
      );
    }
  };
}

export default UserInformation;
