import { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import png from "png.js";
import jsQR from "jsqr";
import firetoast from "./../Utils/Firetoast";
import UserNavbar from "./../Layout/UserNavbar";
import AnimatedImages from "./../Helpers/AnimatedImages";
import QrReader from "react-qr-reader";
// const jpeg = require("jpeg-js");
// const fs = require("fs");
function Userlanding() {
  const [isLoading, setIsLoading] = useState(false);
  const qrRef = useRef(null);
  useEffect(() => {
    // if (window !== "undefined" || window !== undefined) {
    //   setIsLoading(false);
    //   const fileReader = new FileReader();
    //   const fileInput = document.getElementById("file");
    //   function convertPNGtoByteArray(pngData) {
    //     const data = new Uint8ClampedArray(pngData.width * pngData.height * 4);
    //     for (let y = 0; y < pngData.height; y++) {
    //       for (let x = 0; x < pngData.width; x++) {
    //         const pixelData = pngData.getPixel(x, y);
    //         data[(y * pngData.width + x) * 4 + 0] = pixelData[0];
    //         data[(y * pngData.width + x) * 4 + 1] = pixelData[1];
    //         data[(y * pngData.width + x) * 4 + 2] = pixelData[2];
    //         data[(y * pngData.width + x) * 4 + 3] = pixelData[3];
    //       }
    //     }
    //     return data;
    //   }
    //   fileReader.onload = function (event) {
    //     const pngReader = new png(event.target.result);
    //     try {
    //       pngReader.parse(function (err, pngData) {
    //         if (err) console.log(err);
    //         const pixelArray = convertPNGtoByteArray(pngData);
    //         var { data } = jsQR(pixelArray, pngData.width, pngData.height);
    //         let array = data.split("/");
    //         console.log(array[array.length - 1]);
    //         OpenForm(array[array.length - 1]);
    //       });
    //     } catch (e) {
    //       firetoast("Invalid Qr File!", "error", 3000, "top-right");
    //     }
    //   };
    //   fileInput.onchange = function () {
    //     fileReader.readAsArrayBuffer(fileInput.files[0]);
    //   };
    // }
  }, [typeof window]);
  var OpenForm = (data) => {
    setIsLoading(true);
    let array = data.split("/");

    setTimeout(() => {
      window.location.href = `https://nopen.us/created-form/${
        array[array.length - 1]
      }`;
    }, 2000);
  };
  const onScanFile = () => {
    qrRef.current.openImageDialog();
  };
  const handleScanFile = (result) => {
    if (result) {
      OpenForm(result);
    } else {
      firetoast("Invalid Qr!", "error", 3000, "top-right");
    }
  };
  const handleErrorFile = (error) => {
    console.log(error);
  };
  return (
    <>
      <UserNavbar />

      <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
        <div className="container">
          <div className="text-center">
            {isLoading ? (
              <div
                className="text-center"
                style={{ height: "100px", marginTop: "25px" }}
              >
                <Spinner color="warning" /> Loading....
              </div>
            ) : (
              <h3 className="color-default">
                Hi, {JSON.parse(localStorage.getItem("nopen-user")).first_name}
              </h3>
            )}
          </div>

          <div className="row mt-5">
            <div className="col-6 m-auto text-center">
              <h5>
                <label>
                  <b>Upload Qr Code To Generate Form</b>
                </label>
              </h5>

              <QrReader
                delay={300}
                ref={qrRef}
                onError={handleErrorFile}
                onScan={handleScanFile}
                legacyMode
                style={{ width: "100%", height: "50%" }}
              />
              <button onClick={onScanFile} className=" btn btn-dark mt-3">
                Scan Qr Code
              </button>
              {/* <input type="file" id="file" accept="image/png, image/jpeg" /> */}
            </div>
          </div>
          <AnimatedImages />
        </div>
      </div>
    </>
  );
}
export default Userlanding;

// let imagedata = fs.readFileSync(image)
// const jpgData = jpeg.decode(imagedata, { useTArray: true });
// const qrArray = new Uint8ClampedArray(jpgData.data.buffer);
// return JSON.parse(jsQR(qrArray, jpgData.width, jpgData.height).data);
