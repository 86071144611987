import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import man from "../../images/banner-image/man.png";
import code from "../../images/banner-image/code.png";
import carpet from "../../images/banner-image/carpet.png";
import bin from "../../images/banner-image/bin.png";
import book from "../../images/banner-image/book.png";
import desktop from "../../images/banner-image/desktop.png";
import dot from "../../images/banner-image/dot.png";
import flowerTopBig from "../../images/banner-image/flower-top-big.png";
import flowerTop from "../../images/banner-image/flower-top.png";
import keyboard from "../../images/banner-image/keyboard.png";
import pen from "../../images/banner-image/pen.png";
import table from "../../images/banner-image/table.png";
import teacup from "../../images/banner-image/tea-cup.png";
import headphone from "../../images/banner-image/headphone.png";
import mainpic from "../../images/banner-image/main-pic.png";
class Banner extends Component {
  render() {
    return (
      <div className="hero-banner banner-bg2">
        <div className="d-table" style={{ backgroundColor: "#000" }}>
          <div className="d-table-cell">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="hero-main-banner-content">
                    <span className="sub-title text-white">
                      Trendy Application
                    </span>
                    <h1 className="text-white">
                      Work Faster With Powerful Tools!
                    </h1>
                    <p className="text-white">
                      Laboris culpa ea incididunt dolore ipsum tempor duis do
                      ullamco eiusmod officia magna ad id.
                    </p>

                    {/* <form>
                      <input
                        type="email"
                        className="input-newsletter"
                        placeholder="Enter your email"
                        name="EMAIL"
                      />
                      <button type="submit">Get Started</button>
                    </form> */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="hero-main-banner-image">
                    <ScrollAnimation
                      animateIn="fadeInDown"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={man} alt="man" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={code} alt="code" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={carpet} alt="carpet" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="zoomIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={bin} alt="bin" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="bounceIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={book} alt="book" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInDown"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={desktop} alt="desktop" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="zoomIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={dot} alt="dot" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={flowerTopBig} alt="flower-top-big" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="rotateIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={flowerTop} alt="flower-top" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={keyboard} alt="keyboard" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="zoomIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={pen} alt="pen" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="zoomIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={table} alt="table" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={teacup} alt="tea-cup" />
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn="rollIn"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={headphone} alt="headphone" />
                    </ScrollAnimation>

                    {/* Main Images */}
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      delay={50}
                      animateOnce={true}
                    >
                      <img src={mainpic} alt="main-pic" />
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Shape Images */}
        {/* <div className="shape-img1">
          <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce={true}>
            <img src={require("../../images/shape/shape1.png")} alt="image" />
          </ScrollAnimation>
        </div>
        <div className="shape-img2">
          <img src={require("../../images/shape/shape2.svg")} alt="image" />
        </div>
        <div className="shape-img3">
          <img src={require("../../images/shape/shape3.svg")} alt="image" />
        </div>
        <div className="shape-img4">
          <img src={require("../../images/shape/shape4.png")} alt="image" />
        </div>
        <div className="shape-img5">
          <img src={require("../../images/shape/shape5.png")} alt="image" />
        </div>
        <div className="shape-img6">
          <img src={require("../../images/shape/shape6.png")} alt="image" />
        </div>
        <div className="shape-img7">
          <img src={require("../../images/shape/shape7.png")} alt="image" />
        </div>
        <div className="shape-img8">
          <img src={require("../../images/shape/shape8.png")} alt="image" />
        </div>
        <div className="shape-img9">
          <img src={require("../../images/shape/shape9.png")} alt="image" />
        </div>
        <div className="shape-img10">
          <img src={require("../../images/shape/shape10.png")} alt="image" />
        </div> */}
      </div>
    );
  }
}

export default Banner;
