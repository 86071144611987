import AdminNavbar from "./../../Layout/AdminNavbar";
import MenuItems from "./../MenuItems";
import { useState, useEffect } from "react";
import SubscriptionTable from "./SubscriptionTable";
import { Endpoint } from "./../../Utils/Endpoint";
import axios from "axios";
import firetoast from "./../../Utils/Firetoast";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
} from "reactstrap";
function AdminSubscription() {
  const [IsLoading, setIsLoading] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [Total, setTotal] = useState(0);
  const [Open, setOpen] = useState(false);
  const [Name, setName] = useState(null);
  const [Price, setPrice] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    getData();
  }, []);
  let getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${Endpoint}/admin/subscriptions`, {
        headers: { Authorization: token },
      });
      setSubscriptionList(response.data.data.data);
      setTotal(response.data.data.total);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("Error while fetching subscriptions", e);
    }
  };
  let CreateSub = async () => {
    try {
      if (!Name || Name === "") {
        return firetoast(
          "Provide Subscription Name",
          "error",
          3000,
          "top-center"
        );
      }
      if (!Price || Price === "") {
        return firetoast(
          "Provide Subscription Price",
          "error",
          3000,
          "top-center"
        );
      }
      const response = await axios.post(
        `${Endpoint}/admin/subscriptions`,
        {
          name: Name,
          price: Price,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.data.success === 200 || response.status === 200) {
        firetoast("Subscription Created", "dark", 3000, "top-center");
        getData();
        setName(null);
        setPrice(null);
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      }
    } catch (e) {
      console.log("Error while creating sub!", e);
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        {IsLoading ? (
          <div className="mt-5 text-center">
            <Spinner color="dark" /> Getting Data...
          </div>
        ) : (
          <>
            <div className="row mt-2">
              <div className="col-6">
                <h4 className="text-dark"> Subscriptions </h4>
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div>Total Records : {Total}</div>
                  <button
                    className="btn btn-warning"
                    onClick={() => setOpen(!Open)}
                  >
                    Create New
                  </button>
                </div>
                <div className="mt-3">
                  <SubscriptionTable total={Total} records={SubscriptionList} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal isOpen={Open} toggle={() => setOpen(!Open)}>
        <ModalHeader toggle={() => setOpen(!Open)}>
          New Subscription
        </ModalHeader>
        <ModalBody>
          <div className="row" style={{ alignItems: "baseline" }}>
            <div className="col-4">Name</div>
            <div className="col-8">
              <input
                className="form-control"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-2" style={{ alignItems: "baseline" }}>
            <div className="col-4">Price</div>
            <div className="col-8">
              <input
                className="form-control"
                type="number"
                min={1}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={() => CreateSub()}>
            Add
          </Button>{" "}
          <Button color="secondary" toggle={() => setOpen(!Open)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Footer /> */}
    </>
  );
}
export default AdminSubscription;
