import React, { Component } from "react";
import { Input, Button, Label, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Endpoint } from "./../../../Utils/Endpoint";
import firetoast from "./../../../Utils/Firetoast";

class AdminUrlFields extends Component {
  state = {
    data: {
      is_active: 1,
    },
    activeField: true,
    is_mandatory: false,
    is_quick_create: false,
    is_key_field: false,
    is_table_view: false,
  };
  handleImageChange = (e) => {
    var data = { ...this.state.data };
    if (e.target.files[0]) {
      data[e.target.name] = e.target.files[0];
      this.setState({ data });
    }
  };
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete [input.name];
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  render() {
    return (
      <>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Label Name <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="field_name"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            Length<span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="extra_info"
              onChange={this.handleExtraInfo}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Default Value</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="default_value"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Image</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="file"
              name="image"
              onChange={this.handleImageChange}
            />
          </div>
        </div>
        <>
          <hr />
          <div>
            <div className="checkbox checkbox-solid-primary ml-3">
              <Input
                id="solid3"
                type="checkbox"
                name="is_active"
                checked={this.state.activeField}
                onChange={this.setActiveField}
              />
              <Label for="solid3">Set Field Active</Label>
            </div>
          </div>
        </>
        <ModalFooter>
          <Button color="primary" className="btn-sm" onClick={this.Save}>
            Save
          </Button>{" "}
          {/* <Button
                color="secondary"
                onClick={() => this.toggleCustomField(!customField)}
              >
                Cancel
              </Button> */}
        </ModalFooter>
      </>
    );
  }
  handleExtraInfo = async ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete data[input.name];
      this.setState({ data });
    } else {
      var length = { length: parseInt(input.value) };
      data[input.name] = length;
      this.setState({ data });
    }
  };
  Save = async () => {
    var data = { ...this.state.data };
    data["extra_info"] = JSON.stringify(data["extra_info"]);
    data["type_id"] = this.props.type_id;
    var form = new FormData();
    for (var key in data) {
      form.append(key, data[key]);
    }
    try {
      var response = await axios.post(Endpoint + "/admin/fields", form, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        toast.dark("Field Added");
        this.props.closeModal(!this.props.customField);
        this.props.getFields();
      }
    } catch (e) {
      var error = e.response.data.message;
      if (Array.isArray(error)) {
        // toast.error(e.response.data.message[0]);
        firetoast(error[0], "error", 5000, "top-right");
      } else {
        firetoast(error, "error", 5000, "top-right");
        // toast.error(e.response.data.message);
      }
    }
  };
  setActiveField = () => {
    var data = { ...this.state.data };
    if (this.state.activeField) {
      data["is_active"] = 0;
      this.setState({ data, activeField: false });
    } else {
      data["is_active"] = 1;
      this.setState({ data, activeField: true });
    }
  };
  setIsMandatory = () => {
    var data = { ...this.state.data };
    if (this.state.is_mandatory) {
      data["is_mandatory"] = 0;
      this.setState({ data, is_mandatory: false });
    } else {
      data["is_mandatory"] = 1;
      this.setState({ data, is_mandatory: true });
    }
  };
  setIsQuickCreate = () => {
    var data = { ...this.state.data };
    if (this.state.is_quick_create) {
      data["is_quick_create"] = 0;
      this.setState({ data, is_quick_create: false });
    } else {
      data["is_quick_create"] = 1;
      this.setState({ data, is_quick_create: true });
    }
  };
  setIsKeyField = () => {
    var data = { ...this.state.data };
    if (this.state.is_key_field) {
      data["is_key_field"] = 0;
      this.setState({ data, is_key_field: false });
    } else {
      data["is_key_field"] = 1;
      this.setState({ data, is_key_field: true });
    }
  };
  setIsTableView = () => {
    var data = { ...this.state.data };
    if (this.state.is_table_view) {
      data["is_table_view"] = 0;
      this.setState({ data, is_table_view: false });
    } else {
      data["is_table_view"] = 1;
      this.setState({ data, is_table_view: true });
    }
  };
}

export default AdminUrlFields;
