import React, { Component } from "react";
import image from "../../images/features-image/feature-image2.png";
import shape7 from "../../images/shape/shape7.png";
import shape2 from "../../images/shape/shape2.svg";
import shape3 from "../../images/shape/shape3.svg";
import shape4 from "../../images/shape/shape4.png";
class OurFeaturesTab2 extends Component {
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  };

  render() {
    return (
      <>
        <section className="features-area ptb-100 pt-0">
          <div className="container">
            <div className="tab features-list-tab">
              {/* Tab Content */}
              <div className="tab_content">
                {/* Tabs Item 01 */}
                <div id="tab1" className="tabs_item">
                  <div className="features-overview">
                    <div className="overview-image">
                      <div className="image">
                        <img src={image} alt="image" />
                      </div>
                    </div>

                    <div className="overview-content">
                      <div className="content">
                        <h2>The Most Popular Application 2021</h2>
                        <p>
                          Culpa non ex tempor qui nulla laborum. Laboris culpa
                          ea incididunt dolore ipsum.
                        </p>

                        <ul className="features-list">
                          <li>
                            <span>
                              <i className="flaticon-tick"></i> Elit mollit
                              aliqua quis ad sint nulla Lorem commodo
                              reprehenderit.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick"></i> Tempor
                              consectetur aliqua aute veniam occaecat eu do esse
                              adipisicing ut.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="flaticon-tick"></i> Culpa non ex
                              tempor qui nulla laborum. Laboris culpa ea
                              incididunt dolore ipsum tempor duis do ullamco
                              eiusmod officia magna ad id.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Animation Image */}
          <div className="shape-img7">
            <img src={shape7} alt="image" />
          </div>
          <div className="shape-img2">
            <img src={shape2} alt="image" />
          </div>
          <div className="shape-img3">
            <img src={shape3} alt="image" />
          </div>
          <div className="shape-img4">
            <img src={shape4} alt="image" />
          </div>
        </section>
      </>
    );
  }
}

export default OurFeaturesTab2;
