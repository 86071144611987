import React, { Component } from "react";
import { Label, Input, ModalFooter, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Endpoint } from "./../../../Utils/Endpoint";
import firetoast from "./../../../Utils/Firetoast";
class AdminEditEmailField extends Component {
  state = {
    data: {
      is_active: 1,
    },
    field_name: "",
    activeField: true,
  };
  componentDidMount = () => {
    var field = this.props.field;
    var data = {
      is_active: field.is_active,

      field_id: field.id,
      default_value: field.default_value,
      field_name: field.field_name,
      extra_info: field.extra_info,
    };
    this.setState({
      data,
      activeField: field.is_active === 1,
      field_name: field.field_name,
      default_value: field.default_value,
    });
  };
  render() {
    var { field } = this.props && this.props;

    return (
      <>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Label Name <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="field_name"
              defaultValue={field.field_name}
              onChange={this.handleChange}
            />
          </div>
        </div>

        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Image</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="file"
              name="image"
              onChange={this.handleImageChange}
            />
          </div>
        </div>

        <ModalFooter>
          <Button
            color="primary"
            className="btn-sm"
            onClick={this.SaveEmailField}
          >
            Save
          </Button>{" "}
          {/* <Button
                    color="secondary"
                    onClick={() => this.toggleCustomField(!customField)}
                  >
                    Cancel
                  </Button> */}
        </ModalFooter>
      </>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete [input.name];
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  SaveEmailField = async () => {
    var data = this.state.data;
    //console.log(data);
    data["name"] = data["field_name"];
    if (!data["image"]) {
      return firetoast(
        "Please provide field image",
        "error",
        3000,
        "top-center"
      );
    }
    data["options"] = JSON.stringify(data["extra_info"]);
    data["field_id"] = this.props.field.id;
    delete data["extra_info"];
    delete data["field_name"];
    delete data["default_value"];
    delete data["is_active"];
    var form = new FormData();
    for (var key in data) {
      form.append(key, data[key]);
    }
    try {
      var response = await axios.post(
        Endpoint + `/admin/universal-fields/update`,
        form,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success === 200) {
        toast.info("Field Updated");
        this.props.toggleEdit();
        this.props.refreshSection();
      }
    } catch (e) {
      var error = e.response.data.message;
      if (Array.isArray(error)) {
        // toast.error(e.response.data.message[0]);
        firetoast(error[0], "error", 5000, "top-right");
      } else {
        firetoast(error, "error", 5000, "top-right");
        // toast.error(e.response.data.message);
      }
    }
  };
  handleImageChange = (e) => {
    var data = { ...this.state.data };
    if (e.target.files[0]) {
      data[e.target.name] = e.target.files[0];
      this.setState({ data });
    }
  };
}

export default AdminEditEmailField;
