import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import { Input, FormGroup, Label, Button, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Endpoint } from "./../../../Utils/Endpoint";
import firetoast from "./../../../Utils/Firetoast";

class AdminPicklistFields extends Component {
  state = {
    picklist1: [],
    picklist2: [],

    data: {
      is_active: 1,

      extra_info: {},
    },
    activeField: true,
    is_mandatory: false,
    is_quick_create: false,
    is_key_field: false,
    is_table_view: false,
  };
  handleChange1 = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete [input.name];
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  handleImageChange = (e) => {
    var data = { ...this.state.data };
    if (e.target.files[0]) {
      data[e.target.name] = e.target.files[0];
      this.setState({ data });
    }
  };
  handleChange2 = (e) => {
    //console.log(e);
    var data = this.state.data;
    if (e.length > 0) {
      data["default_value"] = e[0].value;
      this.setState({ data, picklist2: e });
    } else {
      delete data["default_value"];
      this.setState({ data, picklist2: this.state.picklist1 });
    }
  };
  render() {
    return (
      <>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Label Name <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="field_name"
              onChange={this.handleChange1}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Length <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="extra_info"
              onChange={this.handleExtraInfo}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
            Picklist Values <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <CreatableSelect
              isMulti
              onChange={this.handleChange}
              options={this.state.picklist1}
            />
          </div>
        </div>
        {/* <div className="mb-3 row">
          <label class="col-sm-3 "></label>
          <div class="col-sm-9">
            <FormGroup check>
              <Label check>
                <Input type="checkbox" /> Role base picklist
              </Label>
            </FormGroup>
          </div>
        </div> */}
        {/* <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
            Default Value <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <CreatableSelect
              isMulti
              onChange={this.handleChange2}
              options={this.state.picklist2}
            />
          </div>
        </div> */}
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Image</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="file"
              name="image"
              onChange={this.handleImageChange}
            />
          </div>
        </div>
        {/* <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
           Color <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="color"
              name="color"
              onChange={this.handleColor}
            />
          </div>
        </div> */}
        <>
          <hr />
          <div>
            {/* <CustomInput
                        type="switch"
                        id="exampleCustomSwitch"
                        name="is_active"
                        label="Set Active"
                      /> */}
            <div className="checkbox checkbox-solid-primary ml-3">
              <Input
                id="solid3"
                type="checkbox"
                name="is_active"
                checked={this.state.activeField}
                onChange={this.setActiveField}
              />
              <Label for="solid3">Set Field Active</Label>
            </div>
          </div>
        </>
        <ModalFooter>
          <Button color="primary" className="btn-sm" onClick={this.Save}>
            Save
          </Button>{" "}
          {/* <Button
            color="secondary"
            onClick={() => this.toggleCustomField(!customField)}
          >
            Cancel
          </Button> */}
        </ModalFooter>
      </>
    );
  }
  handleChange = (e) => {
    this.setState({ picklist1: e, picklist2: e });
  };

  handleExtraInfo = async ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete data.extra_info[input.name];
      this.setState({ data });
    } else {
      var length = { ...data.extra_info, length: parseInt(input.value) };
      data[input.name] = length;
      this.setState({ data });
    }
  };
  Save = async () => {
    var data = { ...this.state.data };
    if (parseInt(data["length"]) < 300) {
      toast.error("Length Should be greater than 300");
    } else {
      var picklist = this.state.picklist1;
      var options = [];
      for (var i = 0; i < picklist.length; i++) {
        options.push(picklist[i].value);
      }
      data.extra_info.options = options;
      // //console.log(data);

      data["extra_info"] = JSON.stringify(data["extra_info"]);
      data["type_id"] = this.props.type_id;
      var form = new FormData();

      for (var key in data) {
        form.append(key, data[key]);
      }
      try {
        var response = await axios.post(Endpoint + "/admin/fields", form, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        if (response.status === 200) {
          toast.dark("Field Added");
          this.props.closeModal(!this.props.customField);
          this.props.getFields();
        }
      } catch (e) {
        var error = e.response.data.message;
        if (Array.isArray(error)) {
          // toast.error(e.response.data.message[0]);
          firetoast(error[0], "error", 5000, "top-right");
        } else {
          firetoast(error, "error", 5000, "top-right");
          // toast.error(e.response.data.message);
        }
      }
    }
  };
  setActiveField = () => {
    var data = { ...this.state.data };
    if (this.state.activeField) {
      data["is_active"] = 0;
      this.setState({ data, activeField: false });
    } else {
      data["is_active"] = 1;
      this.setState({ data, activeField: true });
    }
  };
  setIsMandatory = () => {
    var data = { ...this.state.data };
    if (this.state.is_mandatory) {
      data["is_mandatory"] = 0;
      this.setState({ data, is_mandatory: false });
    } else {
      data["is_mandatory"] = 1;
      this.setState({ data, is_mandatory: true });
    }
  };
  setIsQuickCreate = () => {
    var data = { ...this.state.data };
    if (this.state.is_quick_create) {
      data["is_quick_create"] = 0;
      this.setState({ data, is_quick_create: false });
    } else {
      data["is_quick_create"] = 1;
      this.setState({ data, is_quick_create: true });
    }
  };
  setIsKeyField = () => {
    var data = { ...this.state.data };
    if (this.state.is_key_field) {
      data["is_key_field"] = 0;
      this.setState({ data, is_key_field: false });
    } else {
      data["is_key_field"] = 1;
      this.setState({ data, is_key_field: true });
    }
  };
  setIsTableView = () => {
    var data = { ...this.state.data };
    if (this.state.is_table_view) {
      data["is_table_view"] = 0;
      this.setState({ data, is_table_view: false });
    } else {
      data["is_table_view"] = 1;
      this.setState({ data, is_table_view: true });
    }
  };
  handleColor = async ({ currentTarget: input }) => {
    //console.log(input.value);
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete data.extra_info["picklist_color"];
      this.setState({ data });
    } else {
      // var picklist_color = {  };
      data["extra_info"] = { ...data.extra_info, picklist_color: input.value };
      this.setState({ data });
    }
  };
}

export default AdminPicklistFields;
