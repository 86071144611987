import React, { Component } from "react";
import { Label, Input, ModalFooter, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { Endpoint } from "./../../../Utils/Endpoint";
import firetoast from "./../../../Utils/Firetoast";
import CreatableSelect from "react-select/creatable";

class AdminEditPickListField extends Component {
  state = {
    picklist2: [],

    data: {
      is_active: 1,

      extra_info: {},
    },
    activeField: true,
  };
  handleImageChange = (e) => {
    var data = { ...this.state.data };
    if (e.target.files[0]) {
      data[e.target.name] = e.target.files[0];
      this.setState({ data });
    }
  };
  componentDidMount = () => {
    var field = this.props.field;
    var data = {
      is_active: field.is_active,

      field_id: field.id,
      default_value: field.default_value,
      field_name: field.field_name,
      extra_info: field.extra_info,
    };
    var options = field.extra_info.options;
    var picklist2 = [];
    for (let i = 0; i < options.length; i++) {
      var obj = { label: options[i], value: options[i] };
      picklist2.push(obj);
    }
    this.setState({
      picklist2,
      data,
      activeField: field.is_active === 1,

      field_name: field.field_name,
      default_value: field.default_value,
    });
  };
  render() {
    const { field } = this.props && this.props;
    return (
      <>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Label Name <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              name="field_name"
              defaultValue={field.field_name}
              onChange={this.handleChange1}
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">
            {" "}
            Length <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="text"
              defaultValue={field.extra_info.length}
              name="extra_info"
              onChange={this.handleExtraInfo}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
            Options <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <CreatableSelect
              isMulti
              onChange={this.handleChange2}
              value={this.state.picklist2}
              options={this.state.picklist2}
            />
          </div>
        </div>
        {/* <div className="mb-3 row">
              <label class="col-sm-3 "></label>
              <div class="col-sm-9">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" /> Role base picklist
                  </Label>
                </FormGroup>
              </div>
            </div> */}
        {/* <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
            Default Value <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <CreatableSelect
              isMulti
              onChange={this.handleChange2}
              options={this.state.picklist2}
            />
          </div>
        </div> */}
        {/* <div className="mb-3 row">
          <label class="col-sm-3 ">
            {" "}
            Color <span className="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="color"
              name="color"
              defaultValue={field.extra_info["picklist_color"]}
              onChange={this.handleColor}
            />
          </div>
        </div> */}
        <div class="mb-3 row">
          <label class="col-sm-3 col-form-label">Image</label>
          <div class="col-sm-9">
            <Input
              class="form-control"
              type="file"
              name="image"
              onChange={this.handleImageChange}
            />
          </div>
        </div>
        <ModalFooter>
          <Button color="primary" className="btn-sm" onClick={this.Save}>
            Save
          </Button>{" "}
          {/* <Button
                color="secondary"
                onClick={() => this.toggleCustomField(!customField)}
              >
                Cancel
              </Button> */}
        </ModalFooter>
      </>
    );
  }
  handleChange1 = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete [input.name];
      this.setState({ data });
    } else {
      data[input.name] = input.value;
      this.setState({ data });
    }
  };
  handleChange2 = (e) => {
    //console.log(e);
    var data = this.state.data;
    var options = [];
    if (e.length > 0) {
      // data["default_value"] = e[0].value;
      for (let i = 0; i < e.length; i++) {
        options.push(e[i].label);
      }
      data["extra_info"]["options"] = options;
      this.setState({ data, picklist2: e });
    } else {
      // delete data["default_value"];
      this.setState({ data, picklist2: this.state.picklist1 });
    }
  };
  handleChange = (e) => {
    this.setState({ picklist1: e, picklist2: e });
  };

  handleExtraInfo = async ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete data.extra_info[input.name];
      this.setState({ data });
    } else {
      var length = { ...data.extra_info, length: parseInt(input.value) };
      data[input.name] = length;
      this.setState({ data });
    }
  };
  Save = async () => {
    var data = this.state.data;
    if (parseInt(data["length"]) < 300) {
      toast.error("Length Should be greater than 300");
    } else {
      //console.log(data);
      data["name"] = data["field_name"];
      if (!data["image"]) {
        return firetoast(
          "Please provide field image",
          "error",
          3000,
          "top-center"
        );
      }
      data["options"] = JSON.stringify(data["extra_info"]);
      data["field_id"] = this.props.field.id;
      delete data["extra_info"];
      delete data["field_name"];
      delete data["default_value"];
      delete data["is_active"];
      var form = new FormData();
      for (var key in data) {
        form.append(key, data[key]);
      }
      try {
        var response = await axios.post(
          Endpoint + `/admin/universal-fields/update`,
          form,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (response.data.success === 200) {
          toast.info("Field Updated");
          this.props.toggleEdit();
          this.props.refreshSection();
        }
      } catch (e) {
        var error = e.response.data.message;
        if (Array.isArray(error)) {
          // toast.error(e.response.data.message[0]);
          firetoast(error[0], "error", 5000, "top-right");
        } else {
          firetoast(error, "error", 5000, "top-right");
          // toast.error(e.response.data.message);
        }
      }
    }
  };
  setActiveField = () => {
    var data = { ...this.state.data };
    if (this.state.activeField) {
      data["is_active"] = 0;
      this.setState({ data, activeField: false });
    } else {
      data["is_active"] = 1;
      this.setState({ data, activeField: true });
    }
  };
  setIsMandatory = () => {
    var data = { ...this.state.data };
    if (this.state.is_mandatory) {
      data["is_mandatory"] = 0;
      this.setState({ data, is_mandatory: false });
    } else {
      data["is_mandatory"] = 1;
      this.setState({ data, is_mandatory: true });
    }
  };
  setIsQuickCreate = () => {
    var data = { ...this.state.data };
    if (this.state.is_quick_create) {
      data["is_quick_create"] = 0;
      this.setState({ data, is_quick_create: false });
    } else {
      data["is_quick_create"] = 1;
      this.setState({ data, is_quick_create: true });
    }
  };
  setIsKeyField = () => {
    var data = { ...this.state.data };
    if (this.state.is_key_field) {
      data["is_key_field"] = 0;
      this.setState({ data, is_key_field: false });
    } else {
      data["is_key_field"] = 1;
      this.setState({ data, is_key_field: true });
    }
  };
  setIsTableView = () => {
    var data = { ...this.state.data };
    if (this.state.is_table_view) {
      data["is_table_view"] = 0;
      this.setState({ data, is_table_view: false });
    } else {
      data["is_table_view"] = 1;
      this.setState({ data, is_table_view: true });
    }
  };
  handleColor = async ({ currentTarget: input }) => {
    //console.log(input.value);
    var data = { ...this.state.data };
    if (
      input.value === "" ||
      input.value == null ||
      input.value === undefined
    ) {
      delete data.extra_info["picklist_color"];
      this.setState({ data });
    } else {
      // var picklist_color = {  };
      data["extra_info"] = { ...data.extra_info, picklist_color: input.value };
      this.setState({ data });
    }
  };
}

export default AdminEditPickListField;
