import AdminNavbar from "../../Layout/AdminNavbar";
import MenuItems from "../MenuItems";
import { useState } from "react";
import { Modal } from "reactstrap";
import { ModalHeader } from "reactstrap";
import { ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
import firetoast from "../../Utils/Firetoast";
import { Endpoint } from "../../Utils/Endpoint";
import axios from "axios";
function AdminNotification() {
  const [NotifyTo, setNotifyTo] = useState(null);
  const [ShowModal, setShowModal] = useState(false);
  const [Url, setUrl] = useState("");
  const [Body, setBody] = useState("");
  const [Title, setTitle] = useState("");
  const token = localStorage.getItem("token");
  const Notify = async () => {
    try {
      if (Url === "") {
        return firetoast("Url can't be empty", "error", 3000, "top-right");
      }
      if (Body === "") {
        return firetoast("Body can't be empty", "error", 3000, "top-right");
      }
      if (Title === "") {
        return firetoast("Title can't be empty", "error", 3000, "top-right");
      }

      var response = null;
      if (NotifyTo === "All") {
        response = await axios.post(
          `${Endpoint}/admin/notifications/all`,
          {
            title: Title,
            body: Body,
            url: Url,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }
      if (NotifyTo === "Users") {
        response = await axios.post(
          `${Endpoint}/admin/notifications/customer`,
          {
            title: Title,
            body: Body,
            url: Url,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }
      if (NotifyTo === "Business") {
        response = await axios.post(
          `${Endpoint}/admin/notifications/business`,
          {
            title: Title,
            body: Body,
            url: Url,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
      }

      if (response.status === 200 || response.data.status === "success") {
        firetoast("Notification sent!", "dark", 3000, "top-right");
        setNotifyTo(null);
        setUrl("");
        setTitle("");
        setBody("");
        setTimeout(() => {
          setShowModal(false);
        }, 1500);
      } else {
        firetoast(response.data.message, "error", 3000, "top-right");
      }
    } catch (e) {
      firetoast("Something went wrong", "error", 3000, "top-right");
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        <>
          <div className="row mt-2">
            <div className="col-6">
              <h4 className="text-dark"> Notifications </h4>
            </div>
            <div className="mt-2">
              <div className="row">
                <div className="col-6 mt-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center">
                        <button
                          className="btn btn-large btn-dark"
                          onClick={() => {
                            setShowModal(true);
                            setNotifyTo("All");
                          }}
                        >
                          Notify All Users
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center">
                        <button
                          className="btn btn-large btn-dark"
                          onClick={() => {
                            setShowModal(true);
                            setNotifyTo("Business");
                          }}
                        >
                          Notify Business Users
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center">
                        <button
                          className="btn btn-large btn-dark"
                          onClick={() => {
                            setShowModal(true);
                            setNotifyTo("Users");
                          }}
                        >
                          Notify Customers
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        <Modal isOpen={ShowModal} toggle={() => setShowModal(!ShowModal)}>
          <ModalHeader toggle={() => setShowModal(!ShowModal)}>
            {NotifyTo === "All" && "Notify to all"}
            {NotifyTo === "Business" && "Notify to business users"}
            {NotifyTo === "Users" && "Notify to customers"}
          </ModalHeader>
          <ModalBody>
            <div className="row" style={{ alignItems: "baseline" }}>
              <div className="col-4">Title</div>
              <div className="col-8">
                <input
                  className="form-control"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-2" style={{ alignItems: "baseline" }}>
              <div className="col-4">Body</div>
              <div className="col-8">
                <textarea
                  className="form-control"
                  onChange={(e) => setBody(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-2" style={{ alignItems: "baseline" }}>
              <div className="col-4">Url</div>
              <div className="col-8">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={() => Notify()}>
              Notify
            </Button>{" "}
            <Button color="secondary" toggle={() => setShowModal(!ShowModal)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      {/* <Footer /> */}
    </>
  );
}
export default AdminNotification;
