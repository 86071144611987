import React, { Component } from "react";
import axios from "axios";
import ErrorHandler from "./../Utils/ErrroHandler";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import NavbarThree from "./../Layout/NavbarThree";
import shape7 from "../../images/shape/shape7.png";
import shape2 from "../../images/shape/shape2.svg";
import shape3 from "../../images/shape/shape3.svg";
import shape4 from "../../images/shape/shape4.png";
import FormTable from "./FormTable";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Spinner,
} from "reactstrap";
class Forms extends Component {
  state = { forms: [], addModal: false, isLoading: true };
  componentDidMount = async () => {
    this.getForms();
  };
  addtoggle = () => {
    this.setState({ addModal: !this.state.addModal });
  };
  render() {
    const { addModal } = this.state;
    return (
      <>
        <NavbarThree />

        <div style={{ marginTop: "8rem" }}>
          <div className="container">
            <div className="text-center">
              <h3 className="color-default">Form Records</h3>
            </div>

            {this.state.forms.length > 0 && (
              <div className="text-right">
                <button className="btn btn-dark" onClick={this.addtoggle}>
                  Create Form
                </button>
              </div>
            )}
            <div className="table-responsive">
              {this.state.isLoading ? (
                <div
                  style={{
                    height: "100px",
                    textAlign: "center",
                    marginTop: "40px",
                  }}
                >
                  <span>
                    <Spinner color="dark" /> Getting Records
                  </span>
                </div>
              ) : this.state.forms.length > 0 ? (
                <FormTable forms={this.state.forms} />
              ) : (
                <div
                  className="text-center"
                  style={{
                    height: "100px",
                    marginTop: "100px",
                  }}
                >
                  No Record Found{" "}
                  <button
                    className="btn btn-dark text-white"
                    onClick={this.addtoggle}
                  >
                    Create New Form
                  </button>
                </div>
              )}
            </div>
            <div className="shape-img7">
              <img src={shape7} alt="image" />
            </div>
            <div className="shape-img2">
              <img src={shape2} alt="image" />
            </div>
            <div className="shape-img3">
              <img src={shape3} alt="image" />
            </div>
            <div className="shape-img4">
              <img src={shape4} alt="image" />
            </div>
          </div>
        </div>
        <Modal isOpen={addModal} toggle={this.addtoggle}>
          <ModalHeader toggle={this.addtoggle}>Create New Form</ModalHeader>
          <ModalBody>
            <div className="row" style={{ alignItems: "baseline" }}>
              <div className="col-4">Form Name</div>
              <div className="col-8">
                <Input className="form-control" id="formName" />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={this.addFormName}>
              Add
            </Button>{" "}
            <Button color="secondary" onClick={this.addtoggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
  addFormName = async () => {
    this.setState({ isLoading: true });
    var token = localStorage.getItem("token");
    var input = document.getElementById("formName");
    var value = input.value;
    var form = new FormData();
    form.append("name", value);

    try {
      var response = await axios.post(Endpoint + "/business/forms", form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast("Form Added", "success", 3000, "top-right");
      }
      this.getForms();
      this.addtoggle();
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });

      if (e.response) {
        this.setState({ isLoading: false });

        if (
          e.response.data.error == null ||
          e.response.data.error === undefined
        ) {
          firetoast(e.response.data.message, "error", 3000, "top-right");
        } else {
          ErrorHandler(e.response.data.error);
        }
      }
    }
  };
  getForms = async () => {
    var token = localStorage.getItem("token");
    var response = await axios.get(Endpoint + "/business/forms", {
      headers: { Authorization: token },
    });
    this.setState({ forms: response.data.data, isLoading: false });
  };
}

export default Forms;
