import AdminNavbar from "./../../Layout/AdminNavbar";
import MenuItems from "./../MenuItems";
import { useState, useEffect } from "react";
import firetoast from "./../../Utils/Firetoast";
import { Endpoint } from "./../../Utils/Endpoint";
import axios from "axios";
import { Spinner } from "reactstrap";
import FieldList from "./FieldsList";
import { Modal } from "reactstrap";
import { ModalHeader } from "reactstrap";
import { ModalBody, ModalFooter } from "reactstrap";
import { Button } from "reactstrap";
import AdminTextField from "./conditional fields/textFields";
import AdminMultiComboFields from "./conditional fields/multiComboFileds";
import AdminDecimalFields from "./conditional fields/decimalFields";
import AdminIntegerFields from "./conditional fields/integerFields";
import AdminPercentFields from "./conditional fields/percentFields";
import AdminDateFields from "./conditional fields/dateFields";
import AdminEmailFields from "./conditional fields/emailFields";
import AdminUrlFields from "./conditional fields/urlFields";
import AdminCheckboxFields from "./conditional fields/checkboxFields";
import AdminTextAreaFields from "./conditional fields/textareaFields";
import AdminTimeFields from "./conditional fields/timeFields";
import AdminMultiCurrencyFields from "./conditional fields/multiCurrencyFields";
import AdminPhoneFields from "./conditional fields/phoneFields";
import AdminUploadFields from "./conditional fields/uploadFields";
import AdminPicklistFields from "./conditional fields/pickListFields";
import AdminRadioFields from "./conditional fields/radioFields";
function AdminFields() {
  const [UniversalFields, setUniversalFields] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [sectionType, setsectionType] = useState(null);
  const [is_mandatory, setis_mandatory] = useState(false);
  const [is_quick_create, setis_quick_create] = useState(false);
  const [is_key_field, setis_key_field] = useState(false);
  const [is_table_view, setis_table_view] = useState(false);
  const [data, setdata] = useState({ is_active: 1 });
  const [fields, setfields] = useState([]);
  const [type_id, settype_id] = useState("1");
  const token = localStorage.getItem("token");
  useEffect(() => {
    getFieldTypes();
    getFields();
  }, []);
  var handleFieldType = ({ currentTarget: input }) => {
    console.log("hahahahahahaah");
    var field = JSON.parse(input.value);
    setsectionType(field.id);
    settype_id(field.id);
    setis_mandatory(false);
    setis_quick_create(false);
    setis_key_field(false);
    setis_table_view(false);
    setdata({ is_active: 1 });
  };
  var getFieldTypes = async () => {
    try {
      const response = await axios.get(`${Endpoint}/admin/fields/types`, {
        headers: {
          Authorization: token,
        },
      });
      setfields(response.data.data);
    } catch (e) {
      firetoast("Something went wrong", "error", 3000, "top-center");
    }
  };

  var getFields = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${Endpoint}/admin/universal-fields`, {
        headers: {
          Authorization: token,
        },
      });
      setUniversalFields(response.data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      firetoast("Something went wrong", "error", 3000, "top-center");
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        <>
          <div className="row mt-2">
            <div className="col-6">
              <h4 className="text-dark"> Universal Fields </h4>
            </div>
            <div className="mt-2">
              <div className="text-right mb-2">
                <button
                  className="btn btn-warning"
                  onClick={() => setShowModal(!ShowModal)}
                >
                  Create Field
                </button>
              </div>
              {isLoading ? (
                <div className="text-center">
                  <Spinner color="dark" /> Getting fields...
                </div>
              ) : (
                <FieldList
                  UniversalFields={UniversalFields}
                  getFields={getFields}
                />
              )}
            </div>
          </div>
        </>
        <Modal
          isOpen={ShowModal}
          toggle={() => setShowModal(!ShowModal)}
          size="lg"
        >
          <ModalHeader toggle={() => setShowModal(!ShowModal)}>
            New Field
          </ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col-9 m-auto">
                <div class="mb-3 row">
                  <label class="col-sm-3 col-form-label">
                    Select Field Type
                  </label>
                  <div class="col-sm-9">
                    <select
                      class="form-control"
                      id="validationCustom04"
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => handleFieldType(e)}
                    >
                      <option>Select Option</option>
                      {fields.map((field) => (
                        <option value={JSON.stringify(field)}>
                          {field.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {sectionType === 1 && (
                  <AdminTextField
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 13 && (
                  <AdminMultiComboFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {/*Error */}{" "}
                {sectionType === 3 && (
                  <AdminDecimalFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {(sectionType === 4 || sectionType === 20) && (
                  <AdminIntegerFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 5 && (
                  <AdminPercentFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 7 && (
                  <AdminDateFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 8 && (
                  <AdminEmailFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 10 && (
                  <AdminUrlFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 11 && (
                  <AdminCheckboxFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 12 && (
                  <AdminTextAreaFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 14 && (
                  <AdminTimeFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 6 && (
                  <AdminMultiCurrencyFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 9 && (
                  <AdminPhoneFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {(sectionType === 15 ||
                  sectionType === 18 ||
                  sectionType === 19) && (
                  <AdminUploadFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 2 && (
                  <AdminPicklistFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
                {sectionType === 16 && (
                  <AdminRadioFields
                    type_id={type_id}
                    closeModal={() => setShowModal(!ShowModal)}
                    getFields={getFields}
                  />
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>

      {/* <Footer /> */}
    </>
  );
}
export default AdminFields;
