import firetoast from "./Firetoast";

function ErrorHandler(errors) {
  console.log(errors);
  var keys = Object.keys(errors);
  console.log(errors);
  console.log(keys);
  for (var i = 0; i < keys.length; i++) {
    let key = keys[i];
    let error = errors[key][0];
    firetoast(error, "error", 5000, "top-right");
  }
  //   console.log(errors);
}
export default ErrorHandler;
