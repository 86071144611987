import Footer from "./../Layout/Footer";
import AdminNavbar from "./../Layout/AdminNavbar";
import { useState } from "react";
import firetoast from "./../Utils/Firetoast";
import axios from "axios";
import { Endpoint } from "./../Utils/Endpoint";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
function AdminLogin() {
  const history = useHistory();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!Email || Email === "") {
      setLoading(false);

      return firetoast(
        "Please provide email address",
        "error",
        2000,
        "top-center"
      );
    }
    if (!Password || Password === "") {
      setLoading(false);

      return firetoast("Please provide password", "error", 2000, "top-center");
    }

    try {
      let data = new FormData();
      data.append("email", Email);
      data.append("password", Password);
      var response = await axios.post(`${Endpoint}/admin/login`, data);
      localStorage.setItem("token", "Bearer " + response.data.data.token);
      localStorage.setItem(
        "nopen-user",
        JSON.stringify(response.data.data.user)
      );
      localStorage.setItem("role", response.data.data.user.role_id);
      firetoast("Proceeding....", "dark", 3000, "top-right");
      setLoading(false);
      setTimeout(() => {
        window.location.href = "/admin/dashboard";
      }, 3000);
    } catch (e) {
      setLoading(false);

      console.log(e);
      firetoast("Something went wrong", "error", 2000, "top-right");
    }
  };
  return (
    <>
      <AdminNavbar />

      {/* <PageHeader
    pageTitle="Login"
    breadcrumbTextOne="Home"
    breadcrumbUrl="/"
    breadcrumbTextTwo="Login"
  /> */}

      <div className="container" style={{ marginTop: "8rem" }}>
        <div className="form-content">
          <div className="form-header">
            <h3>Login</h3>
            <p>If you have an account with us, please log in.</p>
          </div>

          <form className="mt-5" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                required
                value={Email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                required
                value={Password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password"
              />
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember me
                  </label>
                </div>
              </div>

              {/* <div className="col-6 text-right">
                <Link to="/reset-password">
                  <a className="form-link right">Forgot password?</a>
                </Link>
              </div> */}
            </div>

            <button type="submit" className="default-btn" disabled={isLoading}>
              {isLoading ? (
                <div className="text-center">
                  <Spinner color="white" size="md" />
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
          {/* <div className="text-center mt-3">OR</div>
          <SocialLoginButtons role={this.state.role} />
          <div className="form-footer">
            <p>
              Don’t have an account?
              <Link to="/signup">
                <a className="form-link">Signup</a>
              </Link>
            </p>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default AdminLogin;
