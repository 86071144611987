import otpImage from "../../assets/images/otp-verify.svg";
import OtpInput from "react-otp-input";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { Endpoint } from "../Utils/Endpoint";
import firetoast from "../Utils/Firetoast";
import React from "react";
import { useLocation, Link } from "react-router-dom";

function OtpVerify() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState(false);
  const [password, setpassword] = useState("");

  const toggle = () => setModal(!modal);
  const email = query.get("email");
  const verify = query.get("verify");
  var emailVerify = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      var form = new FormData();
      form.append("email", email);
      form.append("otp", otp);
      var response = await axios.post(Endpoint + "/verify-token", form);
      if (response.data.status === 200) {
        firetoast("Account Verfied!", "success", 3000, "top-right");
        setIsLoading(false);

        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        setIsLoading(false);

        firetoast(response.data.message, "success", 3000, "top-right");
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);

      firetoast("Something went wrong!", "error", 4000, "top-right");
    }
  };
  var resend = async (e) => {
    try {
      var form = new FormData();
      form.append("email", email);
      form.append("password", password);
      var response = await axios.post(Endpoint + "/refresh-token", form);
      if (response.data.status === 200) {
        firetoast("OTP Resent!", "success", 3000, "top-right");
        setTimeout(() => {
          toggle();
        }, 3000);
      } else {
        firetoast(response.data.message, "success", 3000, "top-right");
      }
    } catch (e) {
      console.log(e);
      firetoast("Something went wrong!", "error", 4000, "top-right");
    }
  };
  useEffect(() => {
    if (verify && verify === "Y") {
      setModal(true);
    }
  }, []);
  return (
    <>
      <div style={{ margin: "6rem 0.5rem 0rem 0.5rem" }}>
        {isLoading ? (
          <div style={{ textAlign: "center", height: "100px" }}>
            <Spinner color="primary" /> Please Wait...
          </div>
        ) : (
          <>
            <div className="container">
              <Link to="/login">
                <i class="fas fa-chevron-left"></i> Back
              </Link>
            </div>
            <div className="text-center">
              <h4 className="mb-5 color-default">Please Verify To Continue</h4>
              <img
                src={otpImage}
                alt="otp"
                className="img-fluid"
                style={{ height: "200px" }}
              />
              <p className="mt-3">
                Enter the verification code we just sent you on your email
                address.
              </p>
              <div className="row m-auto mt-4">
                <div className="col-md-6 m-auto col-sm-12">
                  <OtpInput
                    value={otp}
                    onChange={(e) => setOtp(e)}
                    numInputs={6}
                    separator={<span style={{ color: "white" }}>---</span>}
                    inputStyle={{
                      height: "3em",
                      width: "3em",
                    }}
                    containerStyle={{ justifyContent: "center" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-warning text-white btn-lg"
                  onClick={(e) => emailVerify(e)}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner color="white" size={"md"} />
                    </div>
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
              <p className="mt-3">
                <small>
                  Didn't recieve OTP ?{" "}
                  <a href="#" onClick={(e) => toggle()}>
                    Resend Code
                  </a>
                </small>
              </p>
            </div>
          </>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle}>Otp Resend</ModalHeader>
        <ModalBody>
          <label>Please Provide your Current Password</label>
          <input
            className="form-control"
            onChange={(e) => setpassword(e.target.value)}
            type="password"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => resend()}>
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
}
export default OtpVerify;
