import { Route } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/boxicons.min.css";
import "./assets/css/counter.css";
import "react-toastify/dist/ReactToastify.css";
// import "./node_modules/react-modal-video/scss/modal-video.scss";
// import "react-accessible-accordion/dist/fancy-example.css";
// import "react-image-lightbox/style.css";
// import "./node_modules/react-modal-video/css/modal-video.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Landing from "./Components/Landing/Landing";
import Login from "./Components/Sign-In/Signin";
import Signup from "./Components/Sign-up/Signup";
import TermsAndConditions from "./Components/Terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./Components/Privacy-policy/Privacy-policy";
import { ToastContainer } from "react-toastify";
import UserInformation from "./Components/User/UserInformation";
import Userlanding from "./Components/User/UserLanding";
import CreatedForm from "./Components/User/CreatedForm";
import UserHistory from "./Components/User/UserHistory";
import FormHistoryDetail from "./Components/User/FormHistoryDetail";
import GeneralProfile from "./Components/User/Profile";
import UserDetail from "./Components/Business/UserDetails";
import UserProfile from "./Components/Business/Profile";
import FormHistory from "./Components/Business/FormHistory";
import Forms from "./Components/Business/Form";
import SubmittedRecords from "./Components/Business/SubmittedRecords";
import GenerateQr from "./Components/Business/Generate-Qr";
import FormDetails from "./Components/Business/FormDetails";
import Packages from "./Components/Business/Packages";
import UserInformationProfile from "./Components/User/UserInformationProfile";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import AdminLogin from "./Components/Admin/AdminLogin";
import UserPersonalInformation from "./Components/User/UserPersonalInformation";
import AdminUsers from "./Components/Admin/Users/AdminUsers";
import AdminSubscription from "./Components/Admin/Subscription/AdminSubscription";
import SubscriptionById from "./Components/Admin/Subscription/SubscriptionById";
import AppContent from "./Components/Admin/App Content/AppContent";
import AdminNotification from "./Components/Admin/Notifications/AdminNotification";
import AdminFields from "./Components/Admin/Forms/AdminForms";
import OtpVerify from "./Components/Otp Verify/OtpVerification";
import ProtectedRoute from "./Components/Utils/ProtectedRoute";
import LoginRoute from "./Components/Utils/LoginRoute";
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <LoginRoute path="/" exact component={Landing} />
      <Route path="/login" exact component={Login} />
      <Route path="/signup" exact component={Signup} />
      <Route path="/otp-verify" exact component={OtpVerify} />
      <Route
        path="/terms-and-conditions"
        component={TermsAndConditions}
        exact
      />
      <Route path="/privacy-policy" component={PrivacyPolicy} exact />
      {/*User Routes */}
      <ProtectedRoute
        path="/user-information"
        component={UserInformation}
        exact
      />
      <ProtectedRoute
        path="/user-personal-information"
        component={UserPersonalInformation}
        exact
      />
      <ProtectedRoute path="/user-landing" component={Userlanding} exact />
      <ProtectedRoute path="/created-form/:id" component={CreatedForm} exact />
      <ProtectedRoute path="/user-history" component={UserHistory} exact />
      <ProtectedRoute path="/packages" component={Packages} exact />
      <ProtectedRoute
        path="/form-history-detail/:recordID/:formID"
        exact
        component={FormHistoryDetail}
      />
      <ProtectedRoute
        path="/general-profile"
        exact
        component={GeneralProfile}
      />
      {/*Business Routes*/}
      <ProtectedRoute path="/user-details" exact component={UserDetail} />
      <ProtectedRoute path="/user-profile" exact component={UserProfile} />
      <ProtectedRoute path="/form-history" exact component={FormHistory} />
      <ProtectedRoute path="/forms" exact component={Forms} />
      <ProtectedRoute
        path="/submitted-records/:id"
        exact
        component={SubmittedRecords}
      />
      <ProtectedRoute
        path="/generate-qr/:id/:name"
        exact
        component={GenerateQr}
      />
      <ProtectedRoute path="/formDetails/:id" exact component={FormDetails} />
      <Route path="/form/:name/:id" exact component={CreatedForm} />
      <ProtectedRoute
        path="/user-profile/details"
        component={UserInformationProfile}
      />

      {/*Admin Route*/}
      <ProtectedRoute
        path="/admin/dashboard"
        exact
        component={AdminDashboard}
      />
      <Route path="/admin/login" exact component={AdminLogin} />
      <ProtectedRoute path="/admin/users" exact component={AdminUsers} />
      <ProtectedRoute
        path="/admin/subscriptions"
        exact
        component={AdminSubscription}
      />
      <ProtectedRoute
        path="/admin/subscription/:id"
        exact
        component={SubscriptionById}
      />
      <ProtectedRoute path="/admin/app-content" exact component={AppContent} />
      <ProtectedRoute
        path="/admin/notifications"
        exact
        component={AdminNotification}
      />
      <ProtectedRoute path="/admin/fields" exact component={AdminFields} />
    </div>
  );
}

export default App;
