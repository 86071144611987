import React, { Component } from "react";
import { Link } from "react-router-dom";
import firetoast from "./../Utils/Firetoast";
import logo from "../../images/logo.png";
class UserNavbar extends Component {
  state = {
    sidebarModal: false,
    collapsed: false,
  };
  // toggleModal = () => {
  //   this.setState({
  //     sidebarModal: !this.state.sidebarModal,
  //   });
  // };
  // state = {
  //   searchForm: false,
  // };
  // handleSearchForm = () => {
  //   this.setState((prevState) => {
  //     return {
  //       searchForm: !prevState.searchForm,
  //     };
  //   });
  // };

  // // Navbar
  // _isMounted = false;
  // state = {
  //   display: false,
  //   collapsed: true,
  // };
  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  componentDidMount() {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    let { products } = this.props;
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <>
        <div id="navbar" className="navbar-area">
          <div className="adani-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link to="/" className="navbar-brand">
                  <img src={logo} alt="logo" style={{ height: "80px" }} />
                </Link>

                <button
                  onClick={this.toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul
                    className="navbar-nav"
                    style={{
                      width: "100%",
                      marginLeft: "inherit",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex">
                      <li>
                        <Link
                          to="/user-landing"
                          className="nav-link"
                          style={{ padding: "7px 20px" }}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/user-history"
                          className="nav-link"
                          style={{ padding: "7px 20px" }}
                        >
                          History
                        </Link>
                      </li>
                    </div>
                    <div className="d-flex">
                      <li>
                        <Link
                          to="/general-profile"
                          className="nav-link"
                          style={{ padding: "7px 20px" }}
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="nav-link"
                          style={{ padding: "7px 20px" }}
                          onClick={() => {
                            firetoast(
                              "Logging Out...",
                              "info",
                              3000,
                              "top-right"
                            );

                            setTimeout(() => {
                              localStorage.clear();
                              window.location.href = "/";
                            }, 3000);
                          }}
                        >
                          Log Out
                        </Link>
                      </li>
                    </div>
                  </ul>

                  {/* <div className="others-options">
                        <div className="cart-items">
                          <Link href="/cart">
                            <a>
                              <i className="fas fa-shopping-cart"></i>
                              <span>{products.length}</span>
                            </a>
                          </Link>
                        </div>
    
                        <div className="option-item">
                          <i
                            onClick={this.handleSearchForm}
                            className="search-btn flaticon-search"
                            style={{
                              display: this.state.searchForm ? "none" : "block",
                            }}
                          ></i>
    
                          <i
                            onClick={this.handleSearchForm}
                            className={`close-btn flaticon-close ${
                              this.state.searchForm ? "active" : ""
                            }`}
                          ></i>
    
                          <div
                            className="search-overlay search-popup"
                            style={{
                              display: this.state.searchForm ? "block" : "none",
                            }}
                          >
                            <div className="search-box">
                              <form className="search-form">
                                <input
                                  className="search-input"
                                  name="search"
                                  placeholder="Search"
                                  type="text"
                                />
                                <button className="search-button" type="submit">
                                  <i className="fas fa-search"></i>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
    
                        <div className="burger-menu" onClick={this.toggleModal}>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                     */}
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* Right Sidebar Modal */}
        {/* <SidebarModal
              onClick={this.toggleModal}
              active={this.state.sidebarModal ? "active" : ""}
            /> */}
      </>
    );
  }
}

export default UserNavbar;
