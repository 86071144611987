import { useState, useEffect } from "react";
import AdminNavbar from "./../../Layout/AdminNavbar";
import MenuItems from "./../MenuItems";
import { Spinner } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Endpoint } from "./../../Utils/Endpoint";
import firetoast from "./../../Utils/Firetoast";

function SubscriptionById() {
  const { id } = useParams();
  const [IsLoading, seIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [Detail, setDetail] = useState(null);
  const [Changed, setChanged] = useState(false);
  useEffect(() => {
    getData();
  }, []);
  let getData = async () => {
    seIsLoading(true);
    try {
      const response = await axios.get(
        `${Endpoint}/admin/subscriptions/${id}`,
        { headers: { Authorization: token } }
      );
      setDetail(response.data.data);
      seIsLoading(false);
    } catch (e) {
      console.log("Error while getting subscription id", e);
      seIsLoading(false);
    }
  };
  let updateRecord = async (e) => {
    e.preventDefault();
    let name = document.getElementById("Name").value;
    let price = document.getElementById("Price").value;
    let active = document.getElementById("Active").checked;

    if (!name || name === "") {
      return firetoast(
        "Subscription Name is required",
        "error",
        3000,
        "top-center"
      );
    }
    if (!price || price === "") {
      return firetoast(
        "Subscription Price is required",
        "error",
        3000,
        "top-center"
      );
    }
    try {
      const response = await axios.post(
        `${Endpoint}/admin/subscriptions/update`,
        {
          name,
          price,
          subscription_id: id,
        },
        {
          headers: { Authorization: token },
        }
      );
      if (response.status === 200) {
        firetoast("Details updated", "dark", 3000, "top-center");
        setChanged(false);
      } else {
        firetoast("Something went wrong", "error", 3000, "top-center");
      }
    } catch (e) {
      console.log(e.response);
      firetoast(
        e.response.data.error["name"][0] || e.response.data.error["price"][0],
        "error",
        3000,
        "top-center"
      );

      console.log("Error while updating", e);
    }
  };
  return (
    <>
      <AdminNavbar />

      <div style={{ marginTop: "8rem" }} className="container">
        <MenuItems />

        {IsLoading ? (
          <div className="mt-5 text-center">
            <Spinner color="dark" /> Getting Data...
          </div>
        ) : (
          <>
            <div className="row mt-2">
              <div className="col-6">
                <h4 className="text-dark"> Subscription Detail </h4>
              </div>
              <div className="mt-2">
                <form
                  onSubmit={(e) => {
                    updateRecord(e);
                  }}
                >
                  <div className="row">
                    <div class="col-6 form-group">
                      <label for="exampleInputPassword1">Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Name"
                        defaultValue={Detail ? Detail.name : ""}
                        onChange={() => setChanged(true)}
                        placeholder="Name"
                      />
                    </div>
                    <div class="col-6 form-group">
                      <label for="exampleInputPassword1">Price</label>
                      <input
                        type="number"
                        defaultValue={Detail ? parseInt(Detail.price) : ""}
                        class="form-control"
                        id="Price"
                        onChange={() => setChanged(true)}
                        placeholder="Price"
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div class="col-6">
                      <div class="form-group form-check">
                        <input
                          type="checkbox"
                          defaultChecked={
                            Detail ? Detail.is_active === 1 : false
                          }
                          class="form-check-input"
                          id="Active"
                          onChange={() => setChanged(true)}
                        />
                        <label class="form-check-label" for="exampleCheck1">
                          Active
                        </label>
                      </div>
                    </div>
                    <div class="col-6 form-group">
                      <button
                        className="btn btn-warning btn-sm"
                        disabled={!Changed}
                        type="submit"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>

      {/* <Footer /> */}
    </>
  );
}
export default SubscriptionById;
