import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";

const options = {
  loop: true,
  nav: false,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  mouseDrag: false,
  touchDrag: false,
  navText: [
    "<i className='flaticon-left-chevron'></i>",
    "<i className='flaticon-right-chevron'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
    1550: {
      items: 4,
    },
  },
};

class Testimonials extends Component {
  _isMounted = false;
  state = {
    display: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ display: true });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <section className="feedback-area ptb-100 bg-color">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Testimonials</span>
              <h2>Some Lovely Feedback From Our Clients</h2>
            </div>
          </div>

          {this.state.display ? (
            <OwlCarousel
              className="feedback-slides owl-carousel owl-theme"
              {...options}
            >
              <div className="single-feedback-item">
                <p>
                  “We believe brand interaction is key in communication. Real
                  innovations and a positive customer experience are the heart
                  of successful communication. No fake products and services.”
                </p>
                <div className="client-info">
                  <img
                    src="https://images.unsplash.com/photo-1552058544-f2b08422138a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=399&q=80"
                    alt="image"
                  />
                  <h3>Jason Roy</h3>
                  <span>Manager</span>
                </div>
              </div>

              <div className="single-feedback-item">
                <p>
                  “We believe brand interaction is key in communication. Real
                  innovations and a positive customer experience are the heart
                  of successful communication. No fake products and services.”
                </p>
                <div className="client-info">
                  <img
                    src="https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80"
                    alt="image"
                  />
                  <h3>James Anderson</h3>
                  <span>Web Developer</span>
                </div>
              </div>

              <div className="single-feedback-item">
                <p>
                  “We believe brand interaction is key in communication. Real
                  innovations and a positive customer experience are the heart
                  of successful communication. No fake products and services.”
                </p>
                <div className="client-info">
                  <img
                    src="https://images.unsplash.com/photo-1464863979621-258859e62245?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1372&q=80"
                    alt="image"
                  />
                  <h3>Sarah Taylor</h3>
                  <span>Designer</span>
                </div>
              </div>

              <div className="single-feedback-item">
                <p>
                  “We believe brand interaction is key in communication. Real
                  innovations and a positive customer experience are the heart
                  of successful communication. No fake products and services.”
                </p>
                <div className="client-info">
                  <img
                    src="https://images.unsplash.com/photo-1492446845049-9c50cc313f00?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80"
                    alt="image"
                  />
                  <h3>Steven Smith</h3>
                  <span>Manager</span>
                </div>
              </div>

              <div className="single-feedback-item">
                <p>
                  “We believe brand interaction is key in communication. Real
                  innovations and a positive customer experience are the heart
                  of successful communication. No fake products and services.”
                </p>
                <div className="client-info">
                  <img
                    src="https://images.unsplash.com/photo-1492447216082-4726bf04d1d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                    alt="image"
                  />
                  <h3>Tom Nessham</h3>
                  <span>EnvyTheme</span>
                </div>
              </div>
            </OwlCarousel>
          ) : (
            ""
          )}
        </section>
      </>
    );
  }
}

export default Testimonials;
