import React, { Component } from "react";
import serviceone from "../../images/Group 42.png";
import servicetwo from "../../images/Group 43.png";
import servicethree from "../../images/Group 44.png";
class FeaturedServices extends Component {
  render() {
    return (
      <>
        <section className="featured-services-area ptb-100">
          <div className="container">
            <div className="section-title">
              {/* <span className="sub-title">See What You Will Get With Us</span> */}
              <h2>See What You Will Get With Us</h2>
              {/* <p>Real innovations and positive customer experience are the heart of successful communication. No fake products and services.</p> */}
            </div>

            <div className="row">
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <img src={serviceone} />

                  <h3 style={{ marginTop: "25px" }}>Create Account</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore
                  </p>

                  {/* <a href="/service-details" className="default-btn">
                    Read More <span></span>
                  </a> */}
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="single-featured-box">
                  <img src={servicetwo} />

                  <h3 style={{ marginTop: "25px" }}>Filling The Forms</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore
                  </p>

                  {/* <a href="/service-details" className="default-btn">
                    Read More <span></span>
                  </a> */}
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div className="single-featured-box">
                  <img src={servicethree} />

                  <h3 style={{ marginTop: "25px" }}>Get Appointment</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore
                  </p>

                  {/* <a href="/service-details" className="default-btn">
                    Read More <span></span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FeaturedServices;
