import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    let currentYear = new Date().getFullYear();
    return (
      <>
        <section className="footer-area" style={{ paddingTop: "0px" }}>
          <div className="container">
            {/* <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <h3>Contact Info</h3>

                                    <ul className="footer-contact-info">
                                        <li>
                                            <i className="flaticon-phone-call"></i>
                                            <span>Mon to Fri : 10:00AM - 06:00PM</span>
                                           
                                            <a to="tel:1235421457852">+123 54214 578 52</a>
                                        </li>
                                        <li>
                                            <i className="flaticon-email"></i>
                                            <span>Do You Have a Question?</span>
                                            <a to="mailto:example@taiker.com">example@taiker.com</a>
                                        </li>
                                        <li>
                                            <i className="flaticon-social-media"></i>
                                            <span>Socials Network</span>

                                            <ul className="social">
                                                <li>
                                                    <Link to="/#">
                                                        <a target="_blank"><i className="fab fa-twitter"></i></a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/#">
                                                        <a target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/#">
                                                        <a target="_blank"><i className="fab fa-instagram"></i></a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/#">
                                                        <a target="_blank"><i className="fab fa-linkedin"></i></a>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/#">
                                                        <a target="_blank"><i className="fab fa-youtube"></i></a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget pl-5">
                                    <h3>Quick Links</h3>

                                    <ul className="footer-quick-links">
                                        <li>
                                            <Link to="/">
                                                <a>Home</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about2">
                                                <a>About</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/blog">
                                                <a>Blog</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                <a>Contact</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a>Agency</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a>Digital</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                <a>Support</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/shop">
                                                <a>Shop</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a>Agency</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a>Digital</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                <a>Support</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/shop">
                                                <a>Shop</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a>Digital</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/conatct">
                                                <a>Support</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                <div className="single-footer-widget pl-5">
                                    <h3>Instagram</h3>

                                    <ul className="footer-instagram-post">
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img1.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img2.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img3.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img4.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img5.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img src={require("../../images/instagram-image/insta-img6.jpg")} alt="image" />
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

            <div className="copyright-area">
              <div className="row align-items-center">
                <div className="col-lg-6 col-sm-6 col-md-6">
                  <p>
                    Copyright &copy;{currentYear} Nopen. All rights reserved{" "}
                    <a to="https://nopen.us/" target="_blank">
                      Nopen
                    </a>
                  </p>
                </div>

                <div className="col-lg-6 col-sm-6 col-md-6">
                  <ul>
                    <li>
                      <Link to="/terms-and-conditions" trailingSlash={true}>
                        <a>Terms & Conditions</a>
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" trailingSlash={true}>
                        <a>Privacy Policy</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Footer;
