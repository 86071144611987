import React, { Component } from "react";
import axios from "axios";
import UserNavbar from "./../Layout/UserNavbar";
import firetoast from "./../Utils/Firetoast";
import { Endpoint } from "./../Utils/Endpoint";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";

class UserPersonalInformation extends Component {
  state = {
    show: 1,
    countries: [],
    employmentDetails: [{}],
    personal_details: {},
    health_details: {},
    insuranceDetails: [{}],
    educationDetails: [{}],
    first_name: "",
    last_name: "",
    loading: true,
    sidebarModal: false,
    collapsed: false,
  };

  toggleNavbar = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount = async () => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
    window.scrollTo(0, 0);
    this.checkProfileUpdate();
    var token = localStorage.getItem("token");
    var resp = await axios.get(Endpoint + "/get/countries-list", {
      headers: { Authorization: token },
    });
    this.setState({ countries: resp.data.data.records });

    this.getData();
  };

  checkProfileUpdate = async () => {
    try {
      var token = localStorage.getItem("token");
      var response = await axios.post(
        `${Endpoint}/set/profile-check`,
        {},
        { headers: { Authorization: token } }
      );
      var { data } = response.data;
      if (parseInt(data.is_profile_set) === 1) {
        this.props.history.push("/user-landing");
      }
    } catch (e) {
      firetoast(
        "Something went wrong while getting profile status",
        "error",
        3000,
        "top-right"
      );
    }
  };
  render() {
    const { collapsed } = this.state;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    const { personal_details } = this.state;
    return (
      <>
        <div id="navbar" className="navbar-area">
          <div className="adani-nav">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light">
                <Link to="/" className="navbar-brand">
                  <img src={logo} alt="logo" style={{ height: "80px" }} />
                </Link>

                <button
                  onClick={this.toggleNavbar}
                  className={classTwo}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul
                    className="navbar-nav"
                    style={{
                      width: "100%",
                      marginLeft: "inherit",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div>
                      <Link
                        to="#"
                        className="nav-link"
                        style={{ padding: "7px 20px" }}
                        onClick={() => {
                          firetoast(
                            "Logging Out...",
                            "info",
                            3000,
                            "top-right"
                          );

                          setTimeout(() => {
                            localStorage.clear();
                            window.location.href = "/";
                          }, 3000);
                        }}
                      >
                        Log Out
                      </Link>
                    </div>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div
            className="text-center"
            style={{ height: "100px", marginTop: "8rem" }}
          >
            <Spinner color="dark" /> Getting Ready...{" "}
          </div>
        ) : (
          <div style={{ marginTop: "8rem" }}>
            <div className="container">
              <div
                class="accordion"
                id="accordionExample"
                style={{ border: "none" }}
              >
                <div className="mb-2">
                  <div
                    class="card-header"
                    id="headingOne"
                    style={{
                      background: "#EEEEFF",
                      borderBottom: "none",
                      cursor: "pointer",
                    }}
                  >
                    <h6 class="mb-0" onClick={() => this.toggle(1)}>
                      Personal Details
                    </h6>
                  </div>

                  <div
                    id="collapseOne"
                    class={this.state.show === 1 ? "collapse show" : "collapse"}
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div>
                      <form onSubmit={this.submitPersonalDetails}>
                        <div className="row">
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="your-email"
                                value={personal_details.first_name}
                                required
                                name="first_name"
                                onChange={this.handleChange}
                                placeholder="Enter your first name here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="your-email"
                                required
                                value={personal_details.last_name}
                                name="last_name"
                                onChange={this.handleChange}
                                placeholder="Enter your last name here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>DOB</label>
                              <input
                                type="date"
                                className="form-control"
                                id="your-email"
                                required
                                name="dob"
                                value={
                                  personal_details["dob"]
                                    ? personal_details["dob"]
                                    : null
                                }
                                onChange={this.handleChange}
                                placeholder="Enter Your Date Of Birth"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Sex/Gender</label>
                              <select
                                className="form-control"
                                required
                                name="gender"
                                onChange={this.handleChange}
                                placeholder="Select your gender"
                              >
                                <option value={null}>Select your gender</option>
                                <option
                                  value="male"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "male"
                                  }
                                >
                                  Male
                                </option>
                                <option
                                  value="female"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "female"
                                  }
                                >
                                  Female
                                </option>
                                <option
                                  value="other"
                                  selected={
                                    personal_details.gender &&
                                    personal_details.gender === "other"
                                  }
                                >
                                  Other
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>Country</label>
                              <select
                                type="text"
                                className="form-control"
                                required
                                name="country"
                                onChange={this.handleChange}
                                placeholder="Enter country here"
                              >
                                <option>Select Country</option>
                                {this.state.countries.map((country, index) => (
                                  <option
                                    value={country.name}
                                    selected={
                                      personal_details.country &&
                                      personal_details.country === country.name
                                    }
                                  >
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6 mb-1">
                            <div className="form-group">
                              <label>Marital Status</label>
                              <select
                                type="text"
                                className="form-control"
                                required
                                name="marital_status"
                                onChange={this.handleChange}
                              >
                                <option>Select Marital Status</option>
                                <option
                                  value={"single"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status === "single"
                                  }
                                >
                                  Single
                                </option>
                                <option
                                  value={"married"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "married"
                                  }
                                >
                                  Married
                                </option>
                                <option
                                  value={"widowed"}
                                  selected={
                                    personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "widowed"
                                  }
                                >
                                  Widowed
                                </option>
                                <option value={"divorced"}>
                                  {" "}
                                  selected=
                                  {personal_details.martial_status &&
                                    personal_details.martial_status ===
                                      "divorced"}
                                  Divorced
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Social ID/ID Card</label>
                              <input
                                type="text"
                                className="form-control"
                                required
                                name="social_id"
                                value={
                                  personal_details["social_id"]
                                    ? personal_details["social_id"]
                                    : null
                                }
                                onChange={this.handleChange}
                                placeholder="Enter your ID here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Mobile/Phone</label>
                              <input
                                type="tel"
                                className="form-control"
                                name="phone"
                                onChange={this.handleChange}
                                value={
                                  personal_details["phone"]
                                    ? personal_details["phone"]
                                    : null
                                }
                                pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
                                required
                                placeholder="Enter your phone number here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                name="city"
                                value={
                                  personal_details["city"]
                                    ? personal_details["city"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter place of your residence"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>State</label>
                              <input
                                type="text"
                                className="form-control"
                                // id="your-email"
                                name="state"
                                onChange={this.handleChange}
                                value={
                                  personal_details["state"]
                                    ? personal_details["state"]
                                    : null
                                }
                                required
                                placeholder="Enter place of your residence"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Postal/Zip Code</label>
                              <input
                                type="number"
                                className="form-control"
                                name="postal_code"
                                value={
                                  personal_details["postal_code"]
                                    ? personal_details["postal_code"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter postal/zip code here"
                              />
                            </div>
                          </div>
                          <div className="col-6  mb-1">
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address"
                                value={
                                  personal_details["address"]
                                    ? personal_details["address"]
                                    : null
                                }
                                onChange={this.handleChange}
                                required
                                placeholder="Enter your address here"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-2 text-right">
                          <button className="btn btn-dark" type="submit">
                            Save and Continue
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  toggle = (val) => {
    this.setState({ show: val });
  };
  handleChange = ({ currentTarget: input }) => {
    var personal_details = { ...this.state.personal_details };
    personal_details[input.name] = input.value;
    this.setState({ personal_details });
  };
  handleHealthDetails = ({ currentTarget: input }) => {
    var health_details = { ...this.state.health_details };
    health_details[input.name] = input.value;
    this.setState({ health_details });
  };
  handleInsuranceDetails = (e, index) => {
    var insuranceDetails = [...this.state.insuranceDetails];
    insuranceDetails[index][e.target.name] = e.target.value;
    this.setState({ insuranceDetails });
  };
  handleEducationDetails = (e, index) => {
    var educationDetails = [...this.state.educationDetails];
    educationDetails[index][e.target.name] = e.target.value;
    this.setState({ educationDetails });
  };
  handleEmploymentDetails = (e, index) => {
    var employmentDetails = [...this.state.employmentDetails];
    employmentDetails[index][e.target.name] = e.target.value;
    this.setState({ employmentDetails });
  };
  submitPersonalDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = { ...this.state.personal_details };
    delete data["updated_at"];
    delete data["created_at"];
    for (var key in data) {
      form.append(key, data[key]);
    }
    try {
      var response = await axios.post(
        Endpoint + "/set/personal-details",
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Personal Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        // this.getData();
        // this.setState({ show: this.state.show + 1 });
        setTimeout(() => this.props.history.push("/user-landing"), 2000);
      }
    } catch (e) {}
  };
  submitHealthAndInsuranceDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = { ...this.state.health_details };
    delete data["updated_at"];
    delete data["created_at"];
    for (var key in data) {
      form.append(key, data[key]);
    }
    var data2 = this.state.insuranceDetails;
    var insuranceObj = {
      company_name: [],
      effective_date: [],
      group_no: [],
      i_h_contact_no: [],
      i_h_security_no: [],
      insurance_holder_name: [],
      insurance_id: [],
      phone_no: [],
      plan_name: [],
    };
    for (let i = 0; i < data2.length; i++) {
      let current = data2[i];
      console.log(current);
      for (var key in current) {
        insuranceObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(insuranceObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], insuranceObj[keys[i]]);
      if (insuranceObj[keys[i]].length !== 0) {
        console.log("appended");
        form.append(keys[i], JSON.stringify(insuranceObj[keys[i]]));
      }
    }

    try {
      var response = await axios.post(Endpoint + "/set/health-details", form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Health & Insurance Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {}
  };
  getData = async () => {
    this.setState({ loading: true });
    var token = localStorage.getItem("token");

    var resp_ = await axios.get(Endpoint + "/get/user-details", {
      headers: { Authorization: token },
    });
    var personal_details = resp_.data.data.personal_details || [];
    personal_details["first_name"] = resp_.data.data.user.first_name;
    personal_details["last_name"] = resp_.data.data.user.last_name;
    var health_details = resp_.data.data.health_details || [];
    var insuranceDetails =
      resp_.data.data.insurance_details.length > 0
        ? resp_.data.data.insurance_details
        : [{}];
    var educationDetails =
      resp_.data.data.education_details.length > 0
        ? resp_.data.data.education_details
        : [{}];
    var employmentDetails =
      resp_.data.data.work_details.length > 0
        ? resp_.data.data.work_details
        : [{}];
    if (personal_details["dob"]) {
      let date = new Date(personal_details["dob"]);
      let d =
        date.getFullYear() +
        "-" +
        `${
          parseInt(date.getMonth() + 1) > 10
            ? parseInt(date.getMonth() + 1)
            : "0" + parseInt(date.getMonth() + 1)
        }` +
        "-" +
        date.getDate();
      personal_details["dob"] = d;
    }
    this.setState({
      personal_details,
      health_details,
      insuranceDetails,
      educationDetails,
      employmentDetails,
      actualData: resp_.data.data,
      first_name: resp_.data.data.user.first_name,
      last_name: resp_.data.data.user.last_name,
      loading: false,
    });
  };
  submitEducationDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");
    var form = new FormData();
    var data = this.state.educationDetails;
    delete data["updated_at"];
    delete data["created_at"];

    var educationObj = {
      degree_level: [],
      institute_name: [],
      city: [],
      state: [],
      session: [],
      enrollment_id: [],
      country: [],
      grades: [],
    };

    for (let i = 0; i < data.length; i++) {
      let current = data[i];
      console.log(current);
      for (var key in current) {
        educationObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(educationObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], educationObj[keys[i]]);
      if (educationObj[keys[i]].length !== 0) {
        form.append(keys[i], JSON.stringify(educationObj[keys[i]]));
      }
    }
    // for (let i = 0; i < data.length; i++) {
    //   let current = data[i];
    //   console.log(current);
    //   for (var key in current) {
    //     form.append(`${key}[${i}]`, current[key]);
    //   }
    // }
    try {
      var response = await axios.post(
        Endpoint + "/set/education-details",
        form,
        {
          headers: { Authorization: token },
        }
      );
      if (response.data.status === 200) {
        firetoast(
          "Education Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {}
  };
  submitEmployeementDetails = async (e) => {
    e.preventDefault();
    var token = localStorage.getItem("token");

    var form = new FormData();
    var data = this.state.employmentDetails;
    delete data["updated_at"];
    delete data["created_at"];
    // for (let i = 0; i < data.length; i++) {
    //   let current = data[i];
    //   console.log(current);
    //   for (var key in current) {
    //     form.append(`${key}[${i}]`, current[key]);
    //   }
    // }
    var employmentObj = {
      employer_name: [],
      position: [],
      country: [],
      state: [],
      city: [],
      supervisor: [],
      contact_No: [],
      email: [],
      start_date: [],
      end_date: [],
      salary: [],
      reason_of_leaving: [],
      responsibilities: [],
    };
    for (let i = 0; i < data.length; i++) {
      let current = data[i];
      console.log(current);
      for (var key in current) {
        employmentObj[key].push(current[key]);
      }
    }
    var keys = Object.keys(employmentObj);
    console.log(keys);
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i], employmentObj[keys[i]]);
      if (employmentObj[keys[i]].length !== 0) {
        form.append(keys[i], JSON.stringify(employmentObj[keys[i]]));
      }
    }
    try {
      var response = await axios.post(Endpoint + "/set/work-details", form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Employement Details Set Successfuly",
          "success",
          4000,
          "top-right"
        );
        this.getData();
        // this.setState({ show: this.state.show + 1 });
      }
    } catch (e) {
      firetoast(
        "Something went wrong while adding employment details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updatePersonalDetails = async () => {
    try {
      var { personal_details } = this.state;
      delete personal_details["updated_at"];
      delete personal_details["id"];
      delete personal_details["created_at"];
      var token = localStorage.getItem("token");
      let form = new URLSearchParams();
      for (let key in personal_details) {
        form.append(key, personal_details[key]);
      }
      var response = await axios.put(
        `${Endpoint}/update/personal-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast("Personal details updated!", "success", 3000, "top-right");
      }
    } catch (e) {
      firetoast(
        "Something went wrong while updating personal details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateHealthAndInsuranceDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { insuranceDetails, health_details } = this.state;
      delete health_details["id"];
      delete health_details["created_at"];
      delete health_details["updated_at"];

      for (let i = 0; i < insuranceDetails.length; i++) {
        // delete insuranceDetails[i].id;
        delete insuranceDetails[i].created_at;
        delete insuranceDetails[i].updated_at;
        delete insuranceDetails[i].user_id;
      }

      for (let key in health_details) {
        form.append(key, health_details[key]);
      }

      var insuranceObj = {
        record_ids: [],
        company_name: [],
        effective_date: [],
        group_no: [],
        i_h_contact_no: [],
        i_h_security_no: [],
        insurance_holder_name: [],
        insurance_id: [],
        phone_no: [],
        plan_name: [],
      };
      for (let i = 0; i < insuranceDetails.length; i++) {
        let current = insuranceDetails[i];
        for (var key in current) {
          if (key === "id") {
            insuranceObj.record_ids.push(current[key]);
          } else {
            insuranceObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(insuranceObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], insuranceObj[keys[i]]);
        if (insuranceObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(insuranceObj[keys[i]]));
        }
      }

      var response = await axios.put(
        `${Endpoint}/update/health-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Health and Insurance Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating insurance details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating insurance details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateEducationalDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { educationDetails } = this.state;

      for (let i = 0; i < educationDetails.length; i++) {
        // delete educationDetails[i].id;
        delete educationDetails[i].created_at;
        delete educationDetails[i].updated_at;
        delete educationDetails[i].user_id;
      }

      var educationObj = {
        degree_level: [],
        institute_name: [],
        city: [],
        state: [],
        session: [],
        enrollment_id: [],
        country: [],
        grades: [],
        record_ids: [],
      };
      for (let i = 0; i < educationDetails.length; i++) {
        let current = educationDetails[i];
        for (var key in current) {
          if (key === "id") {
            educationObj.record_ids.push(current[key]);
          } else {
            educationObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(educationObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], educationObj[keys[i]]);
        if (educationObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(educationObj[keys[i]]));
        }
      }

      var response = await axios.put(
        `${Endpoint}/update/education-details`,
        form,
        { headers: { Authorization: token } }
      );
      if (response.data.status === 200) {
        firetoast(
          "Education Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating education details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating education details",
        "error",
        3000,
        "top-right"
      );
    }
  };
  updateEmploymentDetails = async () => {
    var token = localStorage.getItem("token");
    try {
      var form = new URLSearchParams();
      var { employmentDetails } = this.state;

      for (let i = 0; i < employmentDetails.length; i++) {
        // delete employmentDetails[i].id;
        delete employmentDetails[i].created_at;
        delete employmentDetails[i].updated_at;
        delete employmentDetails[i].user_id;
      }

      var employmentObj = {
        employer_name: [],
        position: [],
        country: [],
        state: [],
        city: [],
        supervisor: [],
        contact_No: [],
        email: [],
        start_date: [],
        end_date: [],
        salary: [],
        reason_of_leaving: [],
        responsibilities: [],
        record_ids: [],
      };
      for (let i = 0; i < employmentDetails.length; i++) {
        let current = employmentDetails[i];
        for (var key in current) {
          if (key === "id") {
            employmentObj.record_ids.push(current[key]);
          } else {
            employmentObj[key].push(current[key]);
          }
        }
      }
      var keys = Object.keys(employmentObj);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        console.log(keys[i], employmentObj[keys[i]]);
        if (employmentObj[keys[i]].length !== 0) {
          form.append(keys[i], JSON.stringify(employmentObj[keys[i]]));
        }
      }

      var response = await axios.put(`${Endpoint}/update/work-details`, form, {
        headers: { Authorization: token },
      });
      if (response.data.status === 200) {
        firetoast(
          "Employment Details updated successfully",
          "success",
          3000,
          "top-right"
        );
      } else {
        firetoast(
          "Something went wrong while updating employment details",
          "error",
          3000,
          "top-right"
        );
      }
    } catch (e) {
      console.log(e);
      firetoast(
        "Something went wrong while updating employment details",
        "error",
        3000,
        "top-right"
      );
    }
  };
}

export default UserPersonalInformation;
