import { useState, useEffect } from "react";
import { Endpoint } from "./../../Utils/Endpoint";
import axios from "axios";
import { Spinner } from "reactstrap";
import firetoast from "./../../Utils/Firetoast";
function AdminUserTable({ status }) {
  const [Pagination, setPagination] = useState({
    page_number: 1,
    page_size: 5,
    sort_by: "id",
    order: "DESC",
  });
  const [Users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [Total, setTotal] = useState(0);
  const [Limit, setLimit] = useState(0);
  useEffect(() => {
    setPagination({
      page_number: 1,
      page_size: 5,
      sort_by: "id",
      order: "DESC",
    });
    getCustomers();
  }, [status]);

  useEffect(() => {
    getCustomers();
  }, [Pagination.order, Pagination.page_number]);
  let getCustomers = async () => {
    setLoading(true);

    var response = null;
    if (status === 0) {
      response = await axios.post(
        `${Endpoint}/admin/users/business`,
        Pagination,
        { headers: { Authorization: token } }
      );
      setUsers(response.data.users);
      setTotal(response.data.total);
      var cal = response.data.Total / Pagination.page_size;
      setLimit(Math.ceil(cal));
      setLoading(false);
    } else {
      response = await axios.post(
        `${Endpoint}/admin/users/customers`,
        Pagination,
        { headers: { Authorization: token } }
      );
      setUsers(response.data.users);

      setLoading(false);
    }
  };
  let suspendUser = async (u_id) => {
    try {
      const response = await axios.post(
        `${Endpoint}/admin/users/suspend`,
        { user_id: u_id },
        { headers: { Authorization: token } }
      );
      if (response.data.success === 200) {
        firetoast("User suspended", "dark", 3000, "top-center");
        getCustomers();
      }
    } catch (e) {
      console.log("Error while suspending users", e);
    }
  };
  return loading ? (
    <div className="text-center">
      <Spinner color="dark" /> Getting Users...
    </div>
  ) : (
    <div>
      <div className="w-100" style={{ textAlign: "right" }}>
        <div
          className="row align-items-center w-100"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="col-1">
            <select
              className="form-control"
              onChange={(e) => {
                setPagination({
                  page_number: 1,
                  page_size: 5,
                  sort_by: "id",
                  order: e.target.value,
                });
              }}
            >
              <option selected={Pagination.order === "DESC"} value="DESC">
                DESC
              </option>
              <option selected={Pagination.order === "ASC"} value="ASC">
                ASC
              </option>
            </select>
          </div>
          <div className="col-1">
            <label>Sort by</label>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th className="text-center">Total Forms</th>
            <th className="text-center">Subscription Type</th>
            <th className="text-center">Is Active</th>
            <th></th>
          </thead>
          <tbody>
            {Users.map((user, index) => (
              <tr key={index}>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td className="text-center">
                  <span class="badge badge-light bg-secondary text-light">
                    {user.forms_created}
                  </span>
                </td>
                <td className="text-center">{user.subscription}</td>
                <td className="text-center">
                  {user.is_active === 1 ? (
                    <span>
                      <i class="fas fa-check text-success"></i>
                    </span>
                  ) : (
                    <span>
                      <i class="fas fa-times text-danger"></i>
                    </span>
                  )}
                </td>
                <td>
                  {user.is_active === 1 ? (
                    <button
                      className="btn btn-warning btn-sm text-light"
                      onClick={() => suspendUser(user.id)}
                    >
                      Suspend User
                    </button>
                  ) : (
                    "User is suspended"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ textAlign: "right" }} className="w-100">
        <div className="btn-group">
          <button
            className="btn btn-dark btn-sm"
            disabled={Pagination.page_number === 1}
            onClick={() => {
              setPagination({
                page_number: Pagination.page_number - 1,
                page_size: 5,
                sort_by: "id",
                order: Pagination.order,
              });
            }}
          >
            <i class="fas-chevron-left"></i> prev
          </button>
          <button className="btn btn-dark btn-sm" disabled type="button">
            {Pagination.page_number}
          </button>
          <button
            className="btn btn-dark btn-sm"
            disabled={Math.ceil(Total / Pagination.page_size) === Limit}
            onClick={() => {
              setPagination({
                page_number: Pagination.page_number + 1,
                page_size: 5,
                sort_by: "id",
                order: Pagination.order,
              });
            }}
          >
            <i class="fas-chevron-right"></i> next
          </button>
        </div>
      </div>
    </div>
  );
}
export default AdminUserTable;
