import shape7 from "../../images/shape/shape7.png";
import shape2 from "../../images/shape/shape2.svg";
import shape3 from "../../images/shape/shape3.svg";
import shape4 from "../../images/shape/shape4.png";
function AnimatedImages() {
  return (
    <>
      <div className="shape-img7">
        <img src={shape7} alt="image" />
      </div>
      <div className="shape-img2">
        <img src={shape2} alt="image" />
      </div>
      <div className="shape-img3">
        <img src={shape3} alt="image" />
      </div>
      <div className="shape-img4">
        <img src={shape4} alt="image" />
      </div>
    </>
  );
}
export default AnimatedImages;
