import axios from "axios";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import UserNavbar from "./../Layout/UserNavbar";
import { Endpoint } from "./../Utils/Endpoint";
import firetoast from "./../Utils/Firetoast";
import AnimatedImages from "../Helpers/AnimatedImages";
function FormHistoryDetail() {
  const { recordID, formID } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [formname, setFormname] = useState("");
  const [record, setRecord] = useState([]);
  var getFormRecordDetail = async () => {
    try {
      var token = localStorage.getItem("token");
      var response = await axios.get(
        `${Endpoint}/forms/get/form-history/${formID}/${recordID}`,
        {
          headers: { Authorization: token },
        }
      );
      setFormname(response.data.data["form-details"].name);
      setRecord(response.data.data["sections"]);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      firetoast("Something went wrong!", "error", 3000, "top-right");
      console.log(e);
    }
  };
  useEffect(() => {
    getFormRecordDetail();
  }, []);

  var generatePdf = async () => {
    setIsLoading(true);
    try {
      var token = localStorage.getItem("token");
      var response = await axios.get(
        `${Endpoint}/forms/get/pdf/${formID}/${recordID}`,
        {
          headers: { Authorization: token },
        }
      );
      let link = response.data.data.pdf_file;
      let tag = document.getElementById("download-pdf");
      tag.setAttribute("href", link);
      tag.click();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      firetoast("Something went wrong!", "error", 3000, "top-right");
    }
  };
  return (
    <>
      <a
        className="d-none"
        href=""
        id="download-pdf"
        download={true}
        target="_blank"
      ></a>
      <UserNavbar />

      {isLoading ? (
        <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
          <div
            className="text-center"
            style={{ height: "100px", marginTop: "25px" }}
          >
            <Spinner color="dark" /> Getting Records....
          </div>
        </div>
      ) : (
        <>
          <div style={{ margin: "8rem 0.5rem 0rem 0.5rem" }}>
            <div className="text-center">
              <h5 className="color-default">Record Detail For {formname}</h5>
            </div>
          </div>
          <div className="row m-auto">
            <div className="col-md-8 col-md-10 col-sm-10 m-auto">
              <div className="text-right">
                <button
                  className="btn btn-sm btn-dark text-white"
                  onClick={() => generatePdf()}
                >
                  Get PDF
                </button>
              </div>
              <div className="table-responsive">
                {record.map((section, index) => (
                  <>
                    <div className="d-flex justify-content-between">
                      <h5>Section Name : {section["section-name"]}</h5>
                    </div>
                    <table className="table table-bordered mt-3" key={index}>
                      <thead>
                        <tr>
                          <th>Field</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(section["data"]).map((value, key) => (
                          <tr key={index}>
                            <td>{section["data"][value].field_name}</td>
                            <td>{section["data"][value].value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                ))}
              </div>
            </div>
          </div>
          <AnimatedImages />
        </>
      )}
    </>
  );
}
export default FormHistoryDetail;
