import React, { Component } from "react";
import { Link } from "react-router-dom";
// import FacebookLogin from "react-facebook-login";

import axios from "axios";
import Navbar from "./../Layout/Navbar";
import Footer from "./../Layout/Footer";
import { Endpoint } from "./../Utils/Endpoint";
import ErrorHandler from "./../Utils/ErrroHandler";
import firetoast from "./../Utils/Firetoast";
import SocialLoginButtons from "./../Social Logins/SocialLoginButtons";
import { Spinner } from "reactstrap";
class Login extends Component {
  state = { data: {}, role: "user", isLoading: false };
  render() {
    return (
      <>
        <Navbar />

        {/* <PageHeader
          pageTitle="Login"
          breadcrumbTextOne="Home"
          breadcrumbUrl="/"
          breadcrumbTextTwo="Login"
        /> */}

        <div className="container" style={{ marginTop: "8rem" }}>
          <div className="form-content">
            <div className="form-header">
              <h3>Login</h3>
              <p>If you have an account with us, please log in.</p>
            </div>
            <ul
              class="nav nav-tabs"
              id="myTab"
              role="tablist"
              style={{ justifyContent: "center" }}
            >
              <li class="nav-item" role="presentation">
                <button
                  class={
                    this.state.role === "user" ? "nav-link active" : "nav-link"
                  }
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => this.setState({ role: "user" })}
                >
                  As User
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class={
                    this.state.role === "business"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() => this.setState({ role: "business" })}
                >
                  As Business
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class={
                  this.state.role === "user" ? "tab-pane active" : "tab-pane"
                }
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <form onSubmit={this.handleSubmit} className="mt-5">
                  <div className="form-group">
                    <label>User Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="your-email"
                      required
                      name="email"
                      onChange={this.handleChange}
                      placeholder="Your email"
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="your-password"
                      required
                      name="password"
                      onChange={this.handleChange}
                      placeholder="Your password"
                    />
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-6 text-right">
                      <Link to="/reset-password">
                        <a className="form-link right">Forgot password?</a>
                      </Link>
                    </div> */}
                  </div>

                  <button
                    type="submit"
                    className="default-btn"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <div className="text-center">
                        <Spinner size="md" color="white" />
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </form>
              </div>
              <div
                class={
                  this.state.role === "business"
                    ? "tab-pane active"
                    : "tab-pane"
                }
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <form onSubmit={this.handleSubmit} className="mt-5">
                  <div className="form-group">
                    <label>Business Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="your-email"
                      required
                      name="email"
                      onChange={this.handleChange}
                      placeholder="Your email"
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="your-password"
                      required
                      name="password"
                      onChange={this.handleChange}
                      placeholder="Your password"
                    />
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div className="form-group form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div className="col-6 text-right">
                      <Link to="/reset-password">
                        <a className="form-link right">Forgot password?</a>
                      </Link>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="default-btn"
                    disabled={this.state.isLoading}
                  >
                    {this.state.isLoading ? (
                      <div className="text-center">
                        <Spinner size="md" color="white" />
                      </div>
                    ) : (
                      "Login"
                    )}
                  </button>
                </form>
              </div>
            </div>
            <div
              class={
                this.state.role === "business" ? "tab-pane active" : "tab-pane"
              }
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {/* <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="your-email"
                  required
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Your email"
                />
              </div>

              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="your-password"
                  required
                  name="password"
                  onChange={this.handleChange}
                  placeholder="Your password"
                />
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Remember me
                    </label>
                  </div>
                </div>

                <div className="col-6 text-right">
                  <Link href="/reset-password">
                    <a className="form-link right">Forgot password?</a>
                  </Link>
                </div>
              </div>

              <button type="submit" className="default-btn">
                Login
              </button>
            </form> */}
            </div>

            <div className="text-center mt-3">OR</div>
            <SocialLoginButtons role={this.state.role} />
            <div className="form-footer">
              <p>
                Don’t have an account?
                <Link to="/signup">
                  <a className="form-link">Signup</a>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
  handleChange = ({ currentTarget: input }) => {
    var data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var data = { ...this.state.data };
    var form = new FormData();

    for (var key in data) {
      if (this.checkField(key)) {
        form.append(key, data[key]);
      } else {
        return firetoast("Provide All fields", "error", 3000, "top-right");
      }
    }

    try {
      var response = "";
      if (this.state.role === "business") {
        response = await axios.post(`${Endpoint}/business/login`, form);
      } else {
        response = await axios.post(`${Endpoint}/login`, form);
      }
      if (response.data.status === 200) {
        this.setState({ isLoading: false });

        firetoast("Login Successfull", "success", 3000, "top-right");
        console.log(response.data);
        localStorage.setItem("token", "Bearer " + response.data.data.token);
        localStorage.setItem(
          "nopen-user",
          JSON.stringify(response.data.data.user)
        );
        if (response.data.data.user.role_id === "3") {
          setTimeout(() => {
            window.location.href = "/packages";
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.href = "/user-personal-information";
          }, 2000);
        }
      }
    } catch (e) {
      this.setState({ isLoading: false });

      console.log(e);
      if (e.response) {
        if (
          e.response.data.error == null ||
          e.response.data.error === undefined
        ) {
          if (e.response.data.message === "User not Verified yet") {
            window.location.href = `/otp-verify?email=${this.state.data.email}&verify=Y`;
          }
          firetoast(e.response.data.message, "error", 3000, "top-right");
        } else {
          ErrorHandler(e.response.data.error);
        }
      }
    }
  };

  checkField = (field) => {
    if (!field === "" || field == null || field === undefined) {
      return false;
    }
    return true;
  };
}

export default Login;
