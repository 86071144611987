import { useState, useEffect } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import NavbarThree from "./../Layout/NavbarThree";
import { Endpoint } from "./../Utils/Endpoint";

import axios from "axios";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Spinner,
} from "reactstrap";

function FormHistory() {
  const [activeTab, setActiveTab] = useState("1");
  const [uncomplete, setUncomplete] = useState([]);
  const [complete, setComplete] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  useEffect(async () => {
    if (typeof window !== "undefined") {
      history();
    }
  }, [typeof window]);
  var history = async () => {
    setIsLoading(true);
    var token = localStorage.getItem("token");
    var response = await axios.get(Endpoint + "/business/forms/un-complete", {
      headers: { Authorization: token },
    });
    var resp = await axios.get(Endpoint + "/business/forms/complete", {
      headers: { Authorization: token },
    });

    setComplete(resp.data.data);
    setUncomplete(response.data.data);
    setIsLoading(false);
  };
  return (
    <>
      <NavbarThree />

      <div style={{ marginTop: "8rem" }}>
        <div className="text-center">
          <h4 className="color-default">Form History</h4>
        </div>
        <div className="m-5">
          <Nav tabs style={{ justifyContent: "center" }}>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{ cursor: "pointer" }}
              >
                Completed
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={{ cursor: "pointer" }}
              >
                Uncompleted
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="m-0">
                <Col sm="12" className="mt-2">
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{ margin: "25px", height: "100px" }}
                    >
                      <Spinner color="primary" /> Getting Records
                    </div>
                  ) : complete.length > 0 ? (
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Form Title</th>
                          <th>Created At</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {complete.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                              ></div>
                            </td>
                            <td>
                              <Link to={`/formDetails/${item.id}`}>
                                {item.name}
                              </Link>
                            </td>
                            <td>
                              {item.created_at.substring(
                                0,
                                item.created_at.indexOf(" ")
                              )}
                            </td>
                            <td>
                              {item.status === 1 ? (
                                <button
                                  className="btn btn-success btn-sm"
                                  disabled
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger btn-sm"
                                  disabled
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">No record found</div>
                  )}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="m-0">
                <Col sm="12" className="mt-2">
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{ margin: "25px", height: "100px" }}
                    >
                      <Spinner color="primary" /> Getting Records
                    </div>
                  ) : uncomplete.length > 0 ? (
                    <table className="table table-borderless">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Form Title</th>
                          <th>Created At</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uncomplete.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                              ></div>
                            </td>
                            <td>
                              {" "}
                              <Link to={`/formDetails/${item.id}`}>
                                {item.name}
                              </Link>
                            </td>
                            <td>
                              {item.created_at.substring(
                                0,
                                item.created_at.indexOf(" ")
                              )}
                            </td>
                            <td>
                              {item.status === 1 ? (
                                <button
                                  className="btn btn-success btn-sm"
                                  disabled
                                >
                                  Active
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger btn-sm"
                                  disabled
                                >
                                  Inactive
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">No record found</div>
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
}
export default FormHistory;
