import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "reactstrap";
import { Endpoint } from "./../Utils/Endpoint";
import NavbarThree from "./../Layout/NavbarThree";
function SubmittedRecords(props) {
  const { id } = useParams();
  const [paginate, setPaginate] = useState({
    form_id: id,
    page_number: 1,
    page_size: 10,
    sort_by: "id",
    order: "DESC",
  });
  const [records, setRecords] = useState([]);
  const [heads, setHeads] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataAttrib, setDataAttrib] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(async () => {
    if (typeof window !== "undefined") {
      getPopulatedList();
    }
  }, [typeof window]);
  var getPopulatedList = async () => {
    setIsLoading(true);

    const token = localStorage.getItem("token");
    try {
      var response = await axios.post(
        Endpoint + `/business/forms/data/active-paged-list`,
        paginate,
        { headers: { Authorization: token } }
      );
      setRecords(response.data.data.records);
      setTotalRecords(response.data.data.total_records);
      if (response.data.data.field_name_mapping.length > 0) {
        var mappings = response.data.data.field_name_mapping;
        var obj = {};
        for (var i = 0; i < mappings.length; i++) {
          obj[mappings[i].db_field_name] = mappings[i].field_name;
        }
        setHeads(obj);
      }
      if (response.data.data.records.length > 0) {
        var record = response.data.data.records[0];
        delete record["picklist_mapping"];
        delete record["related_modules"];
        var temp = Object.keys(response.data.data.records[0]);

        setDataAttrib(temp);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const returnRecordVal = (val) => {
    if (val == null || val === undefined) {
      return value;
    } else {
      var value = val.toString();
      if (
        value.includes(".png") ||
        value.includes(".jpg") ||
        value.includes(".jpeg") ||
        value.includes(".gif") ||
        value.includes(".tiff") ||
        value.includes(".tif")
      ) {
        return (
          <img src={value} style={{ height: "50px" }} className="img-fluid" />
        );
      } else {
        return value;
      }
    }
  };
  const pagination = (goTo) => {
    try {
      var numOfPages = Math.ceil(totalRecords / paginate.page_size);

      if (goTo === "next") {
        if (paginate.page_number < numOfPages) {
          paginate.page_number = paginate.page_number + 1;
          setPaginate(paginate);
          getPopulatedList();
        }
      } else if (goTo === "previous") {
        if (paginate.page_number > 1) {
          paginate.page_number = paginate.page_number - 1;
          setPaginate(paginate);
          getPopulatedList();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <NavbarThree />

      <div className="row">
        <div className="col-10 m-auto">
          <div style={{ marginTop: "8rem" }}>
            <div className="text-center">
              <h4 className="text-dark">Submitted Records</h4>
            </div>
            <div
              className="card"
              style={{ marginLeft: "5px", marginRight: "5px" }}
            >
              <div className="card-body">
                <div className="table-responsive">
                  {isLoading ? (
                    <div
                      className="text-center"
                      style={{ height: "100px", marginTop: "25px" }}
                    >
                      <Spinner color="dark" /> Getting Records
                    </div>
                  ) : (
                    <table className="table ">
                      <thead>
                        <tr>
                          {dataAttrib.map((attrib, index) => (
                            <th key={index}>{heads[attrib]}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {records.length > 0 ? (
                          records.map((record, index) => (
                            <tr key={index}>
                              {dataAttrib.map((attrib, index) => (
                                <td key={index}>
                                  {returnRecordVal(record[attrib])}
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <div className="text-center">No Record Found</div>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div className="text-right">
              <div
                class="btn-group btn-left-padding"
                role="group"
                aria-label="Basic example"
              >
                <button
                  class="btn btn-new btn-sm"
                  type="button"
                  onClick={() => pagination("previous")}
                >
                  <i class="fa fa-arrow-left"></i>
                </button>
                <button class="btn btn-new btn-sm" type="button">
                  {paginate.page_number}
                </button>
                <button
                  class="btn btn-new btn-sm"
                  type="button"
                  data-bs-original-title=""
                  title=""
                  onClick={() => pagination("next")}
                >
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubmittedRecords;
